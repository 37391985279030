import React from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import Router from "./rota";

import LoginGeral from "../Paginas/Login/indice";
import LoginAlternative from "../Paginas/LoginAlternative/indice";

//Médicos
import AgendaMedico from "../Paginas/Medico/Agenda/indice";
import ConsultaMedico from "../Paginas/Medico/Consulta/indice";
import ListaConsultasMedico from "../Paginas/Medico/ListaConsultas/indice";
import PainelInicial from "../Paginas/Medico/PainelInicial/indice";
import PerfilMedico from "../Paginas/Medico/Perfil/indice";
import NotificacoesMedico from "../Paginas/Medico/Notificacoes/indice";
import AcompanhamentoMedico from "../Paginas/Medico/Acompanhamento/indice";
import EmContrucao from "../Servicos/EmConstrução/indice";

//Atendente
import PainelPrincipalAtendente from "../Paginas/Atendente/PainelPrincipal/indice";
import AgendaAtendente from "../Paginas/Atendente/Agenda/indice";
import SalaEspera from "../Paginas/Atendente/SalaEspera/indice";
import ConsultasAtendente from "../Paginas/Atendente/Consultas/indice";
import PerfilMedicoAtendente from "../Paginas/Atendente/PerfilMedico/indice";
import PerfilAtendente from "../Paginas/Atendente/Perfil/indice";
import PerfilPacienteAtendente from "../Paginas/Atendente/PerfilPaciente/indice";

//Paciente
import PainelInicialPaciente from "../Paginas/Paciente/PainelInicial/indice";
import ConsultasPaciente from "../Paginas/Paciente/Consultas/indice";
import PerfilPaciente from "../Paginas/Paciente/Perfil/indice";
import AcompanhamentoPaciente from "../Paginas/Paciente/Acompanhamento/indice";
import NotificacoesPaciente from "../Paginas/Paciente/Notificacoes/indice";
import ChatBot from "../Paginas/Paciente/ChatBot/index";

//Clínica
import PainelPrincipalClinica from "../Paginas/Clinica/PainelPrincipal/indice";
import AgendaClinica from "../Paginas/Clinica/Agenda/indice";
import EmpregadoPerfilClinica from "../Paginas/Clinica/PerfilEmpregado/indice";
import ConfiguracoesClinica from "../Paginas/Clinica/Configuracoes/indice";
import RedefinirSenhaClinica from "../Paginas/Clinica/RedefinirSenha/indice";
import AlterarSenhaClinica from "../Paginas/Clinica/AlterarSenha/indice";
import PlanosClinica from "../Paginas/Clinica/Planos/indice";
import FinanceiroClinica from "../Paginas/Clinica/Financeiro/index";
import PerfilMedicoClinica from "../Paginas/Clinica/PerfilMedico/indice";
import PerfilPacienteClinica from "../Paginas/Clinica/PerfilPaciente/indice";
import CadastrarClinica from "../Paginas/Clinica/CadastrarClinica/indice";
import DiarioDeBordo from "../Paginas/DiarioDeBordo/indice";


//Diretor Geral
import PainelPrincipalDiretor from "../Paginas/DiretorGeral/PainelPrincipal/indice";
import EmpregadoPerfilDiretor from "../Paginas/DiretorGeral/PerfilEmpregado/indice";
import PerfilDiretor from "../Paginas/DiretorGeral/Configuracoes/indice";
import PerfilMedicoDiretor from "../Paginas/DiretorGeral/PerfilMedico/indice";
import PerfilPacienteDiretor from "../Paginas/DiretorGeral/PerfilPaciente/indice";

/*-------------- Lists --------------*/
import ClerkList from "../Paginas/Lists/Atendentes/indice";
import DoctorList from "../Paginas/Lists/Medicos/indice";
import PatientList from "../Paginas/Lists/Pacientes/indice";
import EmployeeList from "../Paginas/Lists/Empregados/indice";
import UserList from "../Paginas/Lists/Usuarios/indice";

// Erro 404 Not Found
import NotFound from "../Paginas/NotFound/indice";

function Rotas() {
  return (
    <BrowserRouter>
      <Switch>
        {/* Rota Inicial */}
        <Route path="/" exact component={LoginGeral} />
        <Route path="/loginalternative" component={LoginAlternative} />

        {/* Rota para o ChatBot */}
        <Router path="/chatbot" component={ChatBot} />

        {/* Rotas Médico */}
        <Router path="/medico/inicio" exact component={PainelInicial} />
        <Router path="/medico/agenda" component={AgendaMedico} />
        <Router path="/medico/perfil" component={PerfilMedico} />
        <Router path="/medico/consulta" component={ConsultaMedico} />
        <Router path="/medico/listaconsultas" component={ListaConsultasMedico} />
        <Router path="/medico/notificacoes" component={NotificacoesMedico} />
        <Router path="/medico/listaacompanhamento" component={AcompanhamentoMedico} />

        {/* Rotas Paciente */}
        <Router path="/paciente/inicio" exact component={PainelInicialPaciente} />
        <Router path="/paciente/perfil" component={PerfilPaciente} />
        <Router path="/paciente/consultas" component={ConsultasPaciente} />
        <Router path="/paciente/listaacompanhamento" component={AcompanhamentoPaciente} />
        <Router path="/paciente/notificacoes" component={NotificacoesPaciente} />

        {/* Rotas Atendente */}
        <Router path="/atendente/inicio" exact component={PainelPrincipalAtendente} />
        <Router path="/atendente/agenda" component={AgendaAtendente} />
        <Router path="/atendente/perfil" component={PerfilAtendente} />
        <Router path="/atendente/saladeespera" component={SalaEspera} />
        <Router path="/atendente/consultas" component={ConsultasAtendente} />
        <Router path="/atendente/pacientes" component={PatientList} />
        <Router path="/atendente/paciente/perfil" component={PerfilPacienteAtendente} />
        <Router path="/atendente/medicos" component={DoctorList} />
        <Router path="/atendente/medico/perfil" component={PerfilMedicoAtendente} />

        {/* Rotas Clínica */}
        <Router path="/clinica/inicio" exact component={PainelPrincipalClinica} />
        <Router path="/clinica/agenda" component={AgendaClinica} />
        <Router path="/clinica/pacientes" component={PatientList} />
        <Router path="/clinica/medicos" component={DoctorList} />
        <Router path="/clinica/atendentes" component={ClerkList} />
        <Router path="/clinica/empregados" component={EmployeeList} />
        <Router path="/clinica/empregado/perfil" component={EmpregadoPerfilClinica} />
        <Router path="/clinica/usuarios" component={UserList} />
        <Router path="/clinica/planos" component={PlanosClinica} />
        <Router path="/clinica/financeiro" component={FinanceiroClinica} />
        <Router path="/clinica/configuracoes" component={ConfiguracoesClinica} />
        <Router path="/clinica/cadastrar" component={CadastrarClinica} />

        {/* Rotas Clínica Paciente/Médico */}
        <Router path="/pacientes/diariobordo" component={DiarioDeBordo} />

        {/* Rotas Clínica Paciente/Médico/Atendente */}
        <Router path="/clinica/medico/perfil" component={PerfilMedicoClinica} />
        <Router path="/clinica/paciente/perfil" component={PerfilPacienteClinica} />
        <Route path="/redefinirsenha" component={RedefinirSenhaClinica} />
        <Route path="/alterarsenha" component={AlterarSenhaClinica} />

        {/* Rotas do Diretor Geral */}
        <Router path="/diretor/inicio" exact component={PainelPrincipalDiretor} />
        <Router path="/diretor/pacientes" component={PatientList} />
        <Router path="/diretor/medicos" component={DoctorList} />
        <Router path="/diretor/atendentes" component={ClerkList} />
        <Router path="/diretor/empregados" component={EmployeeList} />
        <Router path="/diretor/empregado/perfil" component={EmpregadoPerfilDiretor} />
        <Router path="/diretor/usuarios" component={UserList} />
        <Router path="/diretor/perfil" component={PerfilDiretor} />
        <Router path="/diretor/paciente/perfil" component={PerfilPacienteDiretor} />
        <Router path="/diretor/medico/perfil" component={PerfilMedicoDiretor} />
        <Router path="/construcao" component={EmContrucao} />

        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default Rotas;