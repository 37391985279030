import React, { useState } from "react";
import Paginacao from "../../Paginacao/indice";

import "./estilos.css";

export default function ConsultasDiarias({
  tituloColuna1,
  tituloColuna2,
  consultas,
}) {
  const [paginaAtual, setPaginaAtual] = useState(1);
  const consultasPorPagina = 4;

  //Pegar consultas atuais
  const indexUltimaConsulta = paginaAtual * consultasPorPagina;
  const indexPrimeiraConsulta = indexUltimaConsulta - consultasPorPagina;

  const consultasAtuais = consultas.slice(
    indexPrimeiraConsulta,
    indexUltimaConsulta
  );

  //Mudar consultas
  const paginar = (numero) => setPaginaAtual(numero);

  return (
    <div className="TabelaConsultasDiarias">
      <table>
        <thead>
          <tr>
            <th>{tituloColuna1}</th>
            <th>{tituloColuna2}</th>
          </tr>
        </thead>
        <tbody>
          {consultasAtuais.length === 0 ? (
            <tr>
              <td colSpan={2}>Não existem consultas diárias cadastradas.</td>
            </tr>
          ) : (
            consultasAtuais.map((consulta) => (
              <tr key={consulta.id}>
                <td>{consulta.start_time}</td>
                <td>
                  {consulta.patient.person.name_first}{" "}
                  {consulta.patient.person.name_last}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div className="PaginacaoTabela">
        <Paginacao
          consultasPorPagina={consultasPorPagina}
          totalConsulta={consultas.length}
          paginar={paginar}
        />
      </div>
    </div>
  );
}
