import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Axios from 'axios';
import api from '../../../Servicos/api';
import swal from 'sweetalert';

import './estilos.css';

export default function Form({
  modoEdicao,
  setModoEdicao,
  setNome,
  setArea,
  setRegistro,
  rota,
}) {
  const history = useHistory();

  //Dados do médico
  const [area_specialization, setArea_specialization] = useState('');
  const [specialization_id, setSpecialization_id] = useState(0);
  const [specialization_name, setSpecialization_name] = useState('');
  const [crm, setCrm] = useState();
  const [rqe, setRqe] = useState('');
  const [uf, setUf] = useState('');
  const [name_first, setName_first] = useState('');
  const [name_last, setName_last] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');

  setNome(name_first);
  setArea(area_specialization);
  setRegistro(crm);

  const idUserGet = localStorage.getItem('idUserGet');
  const idUserPut = localStorage.getItem('idUserPut');
  const token = localStorage.getItem('token');

  //API IBGE
  const [listaEstados, setListaEstados] = useState([]);

  // coletando dados da memed

  const [listaEspecialidade, setListaEspecialidade] = useState([]);
  const [listaEspecialidadeFilter, setListaEspecialidadeFilter] = useState([]);
  //var especializacaoSelect = "";
  var GrupoEspecialidade = [];

  useEffect(() => {
    Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((response) => {
        const estadoInitials = response.data.map((estado) => estado.sigla);

        setListaEstados(estadoInitials);
      })
      .catch((error) => console.log(error));
  }, []);

  const setDoctorData = (data) => {
    setArea_specialization(data.area_specialization);
    setSpecialization_id(data.specialization_id);
    setCrm(data.crm);
    setRqe(data.rqe);
    setUf(data.uf);
    setName_first(data.person.name_first);
    setName_last(data.person.name_last);
    setCpf(
      data.person.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    );
    setPhone(data.person.phone);
    setEmail(data.person.user[0].email);
    setUsername(data.person.user[0].username);
  };

  useEffect(() => {
    api
      .get(`getDoctor/${idUserGet}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDoctorData(response.data);
      });
  }, [idUserGet, token]);

  useEffect(() => {
    listaEspecialidade.map((especialidade) => {
      if (
        especialidade.attributes.nome.toLowerCase() ===
        area_specialization.toLowerCase()
      ) {
        setSpecialization_name(especialidade.attributes.grupo);
        return null;
      } else {
        return null;
      }
    });
  }, [area_specialization, listaEspecialidade]);

  //Função de cancelar alterações
  async function cancelar() {
    await api
      .get(`getDoctor/${idUserGet}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDoctorData(response.data);
      });
  }

  //Função de Alterar Perfil

  async function AlterarPerfil(e) {
    e.preventDefault();
    //setArea_specialization(especializacaoSelect);

    try {
      const gender = 2;
      const data = {
        gender,
        name_first,
        name_last,
        phone,
        cpf,
        area_specialization,
        uf,
        rqe,
        crm,
        specialization_id,
      };
      console.table(data);
      await api.put(`doctor/${idUserPut}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      swal('Dados alterados com Sucesso', '', 'success');
      history.goBack();
    } catch (err) {
      swal(err.response.data.error, 'Digite novamente', 'error');
    }
  }

  useEffect(() => {
    Axios.get('https://api.memed.com.br/v1/especialidades/').then(
      (response) => {
        const dados = response.data.data.map((especialidade) => especialidade);

        function compare(a, b) {
          if (a.attributes.grupo < b.attributes.grupo) {
            return -1;
          }
          if (a.attributes.grupo > b.attributes.grupo) {
            return 1;
          }
          return 0;
        }

        setListaEspecialidade(dados.sort(compare));
        setListaEspecialidadeFilter(dados.sort(compare));
      }
    );
  }, []);

  function setValueGrupoInput() {
    var inputValue = document.getElementById('grupoEspecializacaoId').value;
    var options = document.getElementById('dataListGrupo').childNodes;

    for (let i = 0; i < GrupoEspecialidade.length; i++) {
      if (options[i].value === inputValue) {
        const listaFiltrada = [];
        listaEspecialidade.map((especialidade) =>
          especialidade.attributes.grupo.toLowerCase() ===
          GrupoEspecialidade[i].toLowerCase()
            ? listaFiltrada.push(especialidade)
            : GrupoEspecialidade[i]
        );

        setSpecialization_name(inputValue);
        setListaEspecialidadeFilter(listaFiltrada);
        break;
      }
    }
  }
  function setValueEspecializacaoInput() {
    var inputValue = document.getElementById('especializacaoId').value;
    var options = document.getElementById('dataListEspecializacao').childNodes;

    for (var i = 0; i < listaEspecialidadeFilter.length; i++) {
      if (options[i].value === inputValue) {
        setSpecialization_id(parseInt(options[i].getAttribute('data-value')));
        //especializacaoSelect = inputValue;
        setArea_specialization(inputValue);
        setSpecialization_name(
          listaEspecialidade.filter(
            (especialidade) =>
              especialidade.attributes.nome.toLowerCase() ===
              inputValue.toLowerCase()
          )[0].attributes.grupo
        );
        break;
      }
    }
  }

  const changeName = (e, state) => {
    if (/^[a-zA-Z ]+$/.test(e.target.value)) {
      state(e.target.value);
    }
  };

  const changePhone = (e, state) => {
    if (/^[0-9]+$/.test(e.target.value)) {
      state(e.target.value);
    }
  };

  return (
    <form onSubmit={AlterarPerfil}>
      <h2 className="titulo-formulario">Meus dados</h2>

      <div className="container-input">
        <div>
          <label htmlFor="especializacao">Grupo de Especialização</label>
          <input
            id="grupoEspecializacaoId"
            list="dataListGrupo"
            value={specialization_name}
            disabled={modoEdicao === 'editar' ? false : true}
            placeholder="-- Selecione um Grupo de Especialização --"
            autoComplete="off"
            onClick={(e) => {
              e.target.value = '';
              document.getElementById('especializacaoId').value = '';
              setListaEspecialidadeFilter(listaEspecialidade);
              setArea_specialization('');
            }}
            onChange={(e) => {
              setValueGrupoInput();
            }}
          />
          <datalist id="dataListGrupo">
            {listaEspecialidade
              .filter((especialidade) => {
                if (
                  GrupoEspecialidade.indexOf(
                    especialidade.attributes.grupo.toLowerCase()
                  ) === -1
                ) {
                  GrupoEspecialidade.push(
                    especialidade.attributes.grupo.toLowerCase()
                  );
                  return especialidade.attributes.grupo;
                } else {
                  return null;
                }
              })
              .map((grupo) => (
                <option
                  key={grupo.attributes.grupo}
                  data-value={grupo.id}
                  value={grupo.attributes.grupo}
                >
                  {grupo.attributes.grupo === 'Null'
                    ? 'Outros'
                    : grupo.attributes.grupo}
                </option>
              ))}
          </datalist>
        </div>
        <div>
          <label htmlFor="especializacao">Especialização</label>
          <input
            id="especializacaoId"
            value={area_specialization}
            list="dataListEspecializacao"
            disabled={modoEdicao === 'editar' ? false : true}
            placeholder="-- Selecione uma Especialização --"
            autoComplete="off"
            onClick={(e) => {
              e.target.value = '';
            }}
            onChange={(e) => {
              setValueEspecializacaoInput();
            }}
          />

          <datalist id="dataListEspecializacao">
            {listaEspecialidadeFilter.map((especialidade) => (
              <option
                key={especialidade.id}
                data-value={especialidade.id}
                value={especialidade.attributes.nome}
              >
                {especialidade.attributes.nome}
              </option>
            ))}
          </datalist>
        </div>

        <div>
          <label htmlFor="escolaridade">RQE:</label>
          <input
            type="text"
            id="rqe"
            value={rqe}
            disabled={modoEdicao === 'editar' ? false : true}
            onChange={(e) => setRqe(e.target.value)}
          />
        </div>
      </div>

      <div className="container-input" id="input-escolaridade">
        <div>
          <label htmlFor="crm">CRM</label>
          <input
            type="text"
            id="crm"
            value={crm}
            disabled={modoEdicao === 'editar' ? false : true}
            onChange={(e) => setCrm(parseInt(e.target.value))}
          />
        </div>
        <div>
          <label htmlFor="uf">Estado</label>
          <select
            id="uf"
            value={uf}
            disabled={modoEdicao === 'editar' ? false : true}
            onChange={(e) => setUf(e.target.value)}
          >
            <option value={uf} style={{ textColor: '#6e757d' }}>
              {uf}
            </option>
            {listaEstados.map((uf) => (
              <option key={uf} value={uf}>
                {uf}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="container-input">
        <div>
          <label htmlFor="nome">Nome</label>
          <input
            type="text"
            id="nome"
            value={name_first}
            disabled={modoEdicao === 'editar' ? false : true}
            onChange={(e) => changeName(e, setName_first)}
          />
        </div>

        <div>
          <label htmlFor="sobrenome">Sobrenome</label>
          <input
            type="text"
            id="sobrenome"
            value={name_last}
            disabled={modoEdicao === 'editar' ? false : true}
            onChange={(e) => changeName(e, setName_last)}
          />
        </div>
      </div>

      <div className="container-input" id="input-cpf">
        <div>
          <label htmlFor="cpf">CPF</label>
          <input
            type="text"
            disabled={true}
            id="cpf"
            maxLength={14}
            value={
              modoEdicao === 'editar'
                ? cpf
                : `${cpf[0] + cpf[1] + cpf[2]}.***.***-**`
            }
          />
        </div>
      </div>

      <div className="container-input">
        <div>
          <label htmlFor="telefone">Telefone</label>
          <input
            id="telefone"
            type="text"
            disabled={modoEdicao === 'editar' ? false : true}
            minLength={modoEdicao === 'editar' ? 10 : 13}
            maxLength={modoEdicao === 'editar' ? 10 : 13}
            value={
              modoEdicao === 'editar'
                ? phone
                : phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
            }
            onChange={(e) => changePhone(e, setPhone)}
          />
        </div>
      </div>

      <div className="container-input" id="input-email">
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            disabled={true}
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="username">Nome do Usuário</label>
          <input
            type="text"
            disabled={true}
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
      </div>

      <div className={`container-botao ${modoEdicao}`}>
        <button
          type="reset"
          className="botao-grande"
          onClick={() => {
            setModoEdicao('');
            cancelar();
          }}
        >
          Cancelar
        </button>
        <button className="botao-grande" type="submit">
          Salvar
        </button>
      </div>
    </form>
  );
}
