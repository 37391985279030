import React, { useEffect, useState } from "react";
import Message from "../Message";
import moment from "moment";
import api from "../../../../Servicos/api";
import "./MessageList.css";

const MY_USER_ID = "apple";

export default function MessageList(props) {
  const [messages, setMessages] = useState([]);
  const [control, setControl] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    api
      .get(`/getMedicalSupervision/${props.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.answers !== null) {
          getMessages(
            response.data.questions || "",
            response.data.answers || ""
          );
        } else setControl(true);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, props.id]);

  const getMessages = (mensagem, respostas) => {
    var tempMessages = [];
    for (var i = 0; i < mensagem.length; i++) {
      tempMessages.push(
        {
          id: i,
          author: "orange",
          message: mensagem[i],
          timestamp: new Date().getTime(),
        },
        {
          id: i,
          author: "apple",
          message: respostas[i],
          timestamp: new Date().getTime(),
        }
      );
    }

    setMessages([...messages, ...tempMessages]);
  };

  const renderMessages = () => {
    let i = 0;
    let messageCount = messages.length;
    let tempMessages = [];

    while (i < messageCount) {
      let previous = messages[i - 1];
      let current = messages[i];
      let next = messages[i + 1];
      let isMine = current.author === MY_USER_ID;
      let currentMoment = moment(current.timestamp);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment(previous.timestamp);
        let previousDuration = moment.duration(
          currentMoment.diff(previousMoment)
        );
        prevBySameAuthor = previous.author === current.author;

        if (prevBySameAuthor && previousDuration.as("hours") < 1) {
          startsSequence = false;
        }

        if (previousDuration.as("hours") < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.timestamp);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.author === current.author;

        if (nextBySameAuthor && nextDuration.as("hours") < 1) {
          endsSequence = false;
        }
      }

      tempMessages.push(
        <Message
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
        />
      );

      // Proceed to the next message.
      i += 1;
    }

    return tempMessages;
  };

  return (
    <div className="message-list">
      <div className="message-list-container">
        {control ? (
          <h2 style={{ margin: "50% 20%", color: "var(--cor-cinza)" }}>
            Não foi respondido ainda
          </h2>
        ) : (
          renderMessages()
        )}
      </div>

      {/* <Compose /> */}
    </div>
  );
}
