import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";

import NovaConsultaMedico from "../../Medico/NovaConsulta/indice";

import "./estilos.css";
import api from "../../../Servicos/api";

export default function CalendarioMedico({ listaConsulta }) {
  const [ativo, setAtivo] = useState("");

  let events = [];
  const [consulta, setConsulta] = useState([]);
  const [grupoEspecialidade, setGrupoEspecialidade] = useState("");
  const [especialidade, setEspecialidade] = useState("");
  const doctorName = localStorage.getItem("name_first");
  const especialidadeId = localStorage.getItem("specialization_id");

  function openModal(estado) {
    setAtivo(estado);
  }

  function setEvents() {
    listaConsulta.map((consulta) => {
      if (consulta) {
        events.push({
          id: consulta.id,
          title: `Dr(a) ${doctorName} - Paciente: ${consulta.patient.person.name_first}`,
          start: `${consulta.start_time}:00`,
          end: `${consulta.final_time}:00`,
        });
      }
      //console.table(events);
      return null;
    });
  }

  useEffect(() => {
    api
      .get(`https://api.memed.com.br/v1/especialidades/${especialidadeId}`
      )
      .then((response) => {
        const dados = response.data.data;
        setGrupoEspecialidade(dados.attributes.grupo);
        setEspecialidade(dados.attributes.nome);
      })

  }, [especialidadeId])

  return (
    <div className="calendario">
      {setEvents()}
      <div className={`sobreposicaoConsulta ${ativo}`}>
        <NovaConsultaMedico
          ativo={ativo}
          executar={openModal}
          medico={localStorage.getItem("name_first")}
          especializacao={especialidade}
          grupoEspecializacao={grupoEspecialidade}
          consulta={consulta}
        />
      </div>
      <FullCalendar
        locale="pt-br"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "",
          center: "prev,title,next",
          right: "timeGridDay,timeGridWeek,dayGridMonth",
        }}
        initialView="timeGridDay"
        nowIndicator={true}
        now={moment().format("YYYY-MM-DDTHH:mm:ss[Z]")}
        allDaySlot={false}
        slotLabelFormat={[
          {
            hour: "2-digit",
            minute: "2-digit",
            omitZeroMinute: false,
          },
        ]}
        eventColor={'#1070a1'}

        slotMinTime="06:00"
        slotMaxTime="18:00"
        contentHeight={620}
        eventTimeFormat={{
          hour: "2-digit",
          minute: "2-digit",
        }}
        buttonText={{
          today: "Hoje",
          month: "Mensal",
          week: "Semanal",
          day: "Diario",
        }}
        views={[
          {
            timeGridDay: {
              titleFormat: {
                year: "4-digit",
                month: "4-digit",
                day: "4-digit",
              },
            },
          },
        ]}
        timeZone="UTC"
        events={events}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        eventClick={(info) => {
          setConsulta(listaConsulta.filter(
            (item) =>
              item.id === parseInt(info.event.id)
          )[0]
          );
          openModal("ativo");
        }}
      />
    </div>
  );
}
