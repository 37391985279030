import React from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../Servicos/api";
import { FiAlertTriangle } from "react-icons/fi";

import "./estilos.css";

function ModalExcluirPlano({ abrirModal, setAbrirModal, idPlano }) {
    const token = localStorage.getItem("token");
    const history = useHistory();

    async function excluirPlano() {
        try {
            await api.delete(`/healthInsurance/${idPlano}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            Swal.fire({
                title: "Removido com Sucesso!",
                icon: "success",
                confirmButtonText: "OK",
            }).then(() => {
                setAbrirModal(false);
                history.push("/clinica/inicio");
                history.push("/clinica/planos");
            });
        } catch (err) {
            Swal.fire({
                title: "Erro!",
                text: err.response.data.error || "Erro ao remover o plano.",
                icon: "error",
                confirmButtonText: "Tente novamente",
            });
            setAbrirModal(false);
        }
    }

    if (abrirModal === true) {
        return (
            <div className="SobreposicaoExcluirPlano">
                <section className="ModalExcluirPlano">
                    <header>
                        <h2>Deseja realmente remover o plano?</h2>
                    </header>
                    <FiAlertTriangle
                        style={{ marginTop: "30px" }}
                        size="100"
                        color="#ec1b21"
                    />
                    <main>
                        <button
                            className="botao-grande"
                            style={{ backgroundColor: "#ec1b21" }}
                            onClick={() => excluirPlano()}
                        >
                            Sim
                        </button>
                        <button
                            className="botao-grande"
                            style={{ backgroundColor: "var(--cor-cinza)" }}
                            onClick={() => setAbrirModal(false)}
                        >
                            Não
                        </button>
                    </main>
                </section>
            </div>
        );
    }
    return null;
}

export default ModalExcluirPlano;

