import React from "react";
import Form from "../container-form-acompanhamento/indice";
import "./estilos.css";

function DadosCadastrais({ abrirDados, setAbrirDados }) {
  if (abrirDados === true) {
    return (
      <div className="SobreposicaoCadastrarAcompanhamento">
        <section id="modalMedicoAcompanhamento" >
          <footer>
            <button onClick={() => setAbrirDados(false)}>X</button>
          </footer>
          <Form />
        </section>
      </div>
    );
  }
  return null;
}

export default DadosCadastrais;
