import React from 'react';
import ListaDados from './Components/ListaDados';

const ConfirmaDados = ({ nome, foto, triggerNextStep }) => {
  const handleConfirm = (isCorrect) => {
    if (isCorrect) {
      triggerNextStep({ trigger: '7' });  // Vai para o próximo passo se os dados estiverem corretos
    } else {
      triggerNextStep({ trigger: 'corrigirdados' });  // Vai para a correção de dados
    }
  };

  return (
    <div>
      <ListaDados nome={nome} foto={foto} />
      <p>Esses dados estão corretos?</p>
      <button onClick={() => handleConfirm(true)}>Sim, estão corretos</button>
      <button onClick={() => handleConfirm(false)}>Não, quero atualizar meus dados</button>
    </div>
  );
};

export default ConfirmaDados;
