import React from "react";
import { createContext, useState } from "react";
// import api from "../api";

export const ChallengesContext = createContext({});

export function ChallengesProvider({ children }) {
  const [DataConsulta, setDataConsulta] = useState("");
  const [token, setToken] = useState("0");
  const [verificLogin, setVerificLogin] = useState(false);

  return (
    <ChallengesContext.Provider
      value={{
        DataConsulta,
        setDataConsulta,
        token,
        setToken,
        verificLogin,
        setVerificLogin,
      }}
    >
      {children}
    </ChallengesContext.Provider>
  );
}
