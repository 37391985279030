import React from "react";

import { FiUsers } from "react-icons/fi";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import "./estilos.css";

function InformacoesPacientes({ totalPacientes, idadesPacientes }) {
  //Gráfico Faixa Etária

  const getIntroOfPage = (label) => {
    if (label === 1) {
      return "Criança";
    }
    if (label === 2) {
      return "Criança";
    }
    if (label === 3) {
      return "Jovem";
    }
    if (label === 4) {
      return "Jovem";
    }
    if (label === 5) {
      return "Adulto";
    }
    if (label === 6) {
      return "Adulto";
    }
    if (label === 7) {
      return "Adulto";
    }
    if (label === 8) {
      return "Adulto";
    }
    if (label === 9) {
      return "Adulto";
    }
    if (label === 10) {
      return "Idoso";
    }
  };

  const getLegenda = (label) => {
    if (label === 1) {
      return "0 a 4 Anos";
    }
    if (label === 2) {
      return "5 a 9 Anos";
    }
    if (label === 3) {
      return "10 a 14 Anos";
    }
    if (label === 4) {
      return "15 a 19 Anos";
    }
    if (label === 5) {
      return "20 a 29 Anos";
    }
    if (label === 6) {
      return "30 a 49 Anos";
    }
    if (label === 7) {
      return "30 a 49 Anos";
    }
    if (label === 8) {
      return "40 a 49 Anos";
    }
    if (label === 9) {
      return "50 a 59 Anos";
    }
    if (label === 10) {
      return "60 ou +";
    }
  };

  const CustomTooltip2 = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="legendaCustomizada">
          <p className="label">
            {getIntroOfPage(label)}: {payload[0].value}
          </p>
          <p className="intro">{getLegenda(label)}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div id="InformacoesPacientes">
      <div className="Total">
        <div id="FundoIcone">
          <FiUsers id="Icone" />
        </div>
        <div className="Descricao">
          <span>Total de Pacientes</span>
          <h2>
            {totalPacientes > 10 ? `${totalPacientes}` : `0${totalPacientes}`}
          </h2>
        </div>
      </div>
      <span id="Separador" />
      <div className="DistincaoDeSexo">
        <h2>Faixa Etária Pacientes</h2>
        <ResponsiveContainer>
          <BarChart data={idadesPacientes}>
            <XAxis dataKey="group" />
            <YAxis />
            <Tooltip content={<CustomTooltip2 />} />
            <Bar dataKey="count" barSize={20} fill="var(--cor-azul)" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default InformacoesPacientes;
