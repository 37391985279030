/*-------------- External --------------*/
import React from 'react';
import { Link } from 'react-router-dom';

/*-------------- Helpers --------------*/
import { removeNonNumeric } from '../../../Servicos/helpers/removeNonNumeric';

/*-------------- Assets --------------*/
import { FiEdit } from 'react-icons/fi';

/*-------------- Styles --------------*/
import './estilos.css';

export default function Table({ titles, tableItems, route }) {
  console.log("Dados da tabela:", tableItems);
  return (
    <div className="TabelaComponente">
      <table>
        <thead>
          <tr>
            {tableItems.length !== 0 &&
              titles.map((title) => <th key={title.nome}>{title.nome}</th>)}
          </tr>
        </thead>
        <tbody>
        {!tableItems.length ? (
            <tr>
              <td>Nenhum paciente encontrado.</td>
            </tr>
          ) : (
            tableItems.map((item) => (
              <tr key={item.id}>
                {item.person.patient.medical_record ? (
                  <td>{item.person.patient.medical_record}</td>
                ) : (
                  <td>MB000000</td>
                )}
                <td>{item.person.name_first + " " + item.person.name_last}</td>
                <td>
                  {removeNonNumeric(item.person.cpf).replace(
                    /(\d{3})(\d{3})(\d{3})(\d{2})/,
                    '$1.$2.$3-$4'
                  )}
                </td>
                <td>
                  {removeNonNumeric(item.person.phone).length < 10
                    ? 'Não informado'
                    : removeNonNumeric(item.person.phone).replace(
                        /(\d{2})(\d{4})(\d{4})/,
                        '($1) $2-$3'
                      ) ||
                      removeNonNumeric(item.person.phone).replace(
                        /(\d{2})(\d{5})(\d{4})/,
                        '($1) $2-$3'
                      )}
                </td>
                <td>{item.person.age ? `${item.person.age} anos` : 'Não informado'}</td>
                <td>
                  <Link
                    onClick={() => {
                      localStorage.setItem('idUserGet', item.person.patient.id);
                      localStorage.setItem('idUserPut', item.id);
                    }}
                    to={route}
                    className="botao-editar"
                  >
                    <FiEdit />
                  </Link>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
