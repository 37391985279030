import React, { useState } from "react";
// import api from "../../../Servicos/api";

import "./estilos.css";
export default function CadastrarMedicamento({
  cadastrarMedicamento,
  setCadastrarMedicamento,
  medications,
  setMedications
}) {
  // const token = localStorage.getItem("token");
  const [name, setName] = useState("");
  const [principle, setPrinciple] = useState("");
  const [amount, setAmount] = useState("");
  const [use, setUse] = useState("");
  const [prescription, setPrescription] = useState("");
  const [observation, setObservation] = useState("");
  const [variavel, setVar] = useState(1);
  // const [medicamentos, setMedicamentos] = useState([])

  async function cadastraMedicamento() {

    const medication = {
      name,
      principle,
      amount,
      use,
      prescription,
      observation,
    };
    // setMedications([...medications, me]);

    medications.push(medication);


    setName('')
    setPrinciple('')
    setAmount('')
    setUse('')
    setPrescription('')
    setObservation('')
  }

  if (cadastrarMedicamento === true) {
    if (variavel === 1) {
      setVar(2);
    }
    return (
      <div id="cadastrar-medicamento">
        <div className="modal-medicamento">
          <h3>Cadastrar Medicamento</h3>
          <form >
            <InputGrande
              titulo="Nome:"
              identificador="nome"
              placeholder="Nome do medicamento"
              value={name}
              setValue={setName}
            />

            <InputGrande
              titulo="Princípio Ativo:"
              identificador="principio"
              placeholder="Princípio Ativo"
              value={principle}
              setValue={setPrinciple}
            />

            <div className="grupo-input">
              <InputGrande
                titulo="Uso:"
                identificador="uso"
                placeholder="Uso"
                value={use}
                setValue={setUse}
              />

              <InputGrande
                titulo="Quantidade:"
                identificador="quantidade"
                placeholder="Quantidade"

                value={amount}
                setValue={setAmount}
              />
            </div>
            <div className="input-area-texto">
              <label htmlFor="prescricao">Prescrição:</label>
              <textarea
                name="prescricao"
                id="prescricao"
                rows="5"
                placeholder="Prescrição"
                value={prescription}
                onChange={(e) => setPrescription(e.target.value)}
              ></textarea>
            </div>
            <InputGrande
              titulo="Observações:"
              identificador="obs"
              placeholder="Observações"
              value={observation}
              setValue={setObservation}
            />
            <footer>
              <button
                type="button"
                onClick={() => setCadastrarMedicamento(false)}
              >
                Fechar
              </button>
              <button type="button" onClick={cadastraMedicamento}>Salvar</button>
            </footer>
          </form>
        </div>
      </div>
    );
  }

  return null;
}

function InputGrande({ titulo, identificador, placeholder, value, setValue }) {
  return (
    <div className="input-grande">
      <label htmlFor="nome">{titulo}</label>
      <input
        type="text"
        name={identificador}
        id={identificador}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
}