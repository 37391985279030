/*-------------- External --------------*/
import React from 'react';
import { Link } from 'react-router-dom';

/*-------------- Helpers --------------*/
import { removeNonNumeric } from '../../../Servicos/helpers/removeNonNumeric';

/*-------------- Assets --------------*/
import { FiEdit } from 'react-icons/fi';

/*-------------- Styles --------------*/
import './estilos.css';

export default function Table({ titles, tableItems, route }) {
  return (
    <div className="TabelaComponente">
      <table>
        <thead>
          <tr>
            {tableItems.length !== 0 &&
              titles.map((title) => <th key={title.nome}>{title.nome}</th>)}
          </tr>
        </thead>
        <tbody>
          {!tableItems.length ? (
            <tr>
              <td>Nenhum funcionário encontrado.</td>
            </tr>
          ) : (
            tableItems.map((item) => (
              <tr key={item.person.id}>
                <td>{item.person.name_first + " " + item.person.name_last}</td>
                <td>
                  {removeNonNumeric(item.person.cpf).replace(
                    /(\d{3})(\d{3})(\d{3})(\d{2})/,
                    '$1.$2.$3-$4'
                  )}
                </td>
                <td>
                  {removeNonNumeric(item.person.phone).length < 10
                    ? 'Não informado'
                    : removeNonNumeric(item.person.phone).replace(
                        /(\d{2})(\d{4})(\d{4})/,
                        '($1) $2-$3'
                      ) ||
                      removeNonNumeric(item.person.phone).replace(
                        /(\d{2})(\d{5})(\d{4})/,
                        '($1) $2-$3'
                      )}
                </td>
                <td>
                  <Link
                    onClick={() => {
                      //console.log("Id da tabela " + item.id);
                      localStorage.setItem(
                        'idUserGet',
                        item.privilege === "MHAT" || item.privilege === "MHDG" ? 
                            item.person.employee[0].id
                        : item.privilege === "MHME" ?
                            item.person.doctor.id
                        : null
                      );
                      localStorage.setItem(
                        'idUserPut', item.id
                      )
                    }}
                    to={item.privilege === "MHAT" || item.privilege === "MHDG" 
                            ? route : '/clinica/medico/perfil'}
                    className="botao-editar"
                  >
                    <FiEdit />
                  </Link>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
