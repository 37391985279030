//import React from 'react';

const PlanejamentoFamiliar = () => {
    return [
        {
            id: 'planejamentoFamiliar',
            message: 'Nesta seção você pode receber informações sobre planejamento familiar.',
            //end: true,
            trigger: '2',
        }
    ];
};

export default PlanejamentoFamiliar;
