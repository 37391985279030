import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import api from "../../../Servicos/api";
import swal from "sweetalert";
import { cpfMascara } from "../../../Servicos/Mascaras/cpfMascara";
import { telefoneMascara } from "../../../Servicos/Mascaras/telefoneMascara";

import "./estilos.css";
import { cepMask } from "../../../Servicos/Mascaras/cepMask";

var erroForm = false;

export default function Form({ setAbrirModal }) {
  const token = localStorage.getItem("token");
  const history = useHistory();

  //Informações Gerais
  const [cpf, setCpf] = useState("");
  const [name_first, setName_first] = useState("");
  const [name_last, setName_last] = useState("");
  const [gender, setGender] = useState();
  const [data_birth, setData_birth] = useState(null);
  //const [religion, setReligion] = useState("");

  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [valueCep, setCEP] = useState("");
  const [street, setStreet] = useState("");
  const [housign_type, setHousign_type] = useState("");
  const [valueNumber, setNumber] = useState();

  //Autenticacao
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  //API IBGE
  const [listaEstados, setListaEstados] = useState([]);
  const [listaCidades, setListaCidades] = useState([]);

  //Constantes de Controle
  const [desativa, setDesativa] = useState("");
  const [modoAvancar, setAvancar] = useState("");
  const [modoAvancar2, setAvancar2] = useState("");

  //Diretor Geral e Atendente
  const [occupation, setOccupation] = useState("");

  //Específicas Paciente
  const [race, setRace] = useState("");
  const [escolaridade, setEscolaridade] = useState("");
  const [fathers_name, setFathers_name] = useState("");
  const [mothers_name, setMothers_name] = useState("");
  const [responsible_name, setResponsible_name] = useState("");
  const [marital_status, setMarital_status] = useState("");
  const [profession, setProfession] = useState("");
  const [rg, setRg] = useState("");
  const [toTreatNationality, setToTreatNacionality] = useState("");
  const [birth_town, setBirth_town] = useState("");

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => {
        const estadoInitials = response.data.map((estado) => estado.sigla);

        setListaEstados(estadoInitials);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (!state || state === "0") {
      return setListaCidades([]);
    }

    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`
      )
      .then((response) => {
        const cidadeNome = response.data.map((cidade) => cidade.nome);

        setListaCidades(cidadeNome);
      })
      .catch((error) => console.log(error));
  }, [state]);

  function verificAvancar() {
    if (
      name_first !== "" &&
      name_last !== "" &&
      cpf !== "" &&
      phone !== "" &&
      data_birth !== "" &&
      gender !== "" &&
      valueCep !== "" &&
      state !== "" &&
      city !== "" &&
      street !== "" &&
      valueNumber !== "" &&
      erroForm !== true
    ) {
      setAvancar2(true);
      setAvancar(false);
      setDesativa("desativa");
    } else {
      swal("Preencha os campos corretamente", "Para prosseguir", "error");
    }
  }

  // async function BuscarCep(cepPesquisa) {
  //   const ceplength = parseInt(valueCep.length);
  //   if (ceplength === 9 || ceplength === 8) {
  //     try {
  //       const response = await axios.get(
  //         `https://viacep.com.br/ws/${cepPesquisa}/json/`
  //       );
  //       setCity(response.data.localidade);
  //       setState(response.data.uf);
  //       //setListaCidades(response.data.localidade);
  //       //setState(response.data.uf);
  //     } catch (err) {
  //       swal(err, "Digite novamente", "error");
  //     }
  //   }
  // }

  // BuscarCep(valueCep);

  useEffect(() => {
    if (valueCep.length === 9) {
      searchCep(valueCep);
    }
  }, [valueCep]);

  async function searchCep(cepPesquisa) {
    try {
      const response = await axios.get(
        `https://viacep.com.br/ws/${cepPesquisa}/json/`
      );
      setCity(response.data.localidade);
      setState(response.data.uf);
      setCEP(cepPesquisa);
    } catch (erro) {
      swal(erro, "Digite novamente", "error");
    }
  }


  async function RegistrarPaciente() {
    let cep = parseInt(valueCep);
    let number = parseInt(valueNumber);
    let nationality = parseInt(toTreatNationality);

    const data = {
      username,
      email,
      password,
      confirmationPassword,
      escolaridade,
      race,
      data_birth,
      name_first,
      name_last,
      cpf,
      phone,
      nationality,
      birth_town,
      mothers_name,
      fathers_name,
      responsible_name,
      marital_status,
      profession,
      occupation,
      rg,
      gender,
      address: {
        state,
        city,
        cep,
        number,
        street,
        housign_type,
      },
    };

    console.table(data);
    try {
      await api.post("patient", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      swal("Cadastrado com Sucesso", "", "success");
      history.push("/clinica/pacientes");
    } catch (err) {
      swal(err, "Digite novamente", "error");
    }
  }

  return (
    <div className="form-registro" id="form">
      <div
        className={`grupo-button-form ${desativa}`}
        style={{ display: modoAvancar ? "none" : "block" }}
      >
        <div className="numIndic-Paciente">
          <p> Página 1/2</p>
        </div>
        <Input
          className="input-nome"
          placeholder="000.000.000-00"
          value={cpfMascara(cpf)}
          type="text"
          setValue={setCpf}
          id="cpf"
          label="CPF"
          name="cpf"
          maxlength={14}
        />

        <div className="grupo-nome">
          <Input
            className="input-nome"
            placeholder="Ex. João"
            value={name_first}
            type="text"
            setValue={setName_first}
            id="Name"
            label="Nome"
            name="Nome"
          />

          <Input
            className="input-nome"
            placeholder="Ex. Silva"
            value={name_last}
            type="text"
            setValue={setName_last}
            id="Sobrenome"
            label="Sobrenome"
            name="Sobrenome"
          />
        </div>
        <div className="grupo-nome">
          <Input
            className="input-nome"
            placeholder="(99)9.9999-9999"
            value={telefoneMascara(phone)}
            type="text"
            setValue={setPhone}
            id="Telefone"
            label="Telefone"
            name="Telefone"
            maxlength={15}
          />
          <Input
            type={"date"}
            className="input-nome"
            value={data_birth}
            setValue={setData_birth}
            required
          />

          <select
            placeholder="Etnia"
            value={gender}
            onChange={(e) => setGender(parseInt(e.target.value))}
            required
          >
            <option value="">-- Sexo --</option>
            <option value={1}>Masculino</option>
            <option value={2}>Feminino</option>
            <option value={3}>Outro</option>
          </select>
        </div>

        <div className="grupo-nome">
          <Input
            type="text"
            className="input-nome"
            placeholder="55555-555"
            value={cepMask(valueCep)}
            maxlength={9}
            setValue={setCEP}
            id="CEP"
            label="CEP"
            name="CEP"
            link={true}
          />

          <select
            placeholder="UF"
            value={state}
            onChange={(e) => setState(e.target.value)}
            required
          >
            <option value="">-- Selecione o estado --</option>
            {listaEstados.map((uf) => (
              <option key={uf} value={uf}>
                {uf}
              </option>
            ))}
          </select>
          <select
            placeholder="Cidade"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          >
            <option value="">-- Cidade --</option>
            {listaCidades.map((listaCidade) => (
              <option key={listaCidade} value={listaCidade}>
                {listaCidade}
              </option>
            ))}
          </select>
        </div>
        <div className="grupo-escolaridade-genero">
          <Input
            type="text"
            className="input-nome"
            placeholder="Rua Tabelião Aoem Menescal"
            value={street}
            setValue={setStreet}
            id="Rua"
            label="Rua"
            name="Rua"
          />
        </div>
        <div className="grupo-bairro-numero">
          <Input
            className="input-nome"
            type="text"
            placeholder="Complemento"
            value={housign_type}
            setValue={setHousign_type}
            id="Complemento"
            label="Complemento"
            name="Complemento"
          />

          <Input
            type="number"
            className="input-nome"
            placeholder="Ex. 30"
            value={valueNumber}
            setValue={setNumber}
            id="Número"
            label="Número"
            name="Número"
          />
        </div>
        <Link
          className="botao-grande"
          to="#"
          style={{
            display: modoAvancar ? "none" : "flex",
          }}
          onClick={() => verificAvancar()}
        >
          Avançar
        </Link>
      </div>
      <div
        className="grupo-button-form"
        style={{ display: modoAvancar2 ? "block" : "none" }}
      >
        <div className="numIndic-Paciente">
          <p>Página 2/2</p>
        </div>
        <div className="grupo-nome">
          <Input
            className="input-nome"
            placeholder="Nome do pai"
            value={fathers_name}
            type="text"
            setValue={setFathers_name}
            id="fathers_name"
            label="Nome do Pai"
            name="fathers_name"
          />
          <Input
            className="input-nome"
            placeholder="Nome da mãe"
            value={mothers_name}
            type="text"
            setValue={setMothers_name}
            id="mothers_name"
            label="Nome da Mãe"
            name="mothers_name"
          />
        </div>
        <div className="grupo-nome">
          <Input
            className="input-nome"
            placeholder="Nome do responsável"
            value={responsible_name}
            type="text"
            setValue={setResponsible_name}
            id="responsible_name"
            label="Nome do responsável"
            name="responsible_name"
          />

          <select
            placeholder="Etnia"
            value={race}
            onChange={(e) => setRace(parseInt(e.target.value))}
            required
          >
            <option value={0}>-- Etnia --</option>
            <option value={1}>Amarela</option>
            <option value={2}>Branca</option>
            <option value={3}>Negra</option>
            <option value={4}>Pardo</option>
          </select>
        </div>
        <select
          placeholder="Escolaridade"
          value={escolaridade}
          onChange={(e) => setEscolaridade(e.target.value)}
          required
        >
          <option value="" style={{ textColor: "#6e757d" }}>
            -- Escolaridade --
          </option>
          <option value="Fundamental - Incompleto">
            Fundamental - Incompleto
          </option>
          <option value="Fundamental - Completo">Fundamental - Completo</option>
          <option value="Médio - Incompleto">Médio - Incompleto</option>
          <option value="Médio - Completo">Médio - Completo</option>
          <option value="Superior - Incompleto">Superior - Incompleto</option>
          <option value="Superior - Completo">Superior - Completo</option>
          <option value="Pós-graduação - Incompleto">
            Pós-graduação - Incompleto
          </option>
          <option value="Pós-graduação - Completo">
            Pós-graduação - Completo
          </option>
          <option value="Mestrado - Incompleto">Mestrado - Incompleto</option>
          <option value="Mestrado - Completo">Mestrado - Completo</option>
          <option value="Doutorado - Incompleto">Doutorado - Incompleto</option>
          <option value="Doutorado - Completo">Doutorado - Completo</option>
        </select>
        <div className="grupo-estadoCivil-profCargo">
          <select
            value={marital_status}
            onChange={(e) => setMarital_status(parseInt(e.target.value))}
            required
          >
            <option value={0}>-- Estado Civil --</option>
            <option value={1}>Solteiro</option>
            <option value={2}>Casado</option>
            <option value={3}>Viúvo</option>
          </select>
          <Input
            className="input-nome"
            placeholder="Profissão"
            value={profession}
            type="text"
            setValue={setProfession}
            id="profession"
            label="Profissão"
            name="profession"
          />
          <Input
            type="text"
            className="input-nome"
            placeholder="Cargo"
            value={occupation}
            setValue={setOccupation}
            id="occupation"
            label="Cargo"
            name="occupation"
          />
        </div>
        <div className="grupo-rgNacCidadeNatal">
          <Input
            className="input-nome"
            placeholder="RG"
            value={rg}
            type="text"
            setValue={setRg}
            id="rg"
            label="RG"
            name="rg"
          />
          <select
            placeholder="Nacionalidade"
            value={toTreatNationality}
            onChange={(e) => setToTreatNacionality(e.target.value)}
            required
          >
            <option value={0}>-- Nacionalidade --</option>
            <option value={1}>Brasileiro</option>
            <option value={2}>Estrangeiro</option>
            <option value={3}>Naturalizado</option>
          </select>

          <Input
            className="input-nome"
            placeholder="Cidade de Nascimento"
            value={birth_town}
            type="text"
            setValue={setBirth_town}
            id="birth_town"
            label="Cidade de Nascimento"
            name="birth_town"
          />
        </div>
        <div className="grupo-nome">
          <Input
            type="text"
            className="input-nome"
            placeholder="nome@gmail.com"
            value={email}
            setValue={setEmail}
            id="Email"
            label="Email"
            name="Email"
          />

          <Input
            type="text"
            className="input-nome"
            placeholder="usuario123"
            value={username}
            setValue={setUsername}
            id="Username"
            label="Username"
            name="Username"
          />
        </div>
        <div className="grupo-nome">
          <Input
            className="input-nome"
            placeholder="*********"
            value={password}
            type="password"
            setValue={setPassword}
            id="Password"
            label="Senha"
            name="Password"
          />

          <Input
            className="input-nome"
            placeholder="*********"
            value={confirmationPassword}
            type="password"
            setValue={setConfirmationPassword}
            id="Confirmar Senha"
            label="Confirmar Senha"
            name="Confirmar Senha"
          />
        </div>

        <div className="botoes-form">
          <Link
            to="#"
            className="botao-grande voltar"
            onClick={() => {
              setDesativa("");
              setAvancar2(false);
            }}
          >
            Voltar
          </Link>
          <button
            type="submit"
            className="botao-grande cadastrar"
            onClick={() => {
              RegistrarPaciente();
            }}
          >
            Cadastrar
          </button>
        </div>
      </div>
    </div>
  );
}

function Input({
  id,
  label,
  value,
  setValue,
  type,
  placeholder,
  estado,
  occupation,
  maxlength,
  valueint,
  required,
}) {
  const className = document.getElementById(`box-${id}`);
  var verifyInt = /\d+/g;
  var verifyEmail = /\S+@\S+\.+com/;

  function ModificaValor(e) {
    switch (e.target.id) {
      case 'cpf':
        if(e.target.value === '000.000.000-00' || (e.target.value.length < 14)){
          setValue(e.target.value);
          className.className += ' erro';
          erroForm = true;
        }
        else{
          className.className = 'meu-box';
          setValue(e.target.value);
          erroForm = false;
        }
        break;

      case 'Name':
        if(e.target.value.match(verifyInt) !== null){
          setValue(e.target.value);
          className.className += ' erro';
          erroForm = true;
        }
        else{
          className.className = 'meu-box';
          setValue(e.target.value);
          erroForm = false;
        }
        break;

      case 'Sobrenome':
        if(e.target.value.match(verifyInt) !== null){
          setValue(e.target.value);
          className.className += ' erro';
          erroForm = true;
        }
        else{
          className.className = 'meu-box';
          setValue(e.target.value);
          erroForm = false;
        }
        break;

      case 'Email':
        if(verifyEmail.test(e.target.value)){
          className.className = 'meu-box';
          setValue(e.target.value);
          erroForm = false;
        }else {
          setValue(e.target.value);
          className.className += ' erro';
          erroForm = true;
        }
        break;

      default:
        setValue(e.target.value);
        break;
    }
  }

  function ModificaValorInt(e) {
    setValue(parseInt(e.target.value));
  }

  const isRequired = required ? "required" : "";

  return (
    <div className={`conteudo ${occupation}`}>
      <div id={`box-${id}`} className='meu-box'>
        <input
          className="input-nome"
          type={type}
          id={id}
          name={id}
          value={value}
          placeholder={placeholder}
          onChange={valueint ? ModificaValorInt : ModificaValor}
          disabled={estado}
          maxLength={maxlength}
          required={isRequired}
        />

        {erroForm ?
          <label htmlFor={id} className="label-nome error">
            <span>*{label} inválido</span>
          </label>
        :
          <label htmlFor={id} className="label-nome">
            <span>{label}</span>
          </label>
        }
      
      </div>
    </div>
  );
}
