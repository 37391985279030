/*
Data de criação: 21/10/2020;
Autor: Paulo Lopes;
Descrição: Painel Principal do Atendente, reune informações acerca do paciente e consultas;
*/
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//Componentes
import Menu from "../../../Componentes/GlobalMenu/indice";
import Cartao from "../../../Componentes/Atendente/Cartao/indice";
import InformacoesPacientes from "../../../Componentes/Atendente/InformacoesPacientes/indice";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";
import ModalAnotacao from '../../../Componentes/Atendente/ModalAnotacao/indice';
import swal from "sweetalert";

import { FiCalendar, FiChevronsRight, FiBarChart } from "react-icons/fi";

import api from "../../../Servicos/api";

import "./estilos.css";

export default function PainelPrincipalAtendente() {
  const id = localStorage.getItem("id");
  const token = localStorage.getItem("token");
  const name_first = localStorage.getItem("name_first");
  const employee_id = localStorage.getItem("employee_id");
  const foto = localStorage.getItem("photo");
  const [totalPacientes, setTotalPacientes] = useState(0);
  // const [idadesPacientes, setIdadesPacientes] = useState([]);

  const [anotacao, setAnotacao] = useState();
  const [assunto, setAssunto] = useState();
  const [consultas, setTotalConsultas] = useState();
  const [consultAverage, setConsultAverage] = useState();
  const [consultReturn, setConsultReturn] = useState();
  const [exibeEstatisticas, setExibeEstatisticas] = useState(false);
  const [abrirModalAnotacao, setAbrirModalAnotacao] = useState(false);

  const history = useHistory();

  //Buscar dados dos pacientes

  useEffect(() => {
    api
      .get(`/attendant/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {

        setTotalConsultas(response.data.consult[0].count || "Nenhuma");
        setConsultAverage((response.data.consultAverage[0]?.duration ?? 0) + " min.");
        setConsultReturn(
          response.data.consultReturn[0].count ||
          "Nenhuma consulta para retorno"
        );

        setTotalPacientes(
          response.data.patientsTotal[0].count === undefined ? "0" : response.data.patientsTotal[0].count
        );
        setExibeEstatisticas(true);
      })
      .catch((error) => console.log(error));
  }, [id, token]);

  async function enviarAnotacao() {
    const data = {
      text: anotacao,
      subject: assunto,
    }

    if (anotacao !== undefined && anotacao !== "") {
      try {
        await api.post(`annotation/${employee_id}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        swal("Anotação cadastrada com sucesso", "", "success");
        history.push("/atendente");
        history.push("/atendente/inicio");

      } catch (error) {
        
      }
    } else {
      swal("Preencha o campo anotação", "Para cadastrar", "error");
    }
  }

  return (
    <div id="PainelPrincipalAtendente">
      <Menu />
      <div className="ConteudoAtendente">
        <header>
          <h1>Bem-vindo, {name_first}.</h1>
          <div id="Perfil">
            <div id="FotoPerfil">
              <img src={foto} alt="Foto da Clínica" />
            </div>
          </div>
        </header>

        <main>
          <div id="Titulo">
            <span>Painel</span>
            <FiChevronsRight id="Icone" />
            <span>Visão geral &amp; Estatísticas*</span>
            <Ajuda
              mensagem={
                "Nesta tela é possível vizualizar as consultas agendadas, os retornos programados, a média de consultas, o total de pacientes e ainda possibilita cadastrar e vizualizar anotações. "
              }
              width={"35rem"}
              backColor={"var(--cor-branco)"}
              fontColor={"var(--cor-azul)"}
              margin={"-1.7rem 0 0 1.5rem"}
              border={"0.5rem"}
              size={"2.5rem"}
            />
          </div>

          <section id="Cartoes">
            <Cartao
              icone={<FiCalendar className="Icone" />}
              cor="var(--cor-azul-escuro)"
              titulo="Consultas Agendadas"
              valor={consultas}
            />

            <Cartao
              icone={<FiBarChart className="Icone" />}
              cor="var(--cor-azul-claro)"
              titulo="Média de Duração das Consultas"
              valor={consultAverage}
            />

            <Cartao
              icone={<FiCalendar className="Icone" />}
              cor="var(--cor-azul)"
              titulo="Retornos Programados"
              valor={consultReturn}
            />
          </section>

          <section id="Estatisticas">
            {exibeEstatisticas ? (
              <>
                <InformacoesPacientes totalPacientes={totalPacientes} />
                <div className="AnotacoesAtendente">
                  <label htmlFor="anotacao">Anotação:</label>
                  <textarea
                    value={anotacao}
                    placeholder="Digite aqui sua anotação."
                    onChange={(e) => setAnotacao(e.target.value)}
                    name="anotacao" 
                    id="anotacao"
                  />
                  
                  <label htmlFor="assunto">Assunto:</label>
                  <textarea
                    value={assunto}
                    placeholder="Digite o assunto da anotação."
                    onChange={(e) => setAssunto(e.target.value)}
                    name="assunto" 
                    id="assunto"
                  />

                  <button 
                    id="modalButton" 
                    onClick={() => setAbrirModalAnotacao(true)} 
                    type="button"
                  >
                    Anotações
                  </button>
                  
                  <button 
                    id="saveButton" 
                    onClick={enviarAnotacao} 
                    type="button"
                  >
                    Salvar
                  </button>
                </div>
              </>
            ) : (
              <div />
            )}
          </section>
        </main>
      </div>
      <ModalAnotacao abrirModal={abrirModalAnotacao} setAbrirModal={setAbrirModalAnotacao} />
    </div>
  );
}
