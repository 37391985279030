/*
Data de criação: 18/08/2020;
Autor: Jeciane Amorim;
Descrição: Página de visualização do perfil, responsável por disponibilizar
ao usuário os dados do perfil e o gerenciamento dos dados do perfil e exclusão da sua conta.
*/

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { FiEdit3 } from "react-icons/fi";
import "./estilos.css";
import api from "../../../Servicos/api";
import axios from "axios";
import Menu from "../../../Componentes/GlobalMenu/indice";
import swal from "sweetalert";
import photo from "../../../Arquivos/avatarPadrao.svg";
export default function PerfilPacienteClinica() {
  const history = useHistory();

  const token = localStorage.getItem("token");
  const idUserGet = localStorage.getItem("idUserGet");
  const idUserPut = localStorage.getItem("idUserPut");

  const [name_first, setName_first] = useState("");
  const [name_last, setName_last] = useState();
  const [username, setUsername] = useState();
  const [escolaridade, setEscolaridade] = useState();
  const [cpf, setCpf] = useState("");
  const [data_birth, setData_birth] = useState();
  const [race, setRace] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [age, setAge] = useState();
  //const photo = "";

  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [number, setNumber] = useState();
  const [street, setStreet] = useState();
  const [housign_type, setHousign_type] = useState();
  const [cep, setCep] = useState();
  const [nationality, setNationality] = useState("");
  const [mothers_name, setMothers_name] = useState("");
  const [fathers_name, setFathers_name] = useState("");
  const [responsible_name, setResponsible_name] = useState("");
  //const [religion, setReligion] = useState();
  const [occupation, setOcupation] = useState("");
  const [marital_status, setMarital_status] = useState("");
  const [gender, setGender] = useState("");
  const [profession, setProfession] = useState("");
  const [birth_town, setBirth_town] = useState("");
  const [rg, setRg] = useState("");
  const [listaEstados, setListaEstados] = useState([]);
  const [listaCidades, setListaCidades] = useState([]);

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => {
        const estadoInitials = response.data.map((estado) => estado.sigla);

        setListaEstados(estadoInitials);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (!state || state === "0") {
      return setListaCidades([]);
    }

    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`
      )
      .then((response) => {
        const cidadeNome = response.data.map((cidade) => cidade.nome);

        setListaCidades(cidadeNome);
      })
      .catch((error) => console.log(error));
  }, [state]);

  useEffect(() => {
    api
      .get(`getPatient/${idUserGet}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const dados = response.data.person;
        const data = response.data;
        setUsername(dados.user[0].username);
        setName_first(dados.name_first);
        setName_last(dados.name_last);
        setEscolaridade(dados.user[0].escolaridade);
        setCpf(dados.cpf);
        if (dados.data_birth !== null) {
          setData_birth(dados.data_birth.substring(0, 10));
        }
        setAge(dados.age);
        setRace(dados.race);
        setEmail(dados.user[0].email);
        setPhone(dados.phone);
        setState(dados.address.state);
        setCity(dados.address.city);
        setStreet(dados.address.street);
        setNumber(dados.address.number);
        setHousign_type(dados.address.housign_type);
        setCep(dados.address.cep);
        setGender(dados.gender);

        setFathers_name(data.fathers_name);
        setMothers_name(data.mothers_name);
        setResponsible_name(data.responsible_name);
        setMarital_status(data.marital_status);

        setProfession(data.profession);
        setOcupation(data.occupation);
        setRg(data.rg);
        setNationality(data.nationality);

        setBirth_town(data.birth_town);
      });
  }, [idUserGet, token]);

  async function Cancelar() {
    await api
      .get(`getPatient/${idUserGet}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const dados = response.data.person;
        const data = response.data;
        setUsername(dados.user.username);
        setName_first(dados.name_first);
        setName_last(dados.name_last);
        setEscolaridade(dados.user.escolaridade);
        setCpf(dados.cpf);
        setData_birth(dados.data_birth.substring(0, 10));
        setAge(dados.age);
        setRace(dados.race);
        setEmail(dados.user.email);
        setPhone(dados.phone);
        setState(dados.address.state);
        setCity(dados.address.city);
        setStreet(dados.address.street);
        setNumber(dados.address.number);
        setHousign_type(dados.address.housign_type);
        setCep(dados.address.cep);
        setGender(dados.gender);

        setFathers_name(data.fathers_name);
        setMothers_name(data.mothers_name);
        setResponsible_name(data.responsible_name);

        setMarital_status(data.marital_status);

        //setMarital_status(data.marital_status);
        setProfession(data.profession);
        setOcupation(data.occupation);
        setRg(data.rg);
        setNationality(data.nationality);
        //setNationality(data.nationality);
        setBirth_town(data.birth_town);
      });
  }
  async function AlterarPerfil(e) {
    e.preventDefault();
    const religion = 0;
    try {
      const data = {
        escolaridade,
        race,
        data_birth,
        name_first,
        name_last,
        cpf,
        gender,
        phone,
        nationality,
        birth_town,
        mothers_name,
        fathers_name,
        responsible_name,
        marital_status,
        religion,
        profession,
        occupation,
        rg,
        state,
        city,
        cep,
        number,
        street,
        housign_type,
      };
      await api.put(`patient/${idUserPut}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      swal("Dados alterados com Sucesso", "", "success");
      history.goBack();
    } catch (err) {
      swal(err.response.data.error, "Digite novamente", "error");
    }
  }

  const [modoEdicao, setModoEdicao] = useState("");

  return (
    <div className="perfil-container-paciente">
      <Menu />

      <section className="conteudo-perfil">
        <div className="container-formulario">
          <div className="container-perfil">
            <img src={photo} alt="Foto de Perfil" />

            <div className="informacao">
              <h1 className="informacao-nome">{name_first}</h1>
              <h4 className="informacao-idade">{age} anos</h4>
              <div className="informacao-acao">
                <Link
                  to="#"
                  className={`informacao-acao-editar ${modoEdicao}`}
                  onClick={() => setModoEdicao("editar")}
                >
                  <FiEdit3 className="icon" />
                  Alterar Perfil
                </Link>
              </div>
            </div>
          </div>
          <form onSubmit={AlterarPerfil}>
            <h2 className="titulo-formulario">Meus dados</h2>
            <div className="container-input">
              <div>
                <label htmlFor="nome">Nome</label>
                <input
                  id="nome"
                  value={name_first || ""}
                  disabled={modoEdicao === "editar" ? false : true}
                  onChange={(e) => setName_first(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="sobrenome">Sobrenome</label>
                <input
                  id="sobrenome"
                  value={name_last || ""}
                  disabled={modoEdicao === "editar" ? false : true}
                  onChange={(e) => setName_last(e.target.value)}
                />
              </div>
            </div>
            <div className="container-input" id="input-escolaridade">
              <div>
                <label htmlFor="escolaridade">Escolaridade</label>
                <select
                  id="escolaridade"
                  value={escolaridade || ""}
                  disabled={modoEdicao === "editar" ? false : true}
                  onChange={(e) => setEscolaridade(e.target.value)}
                >
                  <option value={escolaridade || ""}>
                    {escolaridade}
                  </option>
                  <option value="Fundamental - Incompleto">
                    Fundamental - Incompleto
                  </option>
                  <option value="Fundamental - Completo">
                    Fundamental - Completo
                  </option>
                  <option value="Médio - Incompleto">Médio - Incompleto</option>
                  <option value="Médio - Completo">Médio - Completo</option>
                  <option value="Superior - Incompleto">
                    Superior - Incompleto
                  </option>
                  <option value="Superior - Completo">
                    Superior - Completo
                  </option>
                  <option value="Pós-graduação - Incompleto">
                    Pós-graduação - Incompleto
                  </option>
                  <option value="Pós-graduação - Completo">
                    Pós-graduação - Completo
                  </option>
                  <option value="Mestrado - Incompleto">
                    Mestrado - Incompleto
                  </option>
                  <option value="Mestrado - Completo">
                    Mestrado - Completo
                  </option>
                  <option value="Doutorado - Incompleto">
                    Doutorado - Incompleto
                  </option>
                  <option value="Doutorado - Completo">
                    Doutorado - Completo
                  </option>
                </select>
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="cpf">CPF</label>
                <input
                  disabled={modoEdicao === "editar" ? false : true}
                  id="cpf"
                  value={
                    modoEdicao === "editar"
                      ? cpf
                      : `${cpf[0] + cpf[1] + cpf[2]}.***.***-**` || ""
                  }
                  onChange={(e) => setCpf(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="data_birth">Data de nascimento</label>
                <input
                  disabled={modoEdicao === "editar" ? false : true}
                  id="data_birth"
                  format="yyyy-MM-dd"
                  value={data_birth || ""}
                  onChange={(e) => setData_birth(e.target.value)}
                />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="race">Cor/Raça</label>
                <select
                  placeholder="Etnia"
                  value={race || 0}
                  onChange={(e) => setRace(parseInt(e.target.value))}
                  disabled={modoEdicao === "editar" ? false : true}
                  required
                >
                  <option value="">-- Etnia --</option>
                  <option value="1">Amarela</option>
                  <option value="2">Branca</option>
                  <option value="3">Negra</option>
                  <option value="4">Pardo</option>
                </select>
              </div>
              <div>
                <label htmlFor="telefone">Telefone</label>
                <input
                  disabled={modoEdicao === "editar" ? false : true}
                  id="telefone"
                  value={phone || ""}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="email">Email</label>
                <input
                  disabled={modoEdicao === "editar" ? false : true}
                  id="email"
                  value={email || ""}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="username">Nome do Usuário</label>
                <input
                  disabled={modoEdicao === "editar" ? false : true}
                  id="username"
                  value={username || ""}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="nationality">Nacionalidade</label>
                <select
                  disabled={modoEdicao === "editar" ? false : true}
                  id="nationality"
                  value={nationality}
                  onChange={(e) => setNationality(parseInt(e.target.value))}
                  required
                >
                  <option value="">-- Nacionalidade --</option>
                  <option value="1">Brasileiro</option>
                  <option value="2">Estrangeiro</option>
                  <option value="3">Naturalizado</option>
                </select>
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="mothers_name">Nome da Mãe</label>
                <input
                  disabled={modoEdicao === "editar" ? false : true}
                  id="mothers_name"
                  value={mothers_name || ""}
                  onChange={(e) => setMothers_name(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="fathers_name">Nome do Pai</label>
                <input
                  disabled={modoEdicao === "editar" ? false : true}
                  id="fathers_name"
                  value={fathers_name || ""}
                  onChange={(e) => setFathers_name(e.target.value)}
                />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="responsible_name">Nome do Responsavel</label>
                <input
                  disabled={modoEdicao === "editar" ? false : true}
                  id="responsible_name"
                  value={responsible_name || ""}
                  onChange={(e) => setResponsible_name(e.target.value)}
                />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="occupation">Ocupação</label>
                <input
                  disabled={modoEdicao === "editar" ? false : true}
                  id="occupation"
                  value={occupation || ""}
                  onChange={(e) => setOcupation(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="profession">Profissão</label>
                <input
                  disabled={modoEdicao === "editar" ? false : true}
                  id="profession"
                  value={profession || ""}
                  onChange={(e) => setProfession(e.target.value)}
                />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="marital_status">Estado Civil</label>
                <select
                  disabled={modoEdicao === "editar" ? false : true}
                  value={marital_status}
                  onChange={(e) => setMarital_status(parseInt(e.target.value))}
                  required
                >
                  <option value="">-- Estado Civil --</option>
                  <option value="1">Solteiro</option>
                  <option value="2">Casado</option>
                  <option value="3">Viuvo</option>
                </select>
              </div>
              <div>
                <label htmlFor="gender">Gênero</label>
                <select
                  placeholder="Gênero"
                  disabled={modoEdicao === "editar" ? false : true}
                  value={gender}
                  onChange={(e) => setGender(parseInt(e.target.value))}
                  required
                >
                  <option value="">-- Sexo --</option>
                  <option value="1">Masculino</option>
                  <option value="2">Feminino</option>
                  <option value="3">Outro</option>
                </select>
              </div>
            </div>
            <div className="container-input">
              <div>
                <label>Cidade de Nascimento</label>
                <input
                  disabled={modoEdicao === "editar" ? false : true}
                  value={birth_town || ""}
                  onChange={(e) => setBirth_town(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="rg">RG</label>
                <input
                  disabled={modoEdicao === "editar" ? false : true}
                  id="rg"
                  value={rg || ""}
                  onChange={(e) => setRg(e.target.value)}
                />
              </div>
            </div>
            <h2 className="titulo-formulario">Endereço</h2>
            <div id="input-estado" className="container-input">
              <div>
                <label htmlFor="state">Estado</label>
                <select
                  id="state"
                  value={state || ""}
                  onChange={(e) => setState(e.target.value)}
                  disabled={modoEdicao === "editar" ? false : true}
                >
                  <option value={state || ""}>
                    {state}
                  </option>
                  {listaEstados.map((uf) => (
                    <option key={uf} value={uf}>
                      {uf}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="city">Cidade</label>
                <select
                  id="city"
                  value={city || ""}
                  onChange={(e) => setCity(e.target.value)}
                  disabled={modoEdicao === "editar" ? false : true}
                >
                  <option value={city}>
                    {city}
                  </option>
                  {listaCidades.map((listaCidade) => (
                    <option key={listaCidade} value={listaCidade}>
                      {listaCidade}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div id="input-numero" className="container-input">
              <div>
                <label htmlFor="street">Rua</label>
                <input
                  id="street"
                  value={street || ""}
                  disabled={modoEdicao === "editar" ? false : true}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="number">Número</label>
                <input
                  id="number"
                  value={number || 0}
                  disabled={modoEdicao === "editar" ? false : true}
                  onChange={(e) => setNumber(parseInt(e.target.value))}
                />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="housign_type">Tipo da Residência</label>
                <select
                  value={housign_type || ""}
                  onChange={(e) => setHousign_type(e.target.value)}
                  disabled={modoEdicao === "editar" ? false : true}
                >
                  <option value="">-- Complemento --</option>
                  <option value="Casa">Casa</option>
                </select>
              </div>

              <div>
                <label htmlFor="cep">CEP</label>
                <input
                  id="cep"
                  value={cep || 0}
                  disabled={modoEdicao === "editar" ? false : true}
                  onChange={(e) => setCep(parseInt(e.target.value))}
                />
              </div>
            </div>
            <div className={`container-botao ${modoEdicao}`}>
              <button
                className="botao-grande"
                onClick={() => {
                  setModoEdicao("");
                  Cancelar();
                }}
                type="reset"
                formNoValidate
              >
                Cancelar
              </button>
              <button className="botao-grande" type="submit">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
