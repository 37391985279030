import React from 'react';

import './estilos.css';

export default function CartaoConsulta({ icone, titulo, numero, corFundo }) {

  return (
    <div className="Cartao" style={{backgroundColor: corFundo }}>
    {icone}
    <div className="Informacao">
      <h3>{titulo}</h3>
      <h1>{numero}</h1>
    </div>
  </div>
  );
}