import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import api from "../../../Servicos/api";

import styles from "./CriarPlano.module.css";

function CadastrarPlano({ abrirModal, setAbrirModal }) {
    const token = localStorage.getItem("token");
    const history = useHistory();
    const manager_id = localStorage.getItem("id_clinica");

    const [identification_name, setIdentification_name] = useState("");
    const [provider, setProvider] = useState("");
    const [planPrice, setPlanPrice] = useState("");
    const [planDate, setPlanDate] = useState("");
    const [description, setDescription] = useState("");

    async function cadastrarPlano() {
        let price = parseFloat(planPrice);
        let payday = parseInt(planDate);

        const data = {
            manager_id,
            identification_name,
            price,
            provider,
            description,
            payday,
        };
        
        console.log(token);

        try {
            await api.post("healthInsurance", data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            swal("Cadastrado com Sucesso", "", "success");
            setAbrirModal(false);
            history.push("/clinica/inicio");
            history.push("/clinica/planos");
        } catch (err) {
            swal(err.response.data.error, "Tente novamente", "error");
        }
    }

    if (abrirModal === true) {
        return (
            <div className={styles.SobreposicaoDadosCadastrais}>
                <section className={styles.ModalDadosCadastrais}>
                    <header>
                        <h2>Cadastrar Plano</h2>
                        <button
                            onClick={() => {
                                setAbrirModal(false);
                            }}
                        >
                            X
                        </button>
                    </header>
                    <main className={styles.form}>
                        <form action="">
                            <div className={styles.linhaForm}>
                                <div className={styles.inputLabel}>
                                    <label htmlFor="nome">Nome do Plano</label>
                                    <input
                                        className={styles.inputClass}
                                        type="text"
                                        name="nome"
                                        value={identification_name}
                                        onChange={e => setIdentification_name(e.target.value)}
                                    />
                                </div>
                                <div className={styles.inputLabel}>
                                    <label htmlFor="convenio">Convênio</label>
                                    <input
                                        className={styles.inputClass}
                                        type="text"
                                        name="convenio"
                                        value={provider}
                                        onChange={e => setProvider(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className={styles.linhaForm}>
                                <div className={styles.inputLabelDois}>
                                    <label htmlFor="preco">Preço</label>
                                    <input
                                        className={styles.inputClass}
                                        type="number"
                                        name="preco"
                                        value={planPrice}
                                        onChange={e => setPlanPrice(e.target.value)}
                                    />
                                </div>

                                <div className={styles.inputLabel}>
                                    <label htmlFor="vencimento">Vencimento</label>
                                    <select
                                        className={styles.inputClass}
                                        name="vencimento"
                                        id="vencimento"
                                        value={planDate}
                                        onChange={e => setPlanDate(e.target.value)}
                                    >
                                        <option defaultValue>Selecione</option>
                                        <option>Dia 15 de cada mês</option>
                                        <option>Dia 25 de cada mês</option>
                                        <option>Dia 30 de cada mês</option>
                                    </select>
                                </div>
                            </div>

                            <label htmlFor="descricao">Descrição</label>
                            <textarea
                                name="descricao"
                                id="descricao"
                                cols="30"
                                rows="10"
                                className={styles.inputClass}
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                            >
                            </textarea>
                        </form>
                    </main>
                    <footer>
                        <button className={styles.botaoSalvar}
                            onClick={cadastrarPlano}
                        >
                            Cadastrar
                        </button>
                    </footer>
                </section>
            </div>
        );
    }
    return null;
}

export default CadastrarPlano;
