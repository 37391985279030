/*
Data de criação: 02/09/2020;
Autor: Jeciane Amorim;
Descrição: ;
*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../Servicos/api';
import useDebounce from '../../../Servicos/useDebounce';

import foto from '../../../Arquivos/avatarPadrao.svg';
import './estilos.css';

import Menu from '../../../Componentes/GlobalMenu/indice';
import Table from '../../../Componentes/Tables/TabelaMedico';
import Paginacao from '../../../Componentes/Paginacao/indice';
import Form from '../../../Componentes/Medico/Modal-Cadastro-Medico/indice';
import Ajuda from '../../../Componentes/Geral/Ajuda/Ajuda';
import AnimacaoCarregando from '../../../Componentes/AnimacaoCarregando/indice';

export default function MedicosClinica() {
  //Dados Clínica
  const nomeFantasia = localStorage.getItem('fantasy_name');
  const nomeUsuario = localStorage.getItem('name_first');
  const permissao = localStorage.getItem('profile');


  const token = localStorage.getItem('token');

  //Listagem de Pacientes
  const [medicosLista, setMedicosLista] = useState([]);
  const [medicosListaTabela, setMedicosListaTabela] = useState([]);
  const [AbrirDados, setAbrirDados] = useState(false);

  const limitPage = 7;
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    api
      .get(`/searchDoctors/?page=${page}&limit=${limitPage}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setMedicosLista(response.data.data);
        setMedicosListaTabela(response.data.data);
        setTotal(response.data.total);
      });
  }, [token, AbrirDados, page]);

  //Buscar Médicos pelo nome
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoading(true);
      api
        .get(`/searchAndFilterDoctors?search=${debouncedSearchTerm}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setMedicosListaTabela(response.data);
          setLoading(false);
        });
    }
  }, [debouncedSearchTerm, token]);

  useEffect(() => {
    if (searchValue === '') {
      setMedicosListaTabela(medicosLista);
    }
  }, [searchValue, medicosLista]);

  //Título Tabela
  const titulos = [
    { nome: 'CRM' },
    { nome: 'Nome' },
    { nome: 'CPF' },
    { nome: 'Telefone' },
    { nome: 'Especialização' },
    { nome: 'Opções' },
  ];

  return (
    <div className="ContainerClinicaMedicos">
      <Menu />
      <div className="Container-conteudo">
        <header>
          <h1>Bem-vindo, {nomeFantasia ? nomeFantasia : nomeUsuario}.</h1>
          <div className="Perfil">
            <div className="FotoPerfil">
              <img src={foto} alt="Foto de Perfil" />
            </div>
          </div>
        </header>
        <nav>
          <div className="navegacao">
            <h2>{'Médico  '}</h2>
            <h5>
              {'>>'} {'Busca & Visão geral *'}
            </h5>
            <div id="div-ajuda">
              <Ajuda
                mensagem={
                  'Nesta tela é possivel gerenciar os médicos da sua clínica, possibilitando cadastrar, atualizar, visualizar e remover.'
                }
                width={'40rem'}
                backColor={'var(--cor-branco)'}
                fontColor={'var(--cor-azul)'}
                margin={'0 0 0 1.5rem'}
                border={'0.5rem'}
                size={'2.5rem'}
              />
            </div>
          </div>
        </nav>
        <section id="section-clinica-medicos">
          <div className="pesquisa">
            <div className="box-pesquisa">
              <input
                value={searchValue}
                type="text"
                className="nome-input"
                placeholder="Buscar por nome do médico"
                onChange={(e) =>
                  setSearchValue(e.target.value.replace(/[^a-zA-Z ]/g, ''))
                }
                onKeyPressCapture={(e) =>
                  setSearchValue(e.target.value.replace(/[^a-zA-Z ]/g, ''))
                }
              />
              <label className="label-input">Pesquisa</label>
            </div>
          </div>
          {(permissao === "MHDG" || permissao === "MHMA") && (
  <Link
    to="#"
    className="botao-grande cadastro"
    onClick={() => setAbrirDados(true)}
  >
    Cadastrar Médico 
  </Link>
)}


          <Form abrirDados={AbrirDados} setAbrirDados={setAbrirDados} />
          {loading ? (
            <AnimacaoCarregando color="#1070a1" />
          ) : (
            <>
              <Table
                titles={titulos}
                tableItems={medicosListaTabela}
                route="/clinica/medico/perfil"
              />
              {medicosListaTabela && (
                <Paginacao
                  limitPage={limitPage}
                  dataLength={total}
                  setPage={setPage}
                />
              )}
            </>
          )}
        </section>
      </div>
    </div>
  );
}
