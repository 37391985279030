import React from "react";
import { Link } from "react-router-dom";
import { FiX } from "react-icons/fi";
import "./estilos.css";


function FinalizarConsulta({ modalFinalizar, setModalFinalizar, resume, duration}) {
  
  if (modalFinalizar === true)
    return (
      <div className="SobreposicaoFinalizarConsulta">
        <section>
          <FiX className="Icone" onClick={resume} />
          <h2>Deseja encerrar a consulta?</h2>
          <div>
            <Link to="/medico/listaconsultas" className="BtnFinalizar">
              Cancelar Consulta
            </Link>
            <button type="submit">Finalizar Consulta</button>
          </div>
        </section>
      </div>
    );

  return null;
}

export default FinalizarConsulta;
