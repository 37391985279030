/*
Data de criação: 01/09/2020;
Autor: Paulo Lopes;
Descrição: Painel Principal da Clínica, reune informações acerca do paciente e consultas;
*/
import React, { useState, useEffect } from "react";
import api from "../../../Servicos/api";

//Componentes
import Menu from "../../../Componentes/GlobalMenu/indice";
import Cartao from "../../../Componentes/Clinica/Cartao/indice";
import InformacoesPacientes from "../../../Componentes/Clinica/InformacoesPacientes/indice";
import ConsultasDiarias from "../../../Componentes/Clinica/ConsultasDiarias/indice";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";

import { FiCalendar, FiChevronsRight, FiBarChart } from "react-icons/fi";

import FotoPadrao from "../../../Arquivos/avatarPadrao.svg";
import "./estilos.css";

export default function PainelPrincipalClinica() {
  const id = localStorage.getItem("id");
  const token = localStorage.getItem("token");
  const fantasy_name = localStorage.getItem("fantasy_name");
  const [totalPacientes, setTotalPacientes] = useState(0);
  const [idadesPacientes, setIdadesPacientes] = useState([]);
  const [exibeEstatisticas, setExibeEstatisticas] = useState(false);

  const [consultasAgendadas, setConsultasAgendadas] = useState("");
  const [mediaConsultasAgendadas, setMediaConsultasAgendadas] = useState("");
  const [retornosProgramados, setRetornosProgramados] = useState("");

  //Buscar dados dos pacientes

  useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api
      .get(`/manager/dashboard`)
      .then((response) => {
        const pacientesClinica = response.data.countAllPatient;
        const idadesPacientesClinica = response.data.ageRange;

        setConsultasAgendadas(response.data.consult[0].count);
        setMediaConsultasAgendadas(response.data.consultAverage[0].duration || "0");
        setRetornosProgramados(response.data.consultReturn[0].count);

        setTotalPacientes(pacientesClinica);
        setIdadesPacientes(idadesPacientesClinica);
        setExibeEstatisticas(true);
      })
      .catch((error) => console.log(error));
  }, [id, token]);

  return (
    <div className="PainelPrincipalClinica">
      <Menu />

      <div className="ConteudoClinica">
        <header>
          <h1>Bem-vindo, {fantasy_name}.</h1>
          <div id="Perfil">
            <div id="FotoPerfil">
              <img src={FotoPadrao} alt="Foto da Clínica" />
            </div>
          </div>
        </header>

        <main>
          <div id="Titulo">
            <span>Painel</span>
            <FiChevronsRight id="Icone" />
            <span>Visão geral &amp; Estatísticas</span>
            <Ajuda
              mensagem={
                "Nesta tela é possivel visualizar informações gerais sobre a clínica."
              }
              width={"35rem"}
              backColor={"var(--cor-branco)"}
              fontColor={"var(--cor-azul)"}
              margin={"0 0 0 1.5rem"}
              border={"0.5rem"}
              size={"2.5rem"}
            />
          </div>

          <section id="Cartoes">
            <Cartao
              icone={<FiCalendar className="Icone" />}
              cor="var(--cor-azul-escuro)"
              titulo="Consultas Agendadas"
              valor={consultasAgendadas}
            />

            <Cartao
              icone={<FiBarChart className="Icone" />}
              cor="var(--cor-azul-claro)"
              titulo="Média de Duração das Consultas"
              valor={mediaConsultasAgendadas + " min."}
            />

            <Cartao
              icone={<FiCalendar className="Icone" />}
              cor="var(--cor-azul)"
              titulo="Retornos Programados"
              valor={retornosProgramados}
            />
          </section>

          <section id="Estatisticas">
            {exibeEstatisticas ? (
              <>
                <InformacoesPacientes
                  totalPacientes={totalPacientes}
                  idadesPacientes={idadesPacientes}
                />

                <ConsultasDiarias />
              </>
            ) : (
              <div></div>
            )}
          </section>
        </main>
      </div>
    </div>
  );
}
