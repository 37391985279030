/*
Data de criação: 18/08/2020;
Autor: Elizieb Luiz;
Descrição: Página de configuração, responsável por editar os dados de uma clinica.
*/

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { FiEdit3, FiXCircle, FiCamera } from "react-icons/fi";
import api from "../../../Servicos/api";

import "./estilos.css";
import Menu from "../../../Componentes/GlobalMenu/indice";
import ModalFoto from "../../../Componentes/Geral/ModalFoto/indice";
import defaultPhoto from "../../../Arquivos/avatarPadrao.svg";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";
import { cepMask } from "../../../Servicos/Mascaras/cepMask";
import { telefoneMascara } from "../../../Servicos/Mascaras/telefoneMascara";

export default function Configuracoes() {
  const [modoEdicao, setModoEdicao] = useState("");

  const history = useHistory();

  const [name_fantasia, setName_fantasia] = useState(localStorage.getItem("fantasy_name"));
  const [razao_social, setRazao_social] = useState(localStorage.getItem("social_reason"));
  const [cnpj, setCnpj] = useState(localStorage.getItem("cnpj"));
  const [cnes, setCnes] = useState(localStorage.getItem("cnes"));
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [phone, setPhone] = useState(localStorage.getItem("phone"));
  const [photo, setPhoto] = useState(localStorage.getItem("photo"));

  const id = localStorage.getItem("id_clinica");
  const token = localStorage.getItem("token");

  const [state, setState] = useState(localStorage.getItem("state"));
  const [city, setCity] = useState(localStorage.getItem("city"));
  const [number, setNumber] = useState(localStorage.getItem("number"));
  const [street, setStreet] = useState(localStorage.getItem("street"));
  const [cep, setCep] = useState(localStorage.getItem("cep"));

  const [listaEstados, setListaEstados] = useState([]);
  const [listaCidades, setListaCidades] = useState([]);

  const [modalFoto, setModalFoto] = useState(false);

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => {
        const estadoInitials = response.data.map((estado) => estado.sigla);

        setListaEstados(estadoInitials);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (!state || state === "0") {
      return setListaCidades([]);
    }

    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`
      )
      .then((response) => {
        const cidadeNome = response.data.map((cidade) => cidade.nome);

        setListaCidades(cidadeNome);
      })
      .catch((error) => console.log(error));
  }, [state]);

  async function AlterarPerfilClinica(e) {
    e.preventDefault();
    try {
      const housign_type = "";
      const fantasy_name = name_fantasia;
      const social_reason = razao_social;
      const data = {
        email,
        cnes,
        fantasy_name,
        social_reason,
        phone,
        address: {
          state,
          city,
          cep,
          number,
          street,
          housign_type,
        },
      };
      await api.put(`manager/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      swal("Dados alterados com Sucesso", "", "success");

      localStorage.setItem("fantasy_name", fantasy_name);
      localStorage.setItem("email", email);
      localStorage.setItem("cnes", cnes);
      localStorage.setItem("cnpj", cnpj);
      localStorage.setItem("fantasy_name", fantasy_name);
      localStorage.setItem("social_reason", social_reason);
      localStorage.setItem("phone", phone);
      localStorage.setItem("photo", photo);

      localStorage.setItem("state", state);
      localStorage.setItem("city", city);
      localStorage.setItem("cep", cep);
      localStorage.setItem("number", number);
      localStorage.setItem("street", street);
      localStorage.setItem("housign_type", housign_type);

      history.push("/clinica/inicio");
    } catch (err) {
      swal(err.response.data.error, "Digite novamente", "error");
    }
  }

  /* async function DeletePaciente() {
    swal({
      title: "Deseja realmente remover seu registro do sistema?",
      text: "Uma vez excluído, você não terá mais acesso a essa plataforma!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await api.delete(`patient/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          history.push("/paciente/login");
        } catch (err) {
          swal(err.response.data.msg, "Tente novamente", "error");
        }
        swal("Registro excluído com sucesso!", {
          icon: "success",
        });
      } else {
        swal("Sua conta não foi excluída!", { icon: "warning" });
      }
    });
  } */

  return (
    <div className="container-configuracao">
      <ModalFoto
        modalFoto={modalFoto}
        setModalFoto={setModalFoto}
        photo={photo}
        setPhoto={setPhoto}
      />
      <Menu />
      <section className="conteudo-configuracao">
        <div className="container-formulario">
          <div className="container-perfil">
            <img src={photo ? photo : defaultPhoto} alt="Foto de Perfil" />

            <div className="informacao">
              <button
                className="botao-nova-foto"
                onClick={() => {
                  setModalFoto(true);
                }}
              >
                <FiCamera style={{ color: "#ffffff", width: 20, height: 20 }} />
              </button>

              <h1 className="informacao-nome">{name_fantasia}</h1>

              <div className="informacao-acao">
                <button
                  className={`informacao-acao-editar ${modoEdicao}`}
                  onClick={() => setModoEdicao("editar")}
                >
                  <FiEdit3 className="icon" />
                  Alterar Perfil
                </button>
                <div id="div-ajuda">
                  <Ajuda
                    mensagem={
                      "Nesta tela é possivel editar as informações da clínica como também sua imagem."
                    }
                    width={"20rem"}
                    backColor={"var(--cor-branco)"}
                    fontColor={"var(--cor-azul)"}
                    margin={"2.0rem 0 0 -4.0rem"}
                    border={"0.5rem"}
                    size={"2.5rem"}
                  />
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={AlterarPerfilClinica}>
            <h2 className="titulo-formulario">Meus Dados Pessoais</h2>

            <div className="container-input">
              <div>
                <label htmlFor="nome">Nome Fantasia</label>
                <input
                  type="text"
                  id="nome"
                  value={name_fantasia}
                  disabled={modoEdicao === "editar" ? false : true}
                  onChange={(e) => setName_fantasia(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="sobrenome">Razão Social</label>
                <input
                  type="text"
                  id="sobrenome"
                  value={razao_social}
                  disabled={modoEdicao === "editar" ? false : true}
                  onChange={(e) => setRazao_social(e.target.value)}
                />
              </div>
            </div>

            <div className="container-input">
              <div>
                <label htmlFor="cpf">CPF/CNPJ</label>
                <input
                  type="text"
                  disabled={true}
                  id="cpf"
                  value={cnpj}
                  onChange={(e) => setCnpj(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="data_birth">Telefone</label>
                <input
                  disabled={modoEdicao === "editar" ? false : true}
                  id="data_birth"
                  value={telefoneMascara(phone)}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>

            <div className="container-input">
              <div>
                <div className="informacao-cnes">
                  <div id="div-ajuda">
                    <label htmlFor="cnes">CNES</label>
                    <Ajuda
                      mensagem={
                        "Cadastro Nacional de Estabelecimentos de Saúde"
                      }
                      width={"25rem"}
                      backColor={"var(--cor-branco)"}
                      fontColor={"var(--cor-azul)"}
                      margin={"2.0rem 0 0 -4.0rem"}
                      border={"0.5rem"}
                      size={"1.2rem"}
                    />
                  </div>
                </div>
                <input
                  type="text"
                  disabled={modoEdicao === "editar" ? false : true}
                  id="cnes"
                  value={cnes}
                  onChange={(e) => setCnes(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="data_birth">Email</label>
                <input
                  disabled={modoEdicao === "editar" ? false : true}
                  id="data_birth"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <h2 className="titulo-formulario">Endereço</h2>

            <div id="input-estado" className="container-input">
              <div>
                <label htmlFor="state">Estado</label>
                <select
                  id="state"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  disabled={modoEdicao === "editar" ? false : true}
                >
                  <option value="" style={{ textColor: "#6e757d" }}>
                    -- Estado --
                  </option>
                  {listaEstados.map((listaEstado) => (
                    <option key={listaEstado} value={listaEstado}>
                      {listaEstado}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="city">Cidade</label>
                <select
                  id="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  disabled={modoEdicao === "editar" ? false : true}
                >
                  <option value="" style={{ textColor: "#6e757d" }}>
                    -- Cidade --
                  </option>
                  {listaCidades.map((listaCidade) => (
                    <option key={listaCidade} value={listaCidade}>
                      {listaCidade}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="cep">CEP</label>
                <input
                  type="inteiro"
                  id="cep"
                  value={cepMask(cep)}
                  disabled={modoEdicao === "editar" ? false : true}
                  onChange={(e) => setCep(parseInt(e.target.value))}
                />
              </div>
            </div>
            <div id="input-numero" className="container-input">
              <div>
                <label htmlFor="street">Rua</label>
                <input
                  type="text"
                  id="street"
                  value={street}
                  disabled={modoEdicao === "editar" ? false : true}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="number">Número</label>
                <input
                  type="text"
                  id="number"
                  value={number}
                  disabled={modoEdicao === "editar" ? false : true}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </div>
            </div>

            <div className={`container-botao ${modoEdicao}`}>
              <button
                className="botao-grande .vermelho"
                onClick={() => setModoEdicao("")}
                type="reset"
                formNoValidate
              >
                Cancelar
              </button>
              <button className="botao-grande" type="submit">
                Salvar
              </button>
            </div>
          </form>
          <button
            className={`informacao-acao-deletar ${modoEdicao}`}
            //onClick={() => DeletePaciente()}
          >
            <FiXCircle className="icon" />
            Excluir conta
          </button>
        </div>
      </section>
    </div>
  );
}
