import React from "react";
import { Link } from "react-router-dom";

import { FiArrowRight } from "react-icons/fi";

import LogoMeet from "../../../Arquivos/logoMeet.svg";
import LogoWhatsApp from "../../../Arquivos/logoWhatsApp.svg";
import LogoEmail from "../../../Arquivos/logoEmail.svg";

import "./estilos.css";

export default function ConsultasDetalhes({ detalhes }) {
  const FotoPaciente = localStorage.getItem("photo");
  return (
    <div className="ConsultaDetalheCartao">
      <div className="ConsultaDetalheCartaoIdentificacao">
        <div className="Foto">
          <img src={FotoPaciente} alt="Foto do Médico selecionado" />
        </div>
        <div className="Identificacao">
          <h3>{detalhes.nome}</h3>
          <span>{detalhes.especializacao}</span>
        </div>
      </div>
      <div className="ConsultaDetalheCartaoDescricao">
        <h4>Recomendações</h4>
        <p>{detalhes.recomendacoes}</p>
        <h4>Exames Solicitados</h4>
        <p>{detalhes.exames}</p>
      </div>
      <div className="ConsultaDetalheCartaoContato">
        <div className="ConsultasContato">
          <img src={LogoWhatsApp} alt="Ícone do WhatsApp" />
          <img src={LogoEmail} alt="Ícone do Email" />
          <img src={LogoMeet} alt="Ícone do Meet" />
        </div>
        <Link to="/paciente" className="VerMaisDetalhes">
          Ver mais detalhes
          <FiArrowRight />
        </Link>
      </div>
    </div>
  );
}
