import React from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import './estilo.css'
// import ListaDados from './Components/ListaDados';
import ConfirmaDados from './ConfirmaDados';
import Logo from '../../../Arquivos/coracao.png';
import MarcarConsulta from './MarcarConsulta';
import DuvidasGravidez from './DuvidasGravidez';
import PlanejamentoFamiliar from './PlanejamentoFamiliar';



export default function ChatBotGeral(props) {
    
    const theme = {
        background: '#f5f8fb',
        /*fontFamily: 'Helvetica Neue',*/
        headerBgColor: '#1070a1',
        headerFontColor: '#fff',
        headerFontSize: '15px',
        botBubbleColor: '#1070a1',
        botFontColor: '#fff',
        userBubbleColor: '#fff',
        userFontColor: '#4a4a4a',
    };

    const estiloSelecao = {
        background: '#f5f8fb',
        width: '300px',
        padding: '10px',
        margin: '1px 16px',
        border: '2px solid #1070a1',
        color: '#1070a1',
    }

    const bubbleBot = {
        bDAjSC: {
            margin: '-8px 0 10px 40px',
        },
    }

    return (
        <ThemeProvider theme={theme}>
            <ChatBot
            headerTitle="Higia ChatBot"                                 //Titulo
            placeholder="Digite sua mensagem"
            bubbleOptionStyle= {estiloSelecao}
            bubbleStyle= {bubbleBot}
            className= {bubbleBot.bDAjSC}
            botAvatar= {Logo}   
            //width="400px"
            //hideBotAvatar={true}                                      //Faz aparecer o avatar do bot
            //speechSynthesis={{ enable: true, lang: 'pt' }}            //Faz com que as mensagens sejam lidas
            floating= {true}                                            //Faz com que o bot fique em forma de botão flutuante
            
            
            steps={[
                {
                    id: '1',
                    message: "Olá " + props.nome + " sou o chatbot do Higia",
                    trigger: '2',
                },
                {
                    id: '2',
                    message: 'De que se trata esse atendimento?',
                    trigger: '3',
                },
                {
                    id: '3',
                    options: [
                        {value: 1, label: 'Acompanhameto da Gravidez', trigger: 'acompanhamento'},
                        {value: 2, label: 'Marcar consulta', trigger: 'marcarConsulta'},
                        {value: 3, label: 'Duvidas sobre a gravidez', trigger: 'duvidasGravidez'},
                        {value: 4, label: 'Planejamento Familiar', trigger: 'planejamentoFamiliar'},
                    ],
                },
                ...MarcarConsulta(),
                ...DuvidasGravidez(),
                ...PlanejamentoFamiliar(),
                {
                    id: 'acompanhamento',
                    message: 'Muito bem, nessa seção de acompanhamento você pode tratar de alguns tópicos em relação a sua gravidez',
                    trigger: 'assuntoAcompanhamento',
                },
                {
                    id: 'assuntoAcompanhamento',
                    message: 'De qual assuto você quer falar nesse atendimento?',
                    trigger: 'assuntos',
                },
                {
                    id: 'assuntos',
                    options: [
                        {value: 1, label: 'Identificação de fatores de risco da gravidez', trigger: '4'},
                        {value: 2, label: 'Acompanhamento por Trimestre', trigger: 'acompanhamentoTrimestral'},
                        {value: 3, label: 'Relatar alguma queixa', trigger: 'queixa'},
                    ],
                },
                {
                    id: 'acompanhamentoTrimestral',
                    message: 'Parte de acompanhamento trimestral',
                    end: true,
                },
                {
                    id: 'queixa',
                    message: 'Parte de queixas',
                    end: true,
                },
                {
                    id: '4',
                    component: <ConfirmaDados nome={props.nome} foto={props.foto} />,  // Usa o novo componente
                    asMessage: true,
                    trigger: '5',
                  },
                  {
                    id: 'corrigirdados',
                    message: 'Você será direcionada a página de perfil onde poderá atualizar seus dados',
                    end: true,
                  },
                {
                    id: '7',
                    message: 'Muito bem. Agora irei fazer uma anamese com você',
                    trigger: '8',
                },
                {
                    id: '8',
                    message: 'Primeiramente, quero que me informe seus antecedentes familiares',
                    trigger: '9',
                },
                {
                    id: '9',
                    message: 'Antecedentes familiares são todos os históricos de doenças ou condições que tenham afetado os seus parentes',
                    trigger: '10',
                },
                {
                    id: '10',
                    message: 'Tente ser o mais específico possível. Peça ajuda a um familiar caso seja necessário',
                    trigger: '11',
                },
                {
                    id: '11',
                    user: true,
                    trigger: '12',
                },
                {
                    id: '12',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '13',
                },
                {
                    id: '13',
                    message: 'Agora falando dos seus antecedentes',
                    trigger: '14',
                },
                {
                    id: '14',
                    message: 'Você possui hipertensão, diabetes, asma, doença do coração ou alguma doença renal crônica?',
                    trigger: '15',
                },
                {
                    id: '15',
                    user: true,
                    trigger: '16',
                },
                {
                    id: '16',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '17',
                },
                {
                    id: '17',
                    message: 'Você possui algum desvio nutricional? (baixo peso, desnutrição, sobrepeso, obesidade)',
                    trigger: '18',
                },
                {
                    id: '18',
                    user: true,
                    trigger: '19',
                },
                {
                    id: '19',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '20',
                },
                {
                    id: '20',
                    message: 'Você tem ou já teve algum caso de epilepsia?',
                    trigger: '21',
                },
                {
                    id: '21',
                    user: true,
                    trigger: '22',
                },
                {
                    id: '22',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '23',
                },
                {
                    id: '23',
                    message: 'Você tem ou já teve rubéola ou hepatites?',
                    trigger: '24',
                },
                {
                    id: '24',
                    user: true,
                    trigger: '25',
                },
                {
                    id: '25',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '26',
                },
                {
                    id: '26',
                    message: 'Você é portadora do vírus HIV?',
                    trigger: '27',
                },
                {
                    id: '27',
                    options: [
                        {value: 1, label: 'Sim, sou portadora', trigger: 'portadora'},
                        {value: 2, label: 'Não, não sou portadora', trigger: '28'},
                    ],
                },
                {
                    id: 'portadora',
                    message: 'Você está usando algum antirretroviral?',
                    trigger: 'antirretroviral',
                },
                {
                    id: 'antirretroviral',
                    options: [
                        {value: 1, label: 'Sim, sou usando', trigger: 'tipoAntirretroviral'},
                        {value: 2, label: 'Não, não sou usnado', trigger: '28'},
                    ],
                },
                {
                    id: 'tipoAntirretroviral',
                    message: 'Qual antirretroviral você está utilizando?',
                    trigger: 'antirretroviralNome',
                },
                {
                    id: 'antirretroviralNome',
                    user: true,
                    trigger: 'antirretroviralExibir',
                },
                {
                    id: 'antirretroviralExibir',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '28',
                },
                {
                    id: '28',
                    message: 'Você teve alguma infecção urinária recentemente?',
                    trigger: '29',
                },
                {
                    id: '29',
                    user: true,
                    trigger: '30',
                },
                {
                    id: '30',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '31',
                },
                {
                    id: '31',
                    message: 'Você tem alguma doença neurológica ou psiquiátrica?',
                    trigger: '32',
                },
                {
                    id: '32',
                    user: true,
                    trigger: '33',
                },
                {
                    id: '33',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '34',
                },
                {
                    id: '34',
                    message: 'Você fez alguma cirurgia recentemente?',
                    trigger: '35',
                },
                {
                    id: '35',
                    options: [
                        {value: 1, label: 'Sim', trigger: 'cirurgia'},
                        {value: 2, label: 'Não', trigger: '36'},
                    ],
                },
                {
                    id: 'cirurgia',
                    message: 'De que tipo foi essa cirurgia?',
                    trigger: 'tipo',
                },
                {
                    id: 'tipo',
                    user: true,
                    trigger: 'quando',
                },
                {
                    id: 'quando',
                    message: 'Quando foi essa cirurgia?',
                    trigger: 'data',
                },
                {
                    id: 'data',
                    user: true,
                    trigger: 'data',
                },
                {
                    id: '36',
                    message: 'Você tem alguma alergia? (Inclusive alergia a medicamentos)',
                    trigger: '37',
                },
                {
                    id: '37',
                    user: true,
                    trigger: '38',
                },
                {
                    id: '38',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '39',
                },
                {
                    id: '39',
                    message: 'Você teve ou tem câncer?',
                    trigger: '40',
                },
                {
                    id: '40',
                    user: true,
                    trigger: '41',
                },
                {
                    id: '41',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '42',
                },
                {
                    id: '42',
                    message: 'Você faz uso de algum medicamento?',
                    trigger: '43',
                },
                {
                    id: '43',
                    options: [
                        {value: 1, label: 'Sim', trigger: 'medicamento'},
                        {value: 2, label: 'Não', trigger: '44'},
                    ],
                },
                {
                    id: 'medicamento',
                    message: 'Qual medicamento?',
                    trigger: 'tipoMedicamento',
                },
                {
                    id: 'tipoMedicamento',
                    user: true,
                    trigger: '44',
                },
                {
                    id: '44',
                    message: 'Você é usuária de drogas, álcool ou é tabagista(cigarro)?',
                    trigger: '45',
                },
                {
                    id: '45',
                    user: true,
                    trigger: '46',
                },
                {
                    id: '46',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '47'
                },
                {
                    id: '47',
                    message: 'Agora falando dos seus antecedentes ginecológicos',
                    trigger: '48',
                },
                {
                    id: '48',
                    message: 'Como eram seus ciclos menstruais antes da gravidez?',
                    trigger: '49',
                },
                {
                    id: '49',
                    message: 'Qual era a duração? E qual a reguralaridade e o intervalo? E com quantos anos você menstruou pela primeira vez?',
                    trigger: '50',
                },
                {
                    id: '50',
                    user: true,
                    trigger: '51',
                },
                {
                    id: '51',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '52',
                },
                {
                    id: '52',
                    message: 'Você fazia o uso de anticonsepcional antes da gravidez?',
                    trigger: '53',
                },
                {
                    id: '53',
                    options: [
                        {value: 1, label: 'Sim', trigger: '54'},
                        {value: 2, label: 'Não', trigger: '63'},
                    ],
                },
                {
                    id: '54',
                    message: 'Quais você utiliza?',
                    trigger: '55',
                },
                {
                    id: '55',
                    user: true,
                    trigger: '56',
                },
                {
                    id: '56',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '57',
                },
                {
                    id: '57',
                    message: 'Por quanto tempo você utilizou?',
                    trigger: '58',
                },
                {
                    id: '58',
                    user: true,
                    trigger: '59',
                },
                {
                    id: '59',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '60',
                },
                {
                    id: '60',
                    message: 'Qual foi o motivo do abandono do anticoncepcional?',
                    trigger: '61',
                },
                {
                    id: '61',
                    user: true,
                    trigger: '62',
                },
                {
                    id: '62',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '63',
                },
                {
                    id: '63',
                    message: 'Você faz ou fez algum tratamento para infertilidade e esterilidade',
                    trigger: '64',
                },
                {
                    id: '64',
                    user: true,
                    trigger: '65',
                },
                {
                    id: '65',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '66',
                },
                {
                    id: '66',
                    message: 'Você tem alguma doença sexualmente transmissível? (Considere também inflamação pélvica)',
                    trigger: '67',
                },
                {
                    id: '67',
                    options: [
                        {value: 1, label: 'Sim', trigger: '68'},
                        {value: 2, label: 'Não', trigger: '76'},
                    ],
                },
                {
                    id: '68',
                    message: 'Qual doença?',
                    trigger: '69',
                },
                {
                    id: '69',
                    user: true,
                    trigger: '70',
                },
                {
                    id: '70',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '71',
                },
                {
                    id: '71',
                    message: 'Você faz algum tratamento para essa doença?',
                    trigger: '72',
                },
                {
                    id: '72',
                    options: [
                        {value: 1, label: 'Sim', trigger: '73'},
                        {value: 2, label: 'Não', trigger: '76'},
                    ],
                },
                {
                    id: '73',
                    message: 'Qual tratamento?',
                    trigger: '74',
                },
                {
                    id: '74',
                    user: true,
                    trigger: '75',
                },
                {
                    id: '75',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '76',
                },
                {
                    id: '76',
                    message: 'Seu parceiro tem alguma doença sexualmente transmissível? (Considere também inflamação pélvica)',
                    trigger: '77',
                },
                {
                    id: '77',
                    options: [
                        {value: 1, label: 'Sim', trigger: '78'},
                        {value: 2, label: 'Não', trigger: '86'},
                    ],
                },
                {
                    id: '78',
                    message: 'Qual doença?',
                    trigger: '79',
                },
                {
                    id: '79',
                    user: true,
                    trigger: '80',
                },
                {
                    id: '80',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '81',
                },
                {
                    id: '81',
                    message: 'Ele(a) faz algum tratamento para essa doença?',
                    trigger: '82',
                },
                {
                    id: '82',
                    options: [
                        {value: 1, label: 'Sim', trigger: '83'},
                        {value: 2, label: 'Não', trigger: '86'},
                    ],
                },
                {
                    id: '83',
                    message: 'Qual tratamento?',
                    trigger: '84',
                },
                {
                    id: '84',
                    user: true,
                    trigger: '85',
                },
                {
                    id: '85',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '86',
                },
                {
                    id: '86',
                    message: 'Você realizou alguma cirurgia ginecológica?',
                    trigger: '87',
                },
                {
                    id: '87',
                    options: [
                        {value: 1, label: 'Sim', trigger: '87'},
                        {value: 2, label: 'Não', trigger: '95'},
                    ],
                },
                {
                    id: '87',
                    message: 'Qual cirurgia?',
                    trigger: '87',
                },
                {
                    id: '87',
                    user: true,
                    trigger: '88',
                },
                {
                    id: '88',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '89',
                },
                {
                    id: '89',
                    message: 'Com que idade?',
                    trigger: '90',
                },
                {
                    id: '90',
                    user: true,
                    trigger: '91',
                },
                {
                    id: '91',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '92',
                },
                {
                    id: '92',
                    message: 'Por qual motivo',
                    trigger: '93',
                },
                {
                    id: '93',
                    user: true,
                    trigger: '94',
                },
                {
                    id: '94',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '95',
                },
                {
                    id: '95',
                    message: 'Você possui alguma malformação uterina?',
                    trigger: '96',
                },
                {
                    id: '96',
                    user: true,
                    trigger: '97',
                },
                {
                    id: '97',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '98',
                },
                {
                    id: '98',
                    message: 'Você tem ou teve alguma doença nas mamas?',
                    trigger: '99',
                },
                {
                    id: '99',
                    options: [
                        {value: 1, label: 'Sim', trigger: '100'},
                        {value: 2, label: 'Não', trigger: '106'},
                    ],
                },
                {
                    id: '100',
                    message: 'Qual doença?',
                    trigger: '101',
                },
                {
                    id: '101',
                    user: true,
                    trigger: '102',
                },
                {
                    id: '102',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '103',
                },
                {
                    id: '103',
                    message: 'Qual foi o tratamento realizado?',
                    trigger: '104',
                },
                {
                    id: '104',
                    user: true,
                    trigger: '105',
                },
                {
                    id: '105',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '106',
                },
                {
                    id: '106',
                    message: 'Quando foi a sua última colpocitologia oncótica, também chamada de papanicolau ou “prevenção”?',
                    trigger: '107',
                },
                {
                    id: '107',
                    user: true,
                    trigger: '108',
                },
                {
                    id: '108',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '109',
                },
                {
                    id: '109',
                    message: 'Qual o resultado do exame?',
                    trigger: '110',
                },
                {
                    id: '110',
                    user: true,
                    trigger: '111',
                },
                {
                    id: '111',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '112',
                },
                {
                    id: '112',
                    message: 'Agora iremos tratar do assunto Sexualidade',
                    trigger: '113',
                },
                {
                    id: '113',
                    message: 'Com qual idade você teve sua primeira relação sexual?',
                    trigger: '114',
                },
                {
                    id: '114',
                    user: true,
                    trigger: '115',
                },
                {
                    id: '115',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '116',
                },
                {
                    id: '116',
                    message: 'Você sente dor ou desconforto durante o ato sexual?',
                    trigger: '117',
                },
                {
                    id: '117',
                    user: true,
                    trigger: '118',
                },
                {
                    id: '118',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '119',
                },
                {
                    id: '119',
                    message: 'Você tem sangramento durante as relações sexuais?',
                    trigger: '120',
                },
                {
                    id: '120',
                    user: true,
                    trigger: '121',
                },
                {
                    id: '121',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '122',
                },
                {
                    id: '122',
                    message: 'Você tem um parceiro fixo a mais de 2 anos?',
                    trigger: '123',
                },
                {
                    id: '123',
                    user: true,
                    trigger: '124',
                },
                {
                    id: '124',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '125',
                },
                {
                    id: '125',
                    message: 'Com quantos parceiros seu atual parceiro já se relacionou?',
                    trigger: '126',
                },
                {
                    id: '126',
                    user: true,
                    trigger: '127',
                },
                {
                    id: '127',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '128',
                },
                {
                    id: '128',
                    message: 'Você/Seu parceiro fazem uso de preservativos?',
                    trigger: '129',
                },
                {
                    id: '129',
                    options: [
                        {value: 1, label: 'Sim', trigger: '130'},
                        {value: 2, label: 'Não', trigger: '136'},
                    ],
                },
                {
                    id: '130',
                    message: 'Com qual frequência?',
                    trigger: '131',
                },
                {
                    id: '131',
                    user: true,
                    trigger: '132',
                },
                {
                    id: '132',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '133',
                },
                {
                    id: '133',
                    message: 'Quando usam, colocam e retiram o preservativo com o pênis ereto?',
                    trigger: '134',
                },
                {
                    id: '134',
                    user: true,
                    trigger: '135',
                },
                {
                    id: '135',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '136',
                },
                {
                    id: '136',
                    message: 'Agora iremos tratar dos seus Antecedente Obstetricos',
                    trigger: '137',
                },
                {
                    id: '137',
                    message: 'Quantas vezes você já engravidou?',
                    trigger: '138',
                },
                {
                    id: '138',
                    user: true,
                    trigger: '139',
                },
                {
                    id: '139',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '140',
                },
                {
                    id: '140',
                    message: 'Você já teve algum aborto?',
                    trigger: '141',
                },
                {
                    id: '141',
                    options: [
                        {value: 1, label: 'Sim', trigger: '142'},
                        {value: 2, label: 'Não', trigger: '145'},
                    ],
                },
                {
                    id: '142',
                    message: 'Qual foi o motivo?',
                    trigger: '143',
                },
                {
                    id: '143',
                    user: true,
                    trigger: '144',
                },
                {
                    id: '144',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '145',
                },
                {
                    id: '145',
                    message: 'Quantos partos você teve?',
                    trigger: '146',
                },
                {
                    id: '146',
                    options: [
                        {value: 1, label: 'Já tive 1 ou mais', trigger: '147'},
                        {value: 2, label: 'Esta é mimha primeira gestação', trigger: 'primeiroFilho'},
                    ],
                },
                {
                    id: '147',
                    message: 'Foi parto normal ou cesariana?',
                    trigger: '148',
                },
                {
                    id: '148',
                    user: true,
                    trigger: '149',
                },
                {
                    id: '149',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '150',
                },
                {
                    id: '150',
                    message: 'Quantos filhos, biológicos,  você tem',
                    trigger: '151',
                },
                {
                    id: '151',
                    user: true,
                    trigger: '152',
                },
                {
                    id: '152',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '153',
                },
                {
                    id: '153',
                    message: 'Quantos anos se passaram desde a sua primeira gestação?',
                    trigger: '154',
                },
                {
                    id: '154',
                    user: true,
                    trigger: '154',
                },
                {
                    id: '154',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '156',
                },
                {
                    id: '156',
                    message: 'Qual o intervalo médio das suas gestações?',
                    trigger: '157',
                },
                {
                    id: '157',
                    user: true,
                    trigger: '158',
                },
                {
                    id: '158',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '159',
                },
                {
                    id: '159',
                    message: 'Qual o seu tipo sanguíneo?',
                    trigger: '160',
                },
                {
                    id: '160',
                    user: true,
                    trigger: '161',
                },
                {
                    id: '161',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '162',
                },
                {
                    id: '162',
                    message: 'Qual o peso do/dos seu/seus filhos ao nascer?',
                    trigger: '163',
                },
                {
                    id: '163',
                    user: true,
                    trigger: '164',
                },
                {
                    id: '164',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '165',
                },
                {
                    id: '165',
                    message: 'Você teve algum filho prematuro?',
                    trigger: '166',
                },
                {
                    id: '166',
                    user: true,
                    trigger: '167',
                },
                {
                    id: '167',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '168',
                },
                {
                    id: '168',
                    message: 'Você amamentou seus filhos anteriores?',
                    trigger: '169',
                },
                {
                    id: '169',
                    options: [
                        {value: 1, label: 'Sim', trigger: '170'},
                        {value: 2, label: 'Não', trigger: '176'},
                    ],
                },
                {
                    id: '170',
                    message: 'Qual a duração?',
                    trigger: '171',
                },
                {
                    id: '171',
                    user: true,
                    trigger: '172',
                },
                {
                    id: '172',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '173',
                },
                {
                    id: '173',
                    message: 'Qual o motivo do desmame?',
                    trigger: '174',
                },
                {
                    id: '174',
                    user: true,
                    trigger: '175',
                },
                {
                    id: '175',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '176',
                },
                {
                    id: 'primeiroFilho',
                    message: 'Qual o seu tipo sanguíneo?',
                    trigger: 'pf',
                },
                {
                    id: 'pf',
                    user: true,
                    trigger: 'pf1',
                },
                {
                    id: 'pf1',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '176',
                },
                {
                    id: '176',
                    message: 'Agora iremos tratar da sua gestação atual',
                    trigger: '177',
                },
                {
                    id: '177',
                    message: 'Qual a data da sua última menstruação? (dia, mês e ano)',
                    trigger: '178',
                },
                {
                    id: '178',
                    user: true,
                    trigger: '179',
                },
                {
                    id: '179',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '181',
                },
                {
                    id: '181',
                    message: 'Tem certeza da data ou está em dúvida?',
                    trigger: '182',
                },
                {
                    id: '182',
                    user: true,
                    trigger: '183',
                },
                {
                    id: '183',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '184',
                },
                {
                    id: '184',
                    message: 'Qual o seu peso atual?',
                    trigger: '185',
                },
                {
                    id: '185',
                    user: true,
                    trigger: '186',
                },
                {
                    id: '186',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '187',
                },
                {
                    id: '187',
                    message: 'Qual a sua altura?',
                    trigger: '188',
                },
                {
                    id: '188',
                    user: true,
                    trigger: '189',
                },
                {
                    id: '189',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '191',
                },
                {
                    id: '191',
                    message: 'Você está sentindo algum sinal ou sintoma na gestação atual?',
                    trigger: '192',
                },
                {
                    id: '192',
                    user: true,
                    trigger: '193',
                },
                {
                    id: '193',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '194',
                },
                {
                    id: '194',
                    message: 'Quais são os seus hábitos alimentares?',
                    trigger: '195',
                },
                {
                    id: '195',
                    user: true,
                    trigger: '196',
                },
                {
                    id: '196',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '197',
                },
                {
                    id: '197',
                    message: 'Você está fazendo o uso de algum medicamento nessa gestação?',
                    trigger: '198',
                },
                {
                    id: '198',
                    user: true,
                    trigger: '199',
                },
                {
                    id: '199',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '200',
                },
                {
                    id: '200',
                    message: 'Qual a sua ocupação atual?',
                    trigger: '201',
                },
                {
                    id: '201',
                    user: true,
                    trigger: '202',
                },
                {
                    id: '202',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '203',
                },
                {
                    id: '203',
                    message: 'Sua ocupação é de esforço físico intenso?',
                    trigger: '204',
                },
                {
                    id: '204',
                    user: true,
                    trigger: '205',
                },
                {
                    id: '205',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '206',
                },
                {
                    id: '206',
                    message: 'Você fica exposta a agentes químicos e físicos nocivos?',
                    trigger: '207',
                },
                {
                    id: '207',
                    user: true,
                    trigger: '208',
                },
                {
                    id: '208',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '209',
                },
                {
                    id: '209',
                    message: 'Ele lhe causa grande estresse?',
                    trigger: '210',
                },
                {
                    id: '210',
                    user: true,
                    trigger: '211',
                },
                {
                    id: '211',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '212',
                },
                {
                    id: '209',
                    message: 'Você está contente com sua gravidez?',
                    trigger: '210',
                },
                {
                    id: '210',
                    user: true,
                    trigger: '211',
                },
                {
                    id: '211',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '212',
                },
                {
                    id: '212',
                    message: 'Seu parceiro(a) e família estão contentes por sua gravidez?',
                    trigger: '213',
                },
                {
                    id: '213',
                    user: true,
                    trigger: '214',
                },
                {
                    id: '214',
                    message: 'Seus antecedentes: "{previousValue}"',
                    trigger: '215',
                },
                {
                    id: '215',
                    message: "Muito bem " + props.nome + " coletei todos os dados que eu preciso para lhe fornecer ajuda na sua gestação",
                    end: true,
                },
    
            ]}
            />
        </ThemeProvider>
    );
}

//Indentificar gestantes com fraca rede de suporte emocional
//Cáçculo da idade gestacionasl e data privável do parto
