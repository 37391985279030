import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import api from "../../../Servicos/api";

import styles from "./FinanceModal.module.css";
import Logo from "../../../Arquivos/higia.svg";

function AdicionarPagamento({
    abrirModal,
    setAbrirModal,
    idPlano,
    nomePlano,
    provedorPlano,
    vencimentoPlano }) {
    const token = localStorage.getItem("token");
    const history = useHistory();

    const [planData, setPlanData] = useState([]);
    const [fullHistory, setFullHistory] = useState([]);

    const [identification_name, setIdentification_name] = useState("");
    const [provider, setProvider] = useState("");
    const [planDate, setPlanDate] = useState(0);
    const [planExpiration, setPlanExpiration] = useState(0);
    const [notes, setNotes] = useState(0);
    const [paymentInt, setPaymentInt] = useState(0);
    const [creationMonthInt, setCreationMonthInt] = useState(0);

    const months = [
        { name: "Jan" }, { name: "Fev" }, { name: "Mar" }, { name: "Abr" }, { name: "Mai" }, { name: "Jun" }, { name: "Jul" }, { name: "Ago" }, { name: "Set" }, { name: "Out" }, { name: "Nov" }, { name: "Dez" }
    ];

    useEffect(() => {
        setIdentification_name(nomePlano);
        setProvider(provedorPlano);
        setPlanDate(vencimentoPlano);
    }, [nomePlano, provedorPlano, vencimentoPlano])


    useEffect(() => {
        if (idPlano) {
            api
                .get(`/insurancePaymentHistory/${idPlano}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                })
                .then((response) => {
                    const data = response.data ? response.data : null;
                    if (data !== null) {
                        setPlanData(data);
                        setFullHistory(data.allInsurancePaymentHistory);
                        setCreationMonthInt(parseInt(data.healthInsurance.createdAt.slice(5, 7)))
                    }
                });
        }
    }, [idPlano, token]);

    let monthIndexCreation = 0;
    if (creationMonthInt > 0) {
        monthIndexCreation = creationMonthInt - 1;
    }

    let monthIndex = [];
    let pendingPayments = [0];

    let filteredFullHistory = fullHistory.filter((obj) => { return obj.payment_received === false; });

    filteredFullHistory.forEach((e) => {
        monthIndex.push(e.payday.slice(5, 7));
        pendingPayments.push(e.payment);
    });

    let reducedPendingPayments = [];
    reducedPendingPayments.push(pendingPayments.reduce((acc, cur) => { return acc + cur }));

    const dateNow = new Date();

    let month = parseInt(String(dateNow.getMonth()).padStart(2, '0'));
    let year = `${dateNow.getFullYear()}`;

    // Envio do pagamento

    async function sendPayment() {
        let health_insurance_id = idPlano;
        let payday = planExpiration;
        let payment = parseFloat(paymentInt).toFixed(2);
        let payment_received = true;

        const data = {
            health_insurance_id,
            payday,
            payment,
            payment_received,
            notes
        };

        try {
            await api.post("insurancePaymentHistory", data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            swal("Cadastrado com Sucesso", "", "success");
            setAbrirModal(false);
            history.push("/clinica/inicio");
            history.push("/clinica/planos");
        } catch (err) {
            swal(err.response.data.error, "Tente novamente", "error");
        }
    }

    if (abrirModal === true) {
        return (
            (planData.healthInsurance === undefined ?
                <div className={styles.SobreposicaoFinanceiro}>
                    <section className={styles.ModalFinanceiro}>
                        <header>
                            <div className={styles.planAndProvider}>
                                <h2>{identification_name}</h2>
                                <h3>{provider}</h3>
                            </div>
                            <img src={Logo} alt="Higia.com" className={styles.Logo} />
                        </header>
                        <main className={styles.form}>
                            <section className={styles.dates}>
                                <div className={styles.dateItem}>
                                    <p className={styles.dateTitle}>Criado em</p>
                                    <p>{planDate}</p>
                                </div>
                                <div className={styles.dateItem}>
                                    <p className={styles.dateTitle}>Data de vencimento</p>
                                    <p>{planDate}</p>
                                </div>
                            </section>
                            <section className={styles.statement}>
                                <div className={styles.statementContainer}>
                                    <h2>Valor</h2>
                                    <div className={styles.list}>
                                        <p>Não há pagamentos a exibir</p>
                                    </div>
                                </div>
                                <h3>Total<span>R$ 0,00</span></h3>
                            </section>
                            <form action="">
                                <div className={styles.linhaForm}>
                                    <div className={styles.inputLabel}>
                                        <label htmlFor="valorPago">Valor pago</label>
                                        <input
                                            className={styles.inputClass}
                                            type="text"
                                            name="valorPago"
                                        />
                                    </div>
                                    <div className={styles.inputLabelDois}>
                                        <label htmlFor="dataPagamento">Data de pagamento</label>
                                        <input
                                            className={styles.inputClass}
                                            type="text"
                                            name="dataPagamento"
                                        />
                                    </div>
                                </div>
                                <label htmlFor="notes" className={styles.label}>Anotações</label>
                                <textarea
                                    name="notes"
                                    id="notes"
                                    cols="30"
                                    rows="10"
                                    className={styles.inputClass}
                                >
                                </textarea>
                            </form>
                        </main>
                        <footer>
                            <button
                                onClick={() => {
                                    setAbrirModal(false);
                                }}
                            >
                                Cancelar
                            </button>
                            <button
                            >
                                Confirmar
                            </button>
                        </footer>
                    </section>
                </div >
                :
                <div className={styles.SobreposicaoFinanceiro}>
                    <section className={styles.ModalFinanceiro}>
                        <header>
                            <div className={styles.planAndProvider}>
                                <h2>{identification_name}</h2>
                                <h3>{provider}</h3>
                            </div>
                            <img src={Logo} alt="Mobile Health" className={styles.Logo} />
                        </header>
                        <main className={styles.form}>
                            <section className={styles.dates}>
                                <div className={styles.dateItem}>
                                    <p className={styles.dateTitle}>Criado em</p>
                                    {months[creationMonthInt].name === undefined ? (
                                        <div>0</div>
                                    ) : (
                                        <p>{`${planData.healthInsurance.createdAt.slice(8, 10)} ${months[monthIndexCreation].name} ${planData.healthInsurance.createdAt.slice(0, 4)}`}</p>
                                    )}
                                </div>
                                <div className={styles.dateItem}>
                                    <p className={styles.dateTitle}>Data de vencimento</p>
                                    <p>{`${planData.healthInsurance.payday} ${months[month].name} ${year}`}</p>
                                </div>
                            </section>
                            <section className={styles.statement}>
                                <div className={styles.statementContainer}>
                                    <h2>Valor</h2>
                                    {filteredFullHistory.length === 0 ? (
                                        <div className={styles.list}>
                                            <p>Não há pagamentos pendentes anteriores</p>
                                        </div>
                                    ) : (
                                        filteredFullHistory.map((info, index) => (
                                            <div className={styles.list} key={info.id}>
                                                <p>{`${identification_name} - ${info.payday.slice(8, 10)} ${months[parseInt(monthIndex[index]) - 1].name} ${info.payday.slice(0, 4)}`}</p>
                                                <p>{`R$ ${info.payment.toFixed(2)}`}</p>
                                            </div>
                                        ))
                                    )}
                                </div>
                                <h3>Total<span>{`R$ ${parseFloat(reducedPendingPayments).toFixed(2)}`}</span></h3>
                            </section>
                            <form action="">
                                <div className={styles.linhaForm}>
                                    <div className={styles.inputLabel}>
                                        <label htmlFor="valorPago">Valor pago</label>
                                        <input
                                            className={styles.inputClass}
                                            type="text"
                                            name="valorPago"
                                            value={paymentInt}
                                            onChange={e => setPaymentInt(e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.inputLabelDois}>
                                        <label htmlFor="dataPagamento">Data de pagamento</label>
                                        <input
                                            className={styles.inputClass}
                                            type="date"
                                            name="dataPagamento"
                                            value={planExpiration}
                                            onChange={e => setPlanExpiration(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <label htmlFor="notes" className={styles.label}>Anotações</label>
                                <textarea
                                    name="notes"
                                    id="notes"
                                    cols="30"
                                    rows="10"
                                    className={styles.inputClass}
                                    value={notes}
                                    onChange={e => setNotes(e.target.value)}
                                >
                                </textarea>
                            </form>
                        </main>
                        <footer>
                            <button
                                onClick={() => {
                                    setAbrirModal(false);
                                }}
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={sendPayment}
                            >
                                Confirmar
                            </button>
                        </footer>
                    </section>
                </div>
            )
        );
    }
    return null;
}

export default AdicionarPagamento;