import React, { useState } from "react";
import swal from "sweetalert";
import api from "../../../Servicos/api";
import { FiAlertTriangle } from "react-icons/fi";

import "./estilos.css";

function ModalExcluirUsuario({
  abrirModal,
  setAbrirModal,
  idUsuario,
  tipoUser,
}) {
  const token = localStorage.getItem("token");

  const [tipoUsuario, setTipoUsuario] = useState("");

  function setTipouser() {
    switch (tipoUser) {
      case "Atendente":
        setTipoUsuario("employee");
        break;
      case "Diretor Geral":
        setTipoUsuario("employee");
        break;
      case "Paciente":
        setTipoUsuario("patient");
        break;
      case "Médico":
        setTipoUsuario("doctor");
        break;
      default:
        setTipoUsuario("");
        break;
    }
  }

  async function excluirUsuario() {
    const user_id = localStorage.getItem("idExluir");
    try {
      await api.delete(`/${tipoUsuario}/${user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      swal("Excluido com Sucesso", "", "success");
      setAbrirModal(false);
      //window.location.reload();
    } catch (err) {
      swal(err.response.data.error, "Tente novamente", "error");
      //setAbrirModal(false);
    }
  }

  if (abrirModal === true) {
    return (
      <div className="SobreposicaoExcluirUsuario">
        <section className="ModalExcluirUsuario">
          <header>
            <h2>Deseja Realmente Excluir o Usuário?</h2>
          </header>
          <FiAlertTriangle
            style={{ marginTop: "30px" }}
            size="100"
            color="#ec1b21"
          />
          <main>
            <button
              className="botao-grande"
              style={{ backgroundColor: "#ec1b21" }}
              onFocus={setTipouser}
              onClick={() => excluirUsuario()}
            >
              Sim
            </button>
            <button
              className="botao-grande"
              style={{ backgroundColor: "var(--cor-cinza)" }}
              onClick={() => setAbrirModal(false)}
            >
              Não
            </button>
          </main>
        </section>
      </div>
    );
  }
  return null;
}

export default ModalExcluirUsuario;
