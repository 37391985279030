/*
Data de criação: 02/09/2020;
Autor: Caio Rafael;
Descrição: Página de Login das clinicas, responsável por possibilitar ao admin da
clinica logar na aplicação;
*/

import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "../../Servicos/api";
import swal from "sweetalert";
import logoImg from "../../Arquivos/higia.svg";
import SelecionarPerfil from "../SelecionarPerfil/indice";
import AnimacaoCarregando from "../../Componentes/AnimacaoCarregando/indice";

import "./estilos.css";
import { ChallengesContext } from "../../Servicos/Context/ChallengesContext";

export default function LoginGeral() {
  const [modalVinculo, setModalVinculo] = useState(false);

  const [carregando, setCarregando] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [dados, setDados] = useState({});

  const { setToken, setVerificLogin } = useContext(ChallengesContext);

  async function Entrar(e) {
    e.preventDefault();

    try {
      setCarregando(true);
      const response = await api.post("signin", { email, password });
      const informacoes = response.data;

      setToken(response.data.token);
      setVerificLogin(true);
      localStorage.setItem("verifica", true);

      if (informacoes.hasOwnProperty("users")) {
        setDados(response.data);
        setModalVinculo(true);
      } else {
        switch (informacoes.privilege) {
          case "MHME":
            localStorage.setItem("username", response.data.user.username ?? "");
            localStorage.setItem(
              "name_first",
              response.data.user.person.name_first ?? ""
            );
            localStorage.setItem(
              "person_id",
              response.data.user.person_id ?? ""
            );
            localStorage.setItem(
              "name_last",
              response.data.user.person.name_last ?? ""
            );
            localStorage.setItem(
              "phone",
              response.data.user.person.phone ?? ""
            );
            localStorage.setItem("email", response.data.user.email ?? "");
            localStorage.setItem("token", response.data.token ?? "");
            localStorage.setItem(
              "especializacao",
              response.data.user.person.doctor.area_specialization ?? ""
            );
            localStorage.setItem("cpf", response.data.user.person.cpf ?? "");
            localStorage.setItem(
              "crm",
              response.data.user.person.doctor.crm ?? ""
            );
            localStorage.setItem(
              "specialization_id",
              response.data.user.person.doctor.specialization_id ?? ""
            );
            localStorage.setItem(
              "uf",
              response.data.user.person.doctor.uf ?? ""
            );
            localStorage.setItem(
              "rqe",
              response.data.user.person.doctor.rqe ?? ""
            );
            localStorage.setItem("photo", response.data.user.photo ?? "");
            localStorage.setItem("id", response.data.user.id ?? "");
            localStorage.setItem(
              "idMedico",
              response.data.user.person.doctor.id ?? ""
            );
            localStorage.setItem(
              "state",
              response.data.user.person.address.state ?? ""
            );
            localStorage.setItem(
              "city",
              response.data.user.person.address.city ?? ""
            );
            localStorage.setItem(
              "cep",
              response.data.user.person.address.cep ?? ""
            );
            localStorage.setItem(
              "number",
              response.data.user.person.address.number ?? ""
            );
            localStorage.setItem(
              "street",
              response.data.user.person.address.street ?? ""
            );
            localStorage.setItem(
              "housign_type",
              response.data.user.person.address.housign_type ?? ""
            );
            localStorage.setItem(
              "questions",
              response.data.user.person.doctor.questions ?? ""
            );

            history.push("/medico");
            break;

          case "MHMA":
            localStorage.setItem("id", response.data.user.id);
            localStorage.setItem("email", response.data.email ?? "");
            localStorage.setItem("cnes", response.data.user.manager.cnes ?? "");
            localStorage.setItem("cnpj", response.data.user.manager.cnpj ?? "");
            localStorage.setItem(
              "fantasy_name",
              response.data.user.manager.fantasy_name ?? ""
            );
            localStorage.setItem(
              "person_id",
              response.data.user.person_id ?? ""
            );
            localStorage.setItem(
              "social_reason",
              response.data.user.manager.social_reason ?? ""
            );
            localStorage.setItem(
              "phone",
              response.data.user.manager.phone ?? ""
            );
            localStorage.setItem(
              "id_clinica",
              response.data.user.manager.id ?? ""
            );

            localStorage.setItem(
              "state",
              response.data.user.manager.address.state ?? ""
            );
            localStorage.setItem(
              "city",
              response.data.user.manager.address.city ?? ""
            );
            localStorage.setItem(
              "cep",
              response.data.user.manager.address.cep ?? ""
            );
            localStorage.setItem(
              "number",
              response.data.user.manager.address.number ?? ""
            );
            localStorage.setItem(
              "street",
              response.data.user.manager.address.street ?? ""
            );
            localStorage.setItem(
              "housign_type",
              response.data.user.manager.address.housign_type ?? ""
            );
            localStorage.setItem("token", response.data.token ?? "");

            history.push("/clinica");
            break;
          case "MHPA":
            localStorage.setItem("username", response.data.user.username);
            localStorage.setItem(
              "name_first",
              response.data.user.person.name_first
            );
            localStorage.setItem("person_id", response.data.user.person_id);
            localStorage.setItem(
              "name_last",
              response.data.user.person.name_last
            );
            localStorage.setItem("race", response.data.user.person.race);
            localStorage.setItem(
              "data_birth",
              response.data.user.person.data_birth
            );
            localStorage.setItem("phone", response.data.user.person.phone);
            localStorage.setItem("email", response.data.user.email);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem(
              "escolaridade",
              response.data.user.escolaridade
            );
            localStorage.setItem("cpf", response.data.user.person.cpf);
            localStorage.setItem("photo", response.data.user.photo);
            localStorage.setItem("id", response.data.user.id);
            localStorage.setItem(
              "IdPaciente",
              response.data.user.person.patient.id
            );
            localStorage.setItem("idade", response.data.user.person.age);
            localStorage.setItem("gender", response.data.user.person.gender);
            localStorage.setItem(
              "state",
              response.data.user.person.address.state
            );
            localStorage.setItem(
              "city",
              response.data.user.person.address.city
            );
            localStorage.setItem("cep", response.data.user.person.address.cep);
            localStorage.setItem(
              "number",
              response.data.user.person.address.number
            );
            localStorage.setItem(
              "street",
              response.data.user.person.address.street
            );
            localStorage.setItem(
              "housign_type",
              response.data.user.person.address.housign_type
            );
            localStorage.setItem(
              "nationality",
              response.data.user.person.patient.nationality
            );
            localStorage.setItem(
              "fathers_name",
              response.data.user.person.patient.fathers_name
            );
            localStorage.setItem(
              "mothers_name",
              response.data.user.person.patient.mothers_name
            );
            localStorage.setItem(
              "responsible_name",
              response.data.user.person.patient.responsible_name
            );
            localStorage.setItem(
              "religion",
              response.data.user.person.patient.religion
            );
            localStorage.setItem(
              "marital_status",
              response.data.user.person.patient.marital_status
            );
            localStorage.setItem(
              "profession",
              response.data.user.person.patient.profession
            );
            localStorage.setItem(
              "occupation",
              response.data.user.person.patient.occupation
            );
            localStorage.setItem("rg", response.data.user.person.patient.rg);
            localStorage.setItem(
              "birth_town",
              response.data.user.person.patient.birth_town
            );
            history.push("/paciente");
            break;
          case "MHDG":
            localStorage.setItem("id", response.data.user.id);
            localStorage.setItem("email", response.data.email);
            localStorage.setItem("privilege", response.data.user.privilege);
            localStorage.setItem("username", response.data.user.username);
            localStorage.setItem("age", response.data.user.person.age);
            localStorage.setItem(
              "name_first",
              response.data.user.person.name_first
            );
            localStorage.setItem(
              "name_last",
              response.data.user.person.name_last
            );
            localStorage.setItem("cpf", response.data.user.person.cpf);
            localStorage.setItem("phone", response.data.user.person.phone);
            localStorage.setItem("race", response.data.user.person.race);
            localStorage.setItem("gender", response.data.user.person.gender);
            localStorage.setItem(
              "data_birth",
              response.data.user.person.data_birth
            );
            localStorage.setItem(
              "occupation",
              response.data.user.person.employee[0].occupation
            );
            localStorage.setItem("person_id", response.data.user.person_id);
            localStorage.setItem(
              "workload",
              response.data.user.person.employee[0].workload
            );

            localStorage.setItem(
              "state",
              response.data.user.person.address.state
            );
            localStorage.setItem(
              "city",
              response.data.user.person.address.city
            );
            localStorage.setItem("cep", response.data.user.person.address.cep);
            localStorage.setItem(
              "number",
              response.data.user.person.address.number
            );
            localStorage.setItem(
              "street",
              response.data.user.person.address.street
            );
            localStorage.setItem(
              "housign_type",
              response.data.user.person.address.housign_type
            );
            localStorage.setItem("token", response.data.token);
            history.push("/diretor");
            break;
          case "MHAT":
            //console.log(response.data);
            localStorage.setItem("id", response.data.user.id);
            localStorage.setItem("email", response.data.email);
            localStorage.setItem("privilege", response.data.privilege);
            localStorage.setItem("username", response.data.user.username);
            localStorage.setItem("age", response.data.user.person.age);
            localStorage.setItem(
              "name_first",
              response.data.user.person.name_first
            );
            localStorage.setItem(
              "name_last",
              response.data.user.person.name_last
            );
            localStorage.setItem("cpf", response.data.user.person.cpf);
            localStorage.setItem("phone", response.data.user.person.phone);
            localStorage.setItem("race", response.data.user.person.race);
            localStorage.setItem("gender", response.data.user.person.gender);
            localStorage.setItem(
              "data_birth",
              response.data.user.person.data_birth
            );
            localStorage.setItem(
              "occupation",
              response.data.user.person.employee[0].occupation
            );
            localStorage.setItem("person_id", response.data.user.person_id);
            localStorage.setItem(
              "workload",
              response.data.user.person.employee[0].workload
            );
            localStorage.setItem(
              "state",
              response.data.user.person.address.state
            );
            localStorage.setItem(
              "city",
              response.data.user.person.address.city
            );
            localStorage.setItem("cep", response.data.user.person.address.cep);
            localStorage.setItem(
              "number",
              response.data.user.person.address.number
            );
            localStorage.setItem(
              "street",
              response.data.user.person.address.street
            );
            localStorage.setItem(
              "housign_type",
              response.data.user.person.address.housign_type
            );
            localStorage.setItem("token", response.data.token);
            history.push("/atendente");
            break;
          default:
            swal("Privilégio inválido.", "error");
        }
      }
      setCarregando(false);
    } catch (err) {
      setCarregando(false);
      swal(err.response.data.error, "Digite novamente", "error");
    }
  }

  return (
    <div id="login-container">
      {modalVinculo ? <SelecionarPerfil dados={dados} /> : ""}
      <section className="conteudo">
        <img src={logoImg} className="container-logo" alt="Logo MobileHealth" />

        <h2>
          Olá! Faça login para <br />
          continuar.
        </h2>

        <form className="container-formulario" onSubmit={Entrar}>
          <Input
            type="text"
            id="email"
            label="E-mail"
            name="email"
            value={email}
            setValue={setEmail}
          />
          <Input
            type="password"
            id="password"
            label="Senha"
            name="password"
            value={password}
            setValue={setPassword}
          />
          <button className="botao-grande" type="submit">
            {carregando ? <AnimacaoCarregando color="#fff" /> : "Entrar"}
          </button>
          <Link to="/redefinirsenha" className="container-link">
            Esqueceu sua senha?
          </Link>
        </form>
      </section>
    </div>
  );
}

function Input({ id, label, value, setValue, type }) {
  return (
    <div id="container-input">
      <input
        type={type}
        id={id}
        name={id}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        required
      />
      <label htmlFor={id} className="container-label">
        {" "}
        <span>{label}</span>
      </label>
    </div>
  );
}
