import React from "react";
import { FiUsers } from "react-icons/fi";
import "./estilos.css";

function InformacoesPacientes({ totalPacientes }) {
  return (
    <div id="InformacoesPacientes">
      <div className="Total">
        <div id="FundoIcone">
          <FiUsers id="Icone" />
        </div>
        <div className="Descricao">
          <span>Total de Pacientes</span>
          <h2>
            {totalPacientes >= 10 ? `${totalPacientes}` : `0${totalPacientes}`}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default InformacoesPacientes;
