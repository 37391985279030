/*
Data de criação: 24/05/2021;
Autor: Herson Douglas;
Descrição: Listagem de planos;
*/

import React, { useState, useEffect } from "react";
import {
  FiChevronsRight,
  FiEdit,
  FiTrash2,
  FiDollarSign,
} from "react-icons/fi";

import foto from "../../../Arquivos/avatarPadrao.svg";
import Menu from "../../../Componentes/GlobalMenu/indice";
import CriarPlano from "../../../Componentes/Clinica/CriarPlano/indice";
import AtualizarPlano from "../../../Componentes/Clinica/AtualizarPlano/indice";
import ModalExcluirPlano from "../../../Componentes/Clinica/ExcluirPlano/indice";
import AdicionarPagamento from "../../../Componentes/Clinica/AdicionarPagamento/indice";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";

import "./estilos.css";
import api from "../../../Servicos/api";

function PlanosClinica() {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id_clinica");
  const name_first = localStorage.getItem("fantasy_name");

  const [listaPlanos, setListaPlanos] = useState([]);
  const [listaPlanosTabela, setListaPlanosTabela] = useState([]);

  //abrir modal
  const [abrirModal, setAbrirModal] = useState(false);
  const [abrirModalAtualizar, setAbrirMOdalAtualizar] = useState(false);
  const [abrirModalExcluir, setAbrirModalExcluir] = useState(false);
  const [abrirModalFinanceiro, setAbrirModalFinanceiro] = useState(false);

  useEffect(() => {
    api
      .get(`/healthInsurance/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data ? response.data : null;
        if (data !== null) {
          setListaPlanos(data);
        }
      });
  }, [token, id]);

  //Buscar planos pelo Nome
  const [pesquisaNome, setPesquisaNome] = useState("");
  useEffect(() => {
    const resultados = listaPlanos.filter((plano) =>
      plano.identification_name
        .toLowerCase()
        .includes(pesquisaNome.toLowerCase())
    );
    setListaPlanosTabela(resultados);
  }, [pesquisaNome, listaPlanos]);

  // Buscar planos pelo convenio
  const [pesquisaProvider, setPesquisaProvider] = useState("");
  useEffect(() => {
    const resultados = listaPlanos.filter((plano) =>
      plano.provider.toLowerCase().includes(pesquisaProvider.toLowerCase())
    );
    setListaPlanosTabela(resultados);
  }, [pesquisaProvider, listaPlanos]);

  const [idPlano, setIdPlano] = useState();
  const [nomePlano, setNomePlano] = useState("");
  const [provedorPlano, setProvedorPlano] = useState("");
  const [precoPlano, setPrecoPlano] = useState();
  const [descricaoPlano, setDescricaoPlano] = useState("");
  const [vencimentoPlano, setVencimentoPlano] = useState();

  const titulos = [
    { nome: "Nome" },
    { nome: "Convênio" },
    { nome: "Preço" },
    { nome: "Descrição" },
    { nome: "Opções" },
  ];

  return (
    <div className="ContainerClinicaPlanos">
      <CriarPlano
        abrirModal={abrirModal}
        setAbrirModal={setAbrirModal}
        idPlano={idPlano}
      />
      <AtualizarPlano
        abrirModal={abrirModalAtualizar}
        setAbrirModal={setAbrirMOdalAtualizar}
        idPlano={idPlano}
        nomePlano={nomePlano}
        provedorPlano={provedorPlano}
        precoPlano={precoPlano}
        descricaoPlano={descricaoPlano}
        vencimentoPlano={vencimentoPlano}
      />
      <ModalExcluirPlano
        abrirModal={abrirModalExcluir}
        setAbrirModal={setAbrirModalExcluir}
        idPlano={idPlano}
      />
      <AdicionarPagamento
        abrirModal={abrirModalFinanceiro}
        setAbrirModal={setAbrirModalFinanceiro}
        idPlano={idPlano}
        nomePlano={nomePlano}
        provedorPlano={provedorPlano}
        vencimentoPlano={vencimentoPlano}
      />
      <Menu />
      <div className="container-planos">
        <div className="header-usuario">
          <h1>Seus Planos, {name_first}.</h1>
          <div className="Perfil">
            <div className="FotoPerfil">
              <img src={foto} alt="Foto de  Perfil" />
            </div>
          </div>
        </div>
        <nav>
          <div className="navegacaoUsuarios">
            <h2>{"Planos"}</h2>
            <h5>
              <FiChevronsRight id="Icone" /> {"Busca & Visão geral "}
            </h5>
            <Ajuda
              mensagem={
                "Nesta tela é possivel gerenciar os planos médicos da sua clínica."
              }
              width={"35rem"}
              backColor={"var(--cor-branco)"}
              fontColor={"var(--cor-azul)"}
              margin={"0 0 0 1.5rem"}
              border={"0.5rem"}
              size={"2.5rem"}
            />
          </div>
        </nav>

        <section>
          <div className="Pesquisa">
            <div className="pesquisaNome">
              <input
                value={pesquisaNome}
                type="text"
                className="inputPesquisa"
                placeholder="Buscar por nome do plano"
                onChange={(e) => setPesquisaNome(e.target.value)}
              />
              <label className="labelPesquisa">Nome do Plano</label>
            </div>
            <div className="pesquisaCpf">
              <input
                value={pesquisaProvider}
                type="text"
                className="inputPesquisa"
                placeholder="Buscar fornecedor do plano"
                onChange={(e) => setPesquisaProvider(e.target.value)}
              />
              <label className="labelPesquisa">Convênio</label>
            </div>
          </div>
          <button
            onClick={(info) => {
              setAbrirModal(true);
              setIdPlano(info.user_id);
            }}
            className="botaoSalvar"
          >
            Cadastrar novo plano
          </button>
          <div className="navTabela">
            <div className="tabelaUsuarios">
              <table>
                <thead>
                  <tr>
                    {titulos.map((titulo) => (
                      <th key={titulo.nome}>{titulo.nome}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listaPlanosTabela.length === 0 ? (
                    <tr>
                      <td>Não há planos ativos.</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  ) : (
                    listaPlanosTabela.map((info) => (
                      <tr key={info.id}>
                        <td>{info.identification_name}</td>
                        <td>{info.provider}</td>
                        <td>{`R$ ${info.price == null ? '0' : info.price.toFixed(2)}`}</td>
                        <td>{info.description}</td>
                        <td className="opcaoEditExcluir">
                          <button
                            onClick={() => {
                              setAbrirMOdalAtualizar(true);
                              setIdPlano(info.id);
                              setNomePlano(info.identification_name);
                              setProvedorPlano(info.provider);
                              setPrecoPlano(info.price);
                              setDescricaoPlano(info.description);
                              setVencimentoPlano(info.payday);
                            }}
                            className="alterar-status"
                          >
                            <FiEdit className="iconPlano" />
                          </button>
                          
                          <button
                            onClick={() => {
                              setAbrirModalFinanceiro(true);
                              setIdPlano(info.id);
                              setNomePlano(info.identification_name);
                              setProvedorPlano(info.provider);
                              setVencimentoPlano(info.payday);
                            }}
                            className="alterar-status"
                          >
                            <FiDollarSign className="iconPlano"/>
                          </button>
                          <button
                            onClick={() => {
                              setAbrirModalExcluir(true);
                              setIdPlano(info.id);
                            }}
                            className="alterar-status"
                          >
                            <FiTrash2 className="iconPlano" size="20" />
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default PlanosClinica;
