import React from "react";
import { Editor } from "@tinymce/tinymce-react";

import "./estilos.css";

function EditorTexto({ titulo, valor, setValor }) {
  return (
    <div className="ContainerEditor">
      <h4 id="TituloEditor">{titulo}</h4>
      <Editor
        apiKey="2mcpr9q8anv08hubrb3afgc3eb810ylcng3cbi9c71922ojm"
        value={valor}
        init={{
          height: 250,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          language: 'pt',
          entities : '160,nbsp,173,shy,8194,ensp,8195,emsp,8201,thinsp,8204,zwnj,8205,zwj,8206,lrm,8207,rlm',
          toolbar:
            "undo redo | bold italic |" +
            "alignleft aligncenter alignright alignjustify |" +
            "bullist numlist | ",
        }}
        onEditorChange={(content, editor) => {
          setValor(content);
        }}
      />
    </div>
  );
}

export default EditorTexto;
