import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { BiMessageRoundedAdd, BiMessageRoundedMinus } from "react-icons/bi";
import api from "../../../Servicos/api";

import "./estilos.css";

export default function Form() {
  const token = localStorage.getItem("token");
  const doctor_id = parseInt(localStorage.getItem("idMedico"));

  const [listPacientes, setListPacientes] = useState([]);
  const [patient_id, setPaciente] = useState("");
  //Informações Gerais
  const [date, setDate] = useState("");
  const questionsPadrao = localStorage.getItem("questions");
  var quest = questionsPadrao.split(",");
  const [questions, setQuestions] = useState(quest);

  function addInputPergunta(question) {
    const itensCopy = Array.from(questions);
    itensCopy.push(question);
    setQuestions(itensCopy);
  }

  function updateTask({ target }, index) {
    const itensCopy = Array.from(questions);
    itensCopy.splice(index, 1, target.value);
    setQuestions(itensCopy);
  }

  function deleteTask(index) {
    const itensCopy = Array.from(questions);
    itensCopy.splice(index, 1);
    setQuestions(itensCopy);
  }

  async function RegistrarAcompanhamento() {
    const data = {
      doctor_id,
      patient_id,
      date,
      questions,
    };
    try {
      await api.post("createMedicalSupervision", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      swal("Cadastrado com Sucesso", "", "success");
    } catch (err) {
      swal(err.response.data.error, "Digite novamente", "error");
    }
  }

  useEffect(() => {
    api
      .get(`doctorsPatientList/${doctor_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setListPacientes(response.data);
      });
  }, [token, doctor_id]);

  return (
    <div id="form-acompanhamento">
      <select
        value={patient_id}
        onChange={(e) => setPaciente(parseInt(e.target.value))}
        className="input-nome"
        required
      >
        <option value="">-- Pacientes --</option>
        {listPacientes.map((info) => (
          <option key={info.id} value={info.id}>
            {info.person.name_first} {info.person.name_last}
          </option>
        ))}
      </select>

      <Input
        className="input-nome"
        value={date}
        type="date"
        setValue={setDate}
        maxlength={14}
        id="data"
        label="Data de hoje"
        name="Data"
      />
      {questions.map(({ id, value }, index) => (
        <div className={"conteudo"}>
          <div className="meu-box">
            <input
              key={id}
              type="text"
              placeholder="Digite uma pergunta."
              value={questions[index]}
              onChange={(event) => {
                updateTask(event, index);
              }}
              name="Perguntas"
              className="input-nome"
              required
            />
            <label htmlFor={index + 1} className="label-nome">
              {" "}
              <span>{`Pergunta ${index + 1}`}</span>
            </label>
          </div>
          <Link
            id="DeletePergunta"
            className="botao-grande"
            to="#"
            onClick={() => deleteTask(index)}
          >
            <BiMessageRoundedMinus size="20" />{" "}
          </Link>
        </div>
      ))}
      <Link
        id="AddPergunta"
        className="botao-grande"
        to="#"
        onClick={() => addInputPergunta("")}
      >
        <BiMessageRoundedAdd size="30" style={{ marginRight: "5px" }} />{" "}
        Pergunta
      </Link>

      <button
        type="button"
        className="botao-grande"
        onClick={() => { RegistrarAcompanhamento(); }}
      >
        Salvar
      </button>
    </div>
  );
}

function Input({
  id,
  label,
  value,
  setValue,
  type,
  placeholder,
  estado,
  maxlength,
}) {
  // function dateConverter(st) {
  //   var months = [
  //     "01",
  //     "02",
  //     "03",
  //     "04",
  //     "05",
  //     "06",
  //     "07",
  //     "08",
  //     "09",
  //     "10",
  //     "11",
  //     "12",
  //   ];

  //   var dt = new Date(st);
  //   var year = dt.getFullYear();
  //   var month = months[dt.getMonth()];
  //   var date = dt.getDate();
  //   return date + "/" + month + "/" + year;
  // }

  function ModificaValor(e) {
    setValue(e.target.value);
  }

  return (
    <div className={"conteudo"} style={{ marginBottom: "20px" }}>
      <div className="meu-box">
        <input
          className="input-nome"
          type={type}
          id={id}
          name={id}
          value={value}
          placeholder={placeholder}
          onChange={ModificaValor}
          disabled={estado}
          maxLength={maxlength}
          required
        />
        <label htmlFor={id} className="label-nome">
          {" "}
          <span>{label}</span>
        </label>
      </div>
    </div>
  );
}
