import React, { useEffect, useState } from "react";
import { FiChevronsRight } from "react-icons/fi";
import "react-calendar/dist/Calendar.css";

//Componentes
import Menu from "../../../Componentes/GlobalMenu/indice";
import Calendario from "../../../Componentes/Atendente/Calendario/indice";
import AnimacaoCarregando from "../../../Componentes/AnimacaoCarregando/indice";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";
import api from "../../../Servicos/api";

import imagem from "../../../Arquivos/img-error.svg";

import "./estilos.css";

function AgendaAtendente() {
  const name_first = localStorage.getItem("name_first");
  const token = localStorage.getItem("token");

  const [doctorsList, setDoctorsList] = useState([]);
  const [appointmentsList, setAppointmentsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [doctor, setDoctor] = useState("");
  const [doctorId, setDoctorId] = useState(0);
  const [doctorSpecialization, setDoctorSpecialization] = useState('');
  const foto = localStorage.getItem("photo");

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`searchDoctors/?pagination=false`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDoctorsList(response.data);
        setIsLoading(false);
      });
  }, [token]);

  useEffect(() => {
    api
      .get(`listConsultations/?pagination=false`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAppointmentsList(response.data);
      });
  }, [token]);

  return (
    <div className="PainelAgendaAtendente">
      <Menu />
      {isLoading ? (
        <div className="ConteudoAgenda teste">
          <AnimacaoCarregando color="#1070a1" />
        </div>
      ) : doctorsList.length === 0 ? (
        <div className="ConteudoAgenda container-err">
          <div className="error-container">
            <h1>Ops! Nenhum Médico Cadastrado</h1>
            <p>
              Nenhum cadastrado de Médico Localizado.
              <br />
              Verifique cadastro de Médico ou realize o cadastro do médico em{" "}
              <strong>
                Menu{" "}
                <FiChevronsRight
                  style={{ marginTop: 1, marginBottom: -4 }}
                  size="18"
                />{" "}
                Médicos{" "}
                <FiChevronsRight
                  style={{ marginTop: 1, marginBottom: -4 }}
                  size="18"
                />{" "}
                Cadastrar Médico.
              </strong>
            </p>
          </div>
          <img src={imagem} alt="imagem em construção" />
        </div>
      ) : (
        <div className="ConteudoAgenda">
          <header>
            <h1 id="tituloClinica">Bem-vindo, {name_first}.</h1>
            <div id="Perfil">
              <div id="FotoPerfil">
                <img src={foto} alt="Foto de Perfil" />
              </div>
            </div>
          </header>

          <main>
            <div id="TituloAgenda">
              <span>Agenda</span>
              <FiChevronsRight id="Icone" />
              <span>Agenda Médica</span>
              <Ajuda
                mensagem={
                  "Nesta tela é possível gerenciar a agenda diaria, semanal e mensal, possibilitando agendar, atualizar e vizualizar uma consulta."
                }
                width={"45rem"}
                backColor={"var(--cor-branco)"}
                fontColor={"var(--cor-azul)"}
                margin={"0 0 0 1.5rem"}
                border={"0.5rem"}
                size={"2.5rem"}
              />
            </div>

            <div>
  <select
    id="medicoId4"
    required
    value={doctor}
    className="selectConsulta"
    onChange={(e) => {
      const selectedIndex = e.target.selectedIndex;
      const selectedOption = e.target.options[selectedIndex];

      setDoctor(selectedOption.text); // Nome do médico
      setDoctorId(selectedOption.value); // ID do médico
      setDoctorSpecialization(''); // Atualize conforme necessário
    }}
  >
    <option value="" disabled>
      --Selecione um Médico--
    </option>
    {doctorsList.map((medico) => (
      <option
        key={medico.person.doctor.id}
        value={medico.person.doctor.id}
      >
        {medico.person.cpf + ' - ' + medico.person.name_first}
      </option>
    ))}
  </select>

  <label className="modalLabel">Nome do Médico</label>
</div>

         
            <section className="CalendarContainer">
              <Calendario
                listaConsulta={appointmentsList}
                setListaConsulta={setAppointmentsList}
                medico={doctor}
                medicoId={doctorId}
                medicoSpecialization={doctorSpecialization} 
              />
            </section>
          </main>
        </div >
      )
      }
    </div >
  );
}

export default AgendaAtendente;