import React from 'react';
import "./style.css"

export default function ListaDados(props) {
  
    return (
        <div className='listadados'>
            <h3 className='tituloLista'>Dados do paciente</h3>

            <div className="FotoPerfil">
              <img src={props.foto} alt="Foto de Perfil" />
            </div>

            <ul>
                <li>Nome: <strong>{props.nome}</strong></li>
                <li>Idade: <strong>{localStorage.getItem("idade")}</strong></li>
                <li>Cartão do SUS: <strong>-----------</strong></li>
                <li>Raça: <strong>{localStorage.getItem("race")}</strong></li>
                <li>Naturalidade: <strong>{localStorage.getItem("nationality")}</strong></li>
                <li>Endereço: 
                    <strong>
                        {localStorage.getItem("street")} {localStorage.getItem("number")} {localStorage.getItem("city")} - {localStorage.getItem("state")} {localStorage.getItem("cep")}  
                    </strong>
                </li>
            </ul>

        </div>
    )
  
}
