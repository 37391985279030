import React from "react";

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

import styles from "./GraficoMensal.module.css";

function InformacoesFinanceirasMensais(paymentGraphic) {
    //Gráfico Financeiro Mensal

    const data = paymentGraphic.paymentGraphic;

    //const data = [{ month: "mar", total_payment: 8000 }, { month: "abr", total_payment: 10000 }, { month: "mai", total_payment: 7800 }, { month: "jun", total_payment: 6000 }, { month: "jul", total_payment: 8800 }, { month: "ago", total_payment: 5000 }, { month: "set", total_payment: 4000 }, { month: "out", total_payment: 2000 }, { month: "nov", total_payment: 6000 }];

    const CustomTooltip2 = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="legendaCustomizada">
                    <p className="label">
                        {payload === null ? "0,00" : `R$ ${parseFloat(payload[0].value).toFixed(2)}`}
                    </p>
                    <p className="intro">
                    </p>
                </div>
            );
        }

        return null;
    };

    return (
        <div className={styles.FinanceiroMensal}>
            {data.length === 0 ? (
                <h2>Não há dados para exibir</h2>
            ) : (
                <ResponsiveContainer>
                    <BarChart data={data}>
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip2 />} />
                        <Bar dataKey="total_payment" barSize={40} fill="var(--cor-azul)" />
                    </BarChart>
                </ResponsiveContainer>
            )}     
        </div>
    );
}

export default InformacoesFinanceirasMensais;