/*
Data de criação: 18/08/2020;
Autor: Jeciane Amorim;
Descrição: Página de visualização do perfil, responsável por disponibilizar
ao usuário os dados do perfil e o gerenciamento dos dados do perfil e exclusão da sua conta.
*/

import React, { useState, useEffect } from "react";
import "./estilos.css";
import api from "../../../Servicos/api";
import Menu from "../../../Componentes/GlobalMenu/indice";
import photo from "../../../Arquivos/avatarPadrao.svg";
export default function PerfilPacienteClinica() {
  const token = localStorage.getItem("token");
  const idUserGet = localStorage.getItem("idUserGet");

  const [name_first, setName_first] = useState("");
  const [name_last, setName_last] = useState();
  const [username, setUsername] = useState();
  const [escolaridade, setEscolaridade] = useState();
  const [cpf, setCpf] = useState("");
  const [data_birth, setData_birth] = useState();
  const [race, setRace] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [age, setAge] = useState();
  //const photo = "";

  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [number, setNumber] = useState();
  const [street, setStreet] = useState();
  const [housign_type, setHousign_type] = useState();
  const [cep, setCep] = useState();
  const [nationality, setNationality] = useState("");
  const [mothers_name, setMothers_name] = useState("");
  const [fathers_name, setFathers_name] = useState("");
  const [responsible_name, setResponsible_name] = useState("");
  //const [religion, setReligion] = useState();
  const [occupation, setOcupation] = useState("");
  const [marital_status, setMarital_status] = useState("");
  const [gender, setGender] = useState("");
  const [profession, setProfession] = useState("");
  const [birth_town, setBirth_town] = useState("");
  const [rg, setRg] = useState("");

  useEffect(() => {
    api
      .get(`getPatient/${idUserGet}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const dados = response.data.person;
        const data = response.data;

        setUsername(dados.user[0].username);
        setName_first(dados.name_first);
        setName_last(dados.name_last);
        setEscolaridade(dados.user[0].escolaridade);
        setCpf(dados.cpf);
        setData_birth(dados.data_birth.substring(0, 10));
        setAge(dados.age);
        setRace(dados.race);
        setEmail(dados.user[0].email);
        setPhone(dados.phone);
        setState(dados.address.state);
        setCity(dados.address.city);
        setStreet(dados.address.street);
        setNumber(dados.address.number);
        setHousign_type(dados.address.housign_type);
        setCep(dados.address.cep);
        setGender(dados.gender);

        setFathers_name(data.fathers_name);
        setMothers_name(data.mothers_name);
        setResponsible_name(data.responsible_name);
        setMarital_status(data.marital_status);

        setProfession(data.profession);
        setOcupation(data.occupation);
        setRg(data.rg);
        setNationality(data.nationality);

        setBirth_town(data.birth_town);
      });
  }, [idUserGet, token]);

  return (
    <div className="perfil-container-paciente">
      <Menu />
      <section className="conteudo-perfil">
        <div className="container-formulario">
          <div className="container-perfil">
            <img src={photo} alt="Foto de Perfil" />

            <div className="informacao">
              <h1 className="informacao-nome">{name_first}</h1>
              <h4 className="informacao-idade">{age} anos</h4>
            </div>
          </div>
          <form>
            <h2 className="titulo-formulario">Meus dados</h2>
            <div className="container-input">
              <div>
                <label htmlFor="nome">Nome</label>
                <input id="nome" value={name_first || ""} disabled={true} />
              </div>

              <div>
                <label htmlFor="sobrenome">Sobrenome</label>
                <input id="sobrenome" value={name_last || ""} disabled={true} />
              </div>
            </div>
            <div className="container-input" id="input-escolaridade">
              <div>
                <label htmlFor="escolaridade">Escolaridade</label>
                <select
                  id="escolaridade"
                  value={escolaridade || ""}
                  disabled={true}
                >
                  <option
                    value={escolaridade || ""}
                    style={{ textColor: "#6e757d" }}
                  >
                    {escolaridade}
                  </option>
                </select>
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="cpf">CPF</label>
                <input
                  disabled={true}
                  id="cpf"
                  value={`${cpf[0] + cpf[1] + cpf[2]}.***.***-**` || ""}
                />
              </div>
              <div>
                <label htmlFor="data_birth">Data de nascimento</label>
                <input
                  disabled={true}
                  id="data_birth"
                  format="yyyy-MM-dd"
                  value={data_birth || ""}
                />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="race">Cor/Raça</label>
                <select placeholder="Etnia" value={race || 0} disabled={true}>
                  <option value="">-- Etnia --</option>
                  <option value="1">Amarela</option>
                  <option value="2">Branca</option>
                  <option value="3">Negra</option>
                  <option value="4">Pardo</option>
                </select>
              </div>
              <div>
                <label htmlFor="telefone">Telefone</label>
                <input disabled={true} id="telefone" value={phone || ""} />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="email">Email</label>
                <input disabled={true} id="email" value={email || ""} />
              </div>
              <div>
                <label htmlFor="username">Nome do Usuário</label>
                <input disabled={true} id="username" value={username || ""} />
              </div>
            </div>
            <div className="container-input-nacionalidade">
              <label>Nacionalidade</label>
              <select disabled={true} value={nationality}>
                <option value="">-- Nacionalidade --</option>
                <option value="1">Brasileiro</option>
                <option value="2">Estrangeiro</option>
                <option value="3">Naturalizado</option>
              </select>
            </div>
            <div className="container-input">
              <div>
                <label>Nome da Mãe</label>
                <input disabled={true} value={mothers_name || ""} />
              </div>
              <div>
                <label htmlFor="username">Nome do Pai</label>
                <input
                  disabled={true}
                  id="username"
                  value={fathers_name || ""}
                />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label>Nome do Responsavel</label>
                <input disabled={true} value={responsible_name || ""} />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label>Ocupação</label>
                <input disabled={true} value={occupation || ""} />
              </div>
              <div>
                <label htmlFor="username">Profissão</label>
                <input disabled={true} id="username" value={profession || ""} />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label>Estado Civil</label>
                <select value={marital_status} disabled={true}>
                  <option value="">-- Estado Civil --</option>
                  <option value="1">Solteiro</option>
                  <option value="2">Casado</option>
                  <option value="3">Viuvo</option>
                </select>
              </div>
              <div>
                <label htmlFor="username">Gênero</label>
                <select placeholder="Etnia" value={gender} disabled={true}>
                  <option value="">-- Sexo --</option>
                  <option value="1">Masculino</option>
                  <option value="2">Feminino</option>
                  <option value="3">Outro</option>
                </select>
              </div>
            </div>
            <div className="container-input">
              <div>
                <label>Cidade de Nascimento</label>
                <input disabled={true} value={birth_town || ""} />
              </div>
              <div>
                <label htmlFor="username">RG</label>
                <input disabled={true} id="username" value={rg || ""} />
              </div>
            </div>
            <h2 className="titulo-formulario">Endereço</h2>
            <div id="input-estado" className="container-input">
              <div>
                <label htmlFor="state">Estado</label>
                <select id="state" value={state || ""} disabled={true}>
                  <option value={state || ""} style={{ textColor: "#6e757d" }}>
                    {state}
                  </option>
                </select>
              </div>

              <div>
                <label htmlFor="city">Cidade</label>
                <select id="city" value={city || ""} disabled={true}>
                  <option value={city} style={{ textColor: "#6e757d" }}>
                    {city}
                  </option>
                </select>
              </div>
            </div>
            <div id="input-numero" className="container-input">
              <div>
                <label htmlFor="street">Rua</label>
                <input id="street" value={street || ""} disabled={true} />
              </div>

              <div>
                <label htmlFor="number">Número</label>
                <input id="number" value={number || 0} disabled={true} />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="housign_type">Tipo da Residência</label>
                <select value={housign_type} disabled={true}>
                  <option value={"Casa" || "casa"}>Casa</option>
                </select>
              </div>

              <div>
                <label htmlFor="cep">CEP</label>
                <input id="cep" value={cep || 0} disabled={true} />
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
