import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import {
    FiChevronLeft,
    FiEdit3
} from 'react-icons/fi';

import moment from "moment";
import swal from "sweetalert";
import api from "../../../Servicos/api";

import "./estilos.css";

function FormAnnotation({
    selectedAnnotation,
    setShowTable,
    setShowPagination,
    setOpenFormAnnotation
}) {
    
    const token = localStorage.getItem("token");

    const [modoEdicao, setModoEdicao] = useState("");
    const [text, setText] = useState("");
    const [subject, setSubject] = useState("");
    const [createdAt, setCreatedAt] = useState("");
    const [updatedAt, setUpdatedAt] = useState("");

    const history = useHistory();

    useEffect(() => {
        api
            .get(`annotation/${selectedAnnotation}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setText(response.data.text);
                setSubject(response.data.subject);
                setCreatedAt(response.data.createdAt);
                setUpdatedAt(response.data.updatedAt);
            })
    }, [selectedAnnotation, token]);

    async function alterarAnotacao() {
        try {
            const data = {
                text,
                subject
            };
            
            await api.put(`annotation/${selectedAnnotation}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            
            swal("Dados alterados com Sucesso", "", "success");
            history.push("/atendente");
            history.push("/atendente/inicio");
        
        } catch (err) {
            swal(err.response.data.error, "Digite novamente", "error");
        }
    }

    return (
        <form className="form-anotacao">

            <div className="container-cabecalho">
                <Link
                    to="#"
                    className="btn-edit"
                    onClick={() => {
                        setShowTable(true);
                        setShowPagination(true);
                        setOpenFormAnnotation(false);
                    }}    
                >
                    <FiChevronLeft style={{marginBottom: "-0.35rem"}} size="20"/>Voltar
                </Link>

                <Link
                  to="#"
                  className={`btn-edit ${modoEdicao ? "editar" : ""}`}
                  onClick={() => setModoEdicao("editar")}
                  style={{marginRight: "1rem"}}
                >
                  <FiEdit3 style={{marginBottom: "-0.35rem"}} size="20" />
                    Alterar Anotação
                </Link>
            </div>

            <div className="container">
                <div>
                    <label>Data de Criação:</label>&nbsp;
                    {moment(createdAt).format("DD/MM/YYYY")}
                </div>
                <div>
                    <label>Última Atualização:</label>&nbsp;
                    {moment(updatedAt).format("DD/MM/YYYY")}
                </div>
            </div>

            <div>
                <label>Assunto:</label>
                <input
                    defaultValue={subject}
                    disabled={modoEdicao === "editar" ? false : true}
                    onChange={(e) => setSubject(e.target.value)}
                />
            </div>

            <textarea 
                defaultValue={text}
                disabled={modoEdicao === "editar" ? false : true}
                onChange={(e) => setText(e.target.value)}
            />
            
            <div className={`container-anotacao-botao ${modoEdicao}`}>
                {modoEdicao !== "" ? (
                    <>
                        <Link
                            to="#"
                            id="botaoCancelar"
                            onClick={() => {
                                setModoEdicao("");
                            }}
                            className="botao-grande"
                            >
                                Cancelar
                        </Link>
                        <Link
                            to="#"
                            id="botaoSalvar"
                            onClick={() => {
                                alterarAnotacao();
                            }}
                            className="botao-grande"
                        >
                            Salvar
                        </Link>
                    </>
                ): null}
            </div>
        </form>
    );
}

export default FormAnnotation;