//import React from 'react';

const DuvidasGravidez = () => {
    return [
        {
            id: 'duvidasGravidez',
            message: 'Aqui você pode tirar dúvidas sobre a gravidez.',
           // end: true,
           trigger: '2',
        }
    ];
};

export default DuvidasGravidez;
