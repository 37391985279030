import React from "react";

import "./estilos.css";

function Cartao({ icone, cor, titulo, valor }) {
  return (
    <div className="Cartao">
      <div className="FundoIcone" style={{ backgroundColor: cor }}>
        {icone}
      </div>
      <div className="Descricao">
        <span>{titulo}</span>
        <h2>{valor}</h2>
      </div>
    </div>
  );
}

export default Cartao;
