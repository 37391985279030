import React from "react";
import Form from "../ContainerNovoVinculo/indice";
import "./estilos.css";

function DadosCadastrais({ abrirDados, setAbrirDados }) {
  if (abrirDados === true) {
    return (
      <div className="SobreposicaoCadastrarEmpregado">
        <section id="modalEmpregado" className="ModalDadosCadastrarEmpregado">
          <footer>
            <button onClick={() => setAbrirDados(false)}>X</button>
          </footer>
          <Form setAbrirDados={setAbrirDados} />
        </section>
      </div>
    );
  }
  return null;
}

export default DadosCadastrais;
