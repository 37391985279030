import React from 'react';
import { FiX } from "react-icons/fi";
import logo from "../../../Arquivos/higia.svg";
import "./estilo.css";

function Sobre({abrirSobre, setAbrirSobre}) {
    if(abrirSobre === true){
        return (
            <div className='SobreposicaoSobre'>
                <section className='Modal'>
                    <button onClick={() => setAbrirSobre(false)}>
                        <FiX className="Icone"/>
                    </button>
                    <img src={logo} className="container-logo" alt="Logo MobileHealth" />
                    <p>O Higia é uma solução que oferece, além das funcionalidades padrão de 
                        gestão de clínicas, um módulo inteligente de acompanhamento personalizado 
                        dos pacientes, onde a inteligência artificial dará suporte ao médico para entender, de forma 
                        contínua, o estado de saúde do paciente.</p>
                    <a href="mailto:Higia.les@gmail.com">Higia.les@gmail.com</a>
                    <span>v2.00</span>
                </section>
            </div>
        );
    }
    return null;
}

export default Sobre;