import React, { useState } from "react";
import api from "../../../Servicos/api";
import "./estilos.css";
import defaultPhoto from "../../../Arquivos/avatarPadrao.svg";

function ModalFoto({ modalFoto, setModalFoto, photo, setPhoto }) {
  const [photof, setFoto] = useState();
  const [preView, setPreView] = useState(photo ? photo : defaultPhoto);

  const id = localStorage.getItem("id");
  const token = localStorage.getItem("token");

  function preViewFoto(e) {
    if (!e.target.files[0]) {
      return;
    }

    setFoto(e.target.files[0]);

    const criarPreview = URL.createObjectURL(e.target.files[0]);

    setPreView(criarPreview);
  }

  async function atualizarFoto() {
    let file = new FormData();
    file.append("name", photof.name);
    file.append("file", photof);

    await api.put(`changePhoto/${id}`, file, {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    });

    localStorage.setItem("photo", preView);
    setPhoto(preView);

    setModalFoto(false);
  }

  if (modalFoto === true)
    return (
      <div className="SobreposicaoModalFoto">
        <section>
          <div className="container-imagem">
            <label htmlFor="foto-perfil">
              <img src={preView} alt="foto-de-perfil" />
            </label>
            <input
              type="file"
              id="foto-perfil"
              onChange={(e) => preViewFoto(e)}
            />
          </div>
          <h5 id="text-select-photo">Selecione a foto que deseja</h5>
          <div>
            <button
              type="button"
              className="BtnCancelar"
              onClick={() => {
                setModalFoto(false);
              }}
            >
              Cancelar
            </button>
            <button
              type="button"
              onClick={() => {
                atualizarFoto();
              }}
            >
              Salvar
            </button>
          </div>
        </section>
      </div>
    );

  return null;
}

export default ModalFoto;
