/*
  Data de Criação: 24/09/2020;
  Autor: Caio Rafael da Rocha Gomes;
  Descrição: Página para listagem das consultas do médico
*/
import React, { useState, useEffect } from "react";
import {
  FiBell,
  FiClock,
  FiCheck,
  FiUser,
  FiEye,
  FiXCircle,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import api from "../../../Servicos/api";
import Menu from "../../../Componentes/GlobalMenu/indice";
import Vizualizar from "../../../Componentes/Paciente/VisualizarConsulta/indice";
import DadosCadastraisMedico from "../../../Componentes/Paciente/DadosCadastraisMedico/indice";
import "./estilos.css";
import Paginacao from "../../../Componentes/Paginacao/indice";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";

function Consultas() {
  const [abrirDados, setAbrirDados] = useState(false);
  const [abrirDadosMedicos, setAbrirDadosMedicos] = useState(false);
  const token = localStorage.getItem("token");
  const idPaciente = localStorage.getItem("IdPaciente");
  const nome = localStorage.getItem("name_first");

  const [listaConsultas, setListaConsultas] = useState([]);
  const [listaConsultasTabela, setListaConsultasTabela] = useState([]);
  const [mostrarCanceladas, setMostrarCanceladas] = useState(false);
  const [mostrarAgendadas, setMostrarAgendadas] = useState(true);
  const [mostrarFinalizadas, setMostrarFinalizadas] = useState(false);

  const limitPage = 7;
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState();
  const [option, setOption] = useState(1);
  const [/*nextReq,*/ setNextReq] = useState();

  const foto = localStorage.getItem("photo");

  useEffect(() => {
    api
      .get(
        `/patient/consultation/${idPaciente}/?page=${page}&limit=${limitPage}&option=${option}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setListaConsultas(response.data.data);
        setTotal(response.data.total);
      });
  }, [token, idPaciente, page, option]);

  //pesquisar por nome
  const [pesquisaNome, setPesquisaNome] = useState("");

  useEffect(() => {
    const resultados = listaConsultas.filter((paciente) =>
      paciente.doctor.person.name_first
        .toLowerCase()
        .includes(pesquisaNome.toLowerCase())
    );
    setListaConsultasTabela(resultados);
  }, [pesquisaNome, listaConsultas]);

  //pesquisar por especialização
  const [pesquisaEspecializacao, setPesquisaEspecializacao] = useState("");
  useEffect(() => {
    const resultados = listaConsultas.filter((paciente) =>
      paciente.doctor.area_specialization
        .toLowerCase()
        .includes(pesquisaEspecializacao.toLowerCase())
    );
    setListaConsultasTabela(resultados);
  }, [pesquisaEspecializacao, listaConsultas]);

  const titulos = [
    { nome: "Médico" },
    { nome: "Especialidade" },
    { nome: "Tipo da consulta" },
    { nome: "Data" },
    { nome: "Horário" },
    { nome: "Opções" },
  ];

  function exibirAgendados() {
    setMostrarAgendadas(true);
    setMostrarFinalizadas(false);
    setMostrarCanceladas(false);
    setPage(0);
    setOption(1);
  }

  function exibirFinalizadas() {
    setMostrarFinalizadas(true);
    setMostrarAgendadas(false);
    setMostrarCanceladas(false);
    setPage(0);
    setOption(2);
  }

  function exibirCanceladas() {
    setMostrarCanceladas(true);
    setMostrarAgendadas(false);
    setMostrarFinalizadas(false);
    setPage(0);
    setOption(3);
  }

  function dateToEN(date) {
    return date.slice(0, 10).split("-").reverse().join("/");
  }

  return (
    <div className="ContainerConsultas">
      <Menu />
      <Vizualizar abrirDados={abrirDados} setAbrirDados={setAbrirDados} />
      <DadosCadastraisMedico
        abrirDadosMedicos={abrirDadosMedicos}
        setAbrirDadosMedicos={setAbrirDadosMedicos}
      />
      <div className="ContainerConteudo">
        <header>
          <div id="div-ajuda">
            <h1>Suas consultas, {nome}</h1>
            <Ajuda
              mensagem={
                "Nesta tela é possível visualizar todas as suas consultas agendadas, canceladas e finalizadas, permitindo buscar por nome ou especialidade do médico."
              }
              width={"33rem"}
              backColor={"var(--cor-branco)"}
              fontColor={"var(--cor-azul)"}
              margin={"0 0 0 1.5rem"}
              border={"0.5rem"}
              size={"2.5rem"}
            />
          </div>
          <div className="Perfil">
            <Link to="">
              <FiBell className="Notificacao" />
            </Link>
            <div className="FotoPerfil">
              <img src={foto} alt="Foto de  Perfil" />
            </div>
          </div>
        </header>
        <section>
          <div className="Pesquisa">
            <div className="pesquisaNome">
              <input
                value={pesquisaNome}
                type="text"
                className="inputPesquisa"
                placeholder="Buscar por nome do Médico"
                onChange={(e) => setPesquisaNome(e.target.value)}
              />
              <label className="labelPesquisa">Médico</label>
            </div>
            <div className="pesquisaCpf">
              <input
                value={pesquisaEspecializacao}
                type="text"
                className="inputPesquisa"
                placeholder="Buscar por Especialidade"
                onChange={(e) => setPesquisaEspecializacao(e.target.value)}
              />
              <label className="labelPesquisa">Especialidade</label>
            </div>
          </div>
          <div className="Tabela">
            <div>
              <button onClick={exibirAgendados} className="botaoAgendados">
                <span>Agendadas</span> <FiClock />
              </button>
              <button onClick={exibirFinalizadas} className="botaoFinalizados">
                <span>Finalizadas</span> <FiCheck />
              </button>
              <button onClick={exibirCanceladas} className="botaoCancelados">
                <span>Canceladas</span> <FiXCircle />
              </button>
            </div>
            <div className="tabelaConsultas">
              <table
                style={
                  mostrarAgendadas
                    ? { display: "inline-table" }
                    : { display: "none" }
                }
              >
                <thead>
                  <tr>
                    {titulos.map((titulo) => (
                      <th key={titulo.nome}>{titulo.nome}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listaConsultasTabela.length === 0 ? (
                    <tr>
                      <td>Não há consultas agendadas.</td>
                    </tr>
                  ) : (
                    listaConsultasTabela.reverse().map((info) => (
                      <tr key={info.id}>
                        <td>{info.doctor.person.name_first}</td>
                        <td>{info.doctor.area_specialization}</td>
                        <td>
                          {" "}
                          {info.type === 1
                            ? "Consulta - 1 vez"
                            : info.type === 2
                              ? "Consulta - Rotina"
                              : info.type === 3
                                ? "Consulta - Retorno"
                                : "Exames"}
                        </td>
                        <td>{dateToEN(info.start_time)}</td>
                        <td>{info.start_time.slice(11, 16)}</td>
                        <td>
                          <Link
                            to="#"
                            onClick={() => {
                              setAbrirDadosMedicos(true);
                              localStorage.setItem("doctor_id", info.doctor.id);
                            }}
                          >
                            <FiUser />
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <table
                style={
                  mostrarFinalizadas
                    ? { display: "inline-table" }
                    : { display: "none" }
                }
              >
                <thead>
                  <tr>
                    {titulos.map((titulo) => (
                      <th key={titulo.nome}>{titulo.nome}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listaConsultasTabela.length === 0 ? (
                    <tr>
                      <td>Não há consultas finalizadas.</td>
                    </tr>
                  ) : (
                    listaConsultasTabela.reverse().map((info) => (
                      <tr key={info.id}>
                        <td>{info.doctor.person.name_first}</td>
                        <td>{info.doctor.area_specialization}</td>
                        <td>
                          {info.type === 1
                            ? "Consulta - 1 vez"
                            : info.type === 2
                              ? "Consulta - Rotina"
                              : info.type === 3
                                ? "Consulta - Retorno"
                                : "Exames"}
                        </td>
                        <td>{dateToEN(info.start_time)}</td>
                        <td>{info.start_time.slice(11, 16)}</td>
                        <td>
                          <Link
                            to="#"
                            onClick={() => {
                              setAbrirDados(true);
                              localStorage.setItem("idConsulta", info.id);
                            }}
                          >
                            <FiEye />
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <table
                style={
                  mostrarCanceladas
                    ? { display: "inline-table" }
                    : { display: "none" }
                }
              >
                <thead>
                  <tr>
                    {titulos.map((titulo) => (
                      <th key={titulo.nome}>{titulo.nome}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listaConsultasTabela.length === 0 ? (
                    <tr>
                      <td>Não há consultas canceladas.</td>
                    </tr>
                  ) : (
                    listaConsultasTabela.reverse().map((info) => (
                      <tr key={info.id}>
                        <td>{info.doctor.person.name_first}</td>
                        <td>{info.doctor.area_specialization}</td>
                        <td>
                          {info.type === 1
                            ? "Consulta - 1 vez"
                            : info.type === 2
                              ? "Consulta - Rotina"
                              : info.type === 3
                                ? "Consulta - Retorno"
                                : "Exames"}
                        </td>
                        <td>{dateToEN(info.start_time)}</td>
                        <td>{info.start_time.slice(11, 16)}</td>
                        <td>
                          <Link
                            to="#"
                            onClick={() => {
                              setAbrirDados(true);
                              localStorage.setItem("idConsulta", info.id);
                            }}
                          >
                            <FiEye />
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {mostrarAgendadas ? (
                <Paginacao
                  consultasPorPagina={limitPage}
                  totalConsulta={total}
                  setPage={setPage}
                />
              ) : mostrarFinalizadas ? (
                <Paginacao
                  consultasPorPagina={limitPage}
                  totalConsulta={total}
                  setNextReq={setNextReq}
                  setPage={setPage}
                />
              ) : setMostrarCanceladas ? (
                <Paginacao
                  consultasPorPagina={limitPage}
                  totalConsulta={total}
                  setNextReq={setNextReq}
                  setPage={setPage}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Consultas;
