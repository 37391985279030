/*
Data de criação: 22/10/2020;
Autor: Jeciane Amorim;
Descrição: Listagem de usuários Ativos e Inativos (Visualizar dados e modificar status)  - Perfil Clinica;
*/

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiBell, FiChevronsRight } from "react-icons/fi";

import Menu from "../../../Componentes/GlobalMenu/indice";
import Paginacao from "../../../Componentes/Paginacao/indice";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";

import Chat from "../../../Componentes/Geral/Chat/index";

import "./estilos.css";
import api from "../../../Servicos/api";
import { cpfMascara } from "../../../Servicos/Mascaras/cpfMascara";
import { BsChat } from "react-icons/bs";


function UsuariosClinica() {
  const token = localStorage.getItem("token");
  const person_id = localStorage.getItem("person_id");
  const name_first = localStorage.getItem("name_first");

  const [listaUsuarios /*setListaUsuario*/] = useState([]);
  const [listaUsuariosTabela, setListaUsuarioTabela] = useState([]);
  const [chat, setChat] = useState(false);
  const [id, setId] = useState(0);
  const [receiverInformation, setReceiverInformation] = useState({});

  const [, /*paginaAtual*/ setPaginaAtual] = useState(1);
  const consultasPorPagina = 10;

  const foto = localStorage.getItem("photo");

  useEffect(() => {
    api
      .get(`/listMedicalSupervision/${person_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          is_doctor: 0,
        },
      })
      .then((response) => {
        setListaUsuarioTabela(response.data);
      });
  }, [token, person_id]);

  // function FormataStringData(data) {
  //   var dia = data.split("-")[2];
  //   var mes = data.split("-")[1];
  //   var ano = data.split("-")[0];

  //   return ("0" + dia).slice(-2) + "/" + ("0" + mes).slice(-2) + "/" + ano;
  //   // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  // }

  // const indexUltimaConsulta = paginaAtual * consultasPorPagina;
  // const indexPrimeiraConsulta = indexUltimaConsulta - consultasPorPagina;

  const paginar = (numero) => setPaginaAtual(numero);
  
  //Buscar Pacientes pelo Nome
  const [pesquisaNome, setPesquisaNome] = useState("");
  useEffect(() => {
    const resultados = listaUsuarios.filter((usuario) =>
      usuario.name.toLowerCase().includes(pesquisaNome.toLowerCase())
    );
    setListaUsuarioTabela(resultados);
  }, [pesquisaNome, listaUsuarios]);

  //Buscar Pacientes pelo cpf
  const [pesquisaCpf, setPesquisaCpf] = useState("");
  useEffect(() => {
    const resultados = listaUsuarios.filter((usuario) =>
      usuario.cpf.includes(pesquisaCpf)
    );
    setListaUsuarioTabela(resultados);
  }, [pesquisaCpf, listaUsuarios]);

  //dados para o modal

  const titulos = [
    { nome: "Especialização" },
    { nome: "Nome" },
    { nome: "CPF" },
    { nome: "Telefone" },
    { nome: "Data" },
    { nome: "Opções" },
  ];

  //console.table(listaUsuariosTabela);

  return (
    <div className="ContainerAcompanhamentoPaciente">
      <Menu />
      {/* <ChatPaciente */}
      <Chat
        chatModal={chat}
        setChatModal={setChat}
        id={id}
        receiverInformation={receiverInformation}
      />
      <div className="container-acompanhamentos-paciente">
        <div className="header-usuario">
          <h1>Seus Acompanhamentos, {name_first}.</h1>
          <div className="Perfil">
            <Link to="">
              <FiBell className="Notificacao" />
            </Link>
            <div className="FotoPerfil">
              <img src={foto} alt="Foto de  Perfil" />
            </div>
          </div>
        </div>
        <nav>
          <div className="navegacaoUsuarios">
            <h2>{"Acompanhamentos"}</h2>
            <h5>
              <FiChevronsRight id="Icone" /> {"Busca & Visão geral *"}
            </h5>
            <Ajuda
              mensagem={
                "Nesta tela é possível visualizar os seus acompanhamentos, permitindo buscar por nome ou cpf."
              }
              width={"34rem"}
              backColor={"var(--cor-branco)"}
              fontColor={"var(--cor-azul)"}
              margin={"0 0 0 1.5rem"}
              border={"0.5rem"}
              size={"2.5rem"}
            />
          </div>
        </nav>

        <section>
          <div className="Pesquisa">
            <div className="pesquisaNome">
              <input
                value={pesquisaNome}
                type="text"
                className="inputPesquisa"
                placeholder="Buscar por nome"
                onChange={(e) => setPesquisaNome(e.target.value)}
                onKeyPressCapture={(e) => setPesquisaNome(e.target.value)}
              />
              <label className="labelPesquisa">Nome do Usuários</label>
            </div>
            <div className="pesquisaCpf">
              <input
                value={pesquisaCpf}
                type="text"
                className="inputPesquisa"
                placeholder="Buscar por CPF"
                onChange={(e) => setPesquisaCpf(cpfMascara(e.target.value))}
                onKeyPressCapture={(e) =>
                  setPesquisaCpf(cpfMascara(e.target.value))
                }
              />
              <label className="labelPesquisa">CPF</label>
            </div>
          </div>

          <div className="navTabela">
            <div className="tabelaUsuarios">
              <table>
                <thead>
                  <tr>
                    {titulos.map((titulo) => (
                      <th key={titulo.nome}>{titulo.nome}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listaUsuariosTabela.length === 0 ? (
                    <tr>
                      <td colSpan={6} id="msgAcomp">Não há acompanhamento cadastrado.</td>
                    </tr>
                  ) : (
                    listaUsuariosTabela.map((info) => (
                      <tr key={info.id}>
                        <td>{info.doctor.area_specialization}</td>
                        <td>
                          {info.doctor.person.name_first}{" "}
                          {info.doctor.person.name_last}
                        </td>
                        <td>{info.doctor.person.cpf}</td>
                        <td>{info.doctor.person.phone}</td>
                        <td>{info.date}</td>
                        <td id="opcaoEditExcluirAcom">
                          <button
                            className="alterar-status"
                            onClick={() => {
                              setChat(true);
                              setId(info.id);
                              setReceiverInformation({
                                receiverId: info.doctor.person.user[0].id,
                                receiverName: info.doctor.person.name_first,
                                receiverPhoto: info.doctor.person.user[0].photo
                              });
                            }}
                          >
                            <BsChat color="#1070a1" />
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <Paginacao
                consultasPorPagina={consultasPorPagina}
                totalConsulta={listaUsuariosTabela.length}
                paginar={paginar}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default UsuariosClinica;
