import React, { useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import api from "../../../Servicos/api";
// import swal from "sweetalert";
import "./estilos.css";
import { FiChevronDown, FiPlus, FiPrinter } from "react-icons/fi";
import { FaWindowClose } from "react-icons/fa";
import Pdf from "../Impressao/indice";

function VisualizarConsulta({ abrirDados, setAbrirDados }) {
  //Abrir/Fechar seções
  const [abrirConsulta, setAbrirConsulta] = useState("");
  const [abrirReceita, setAbrirReceita] = useState("");
  const [abrirAtestado, setAbrirAtestado] = useState("");
  const [abrirExames, setAbrirExames] = useState("");
  const token = localStorage.getItem("token");

  //Função para retirar tag HTML do response consulta
  // eslint-disable-next-line no-extend-native
  String.prototype.stripHTML = function () {
    return this.replace(/<.*?>/g, "");
  };
  //

  const [complaint, setComplaint] = useState("Queixa inicial");

  // const [has, setHas] = useState();
  // const [diabetes, setDiabetes] = useState();
  // const [heart_diseases, setHeart_diseases] = useState();
  // const [coagulation, setCoagulation] = useState();
  // const [hyperthyroidism, setHyperthyroidism] = useState();
  // const [hypothyroidism, setHypothyroidism] = useState();
  // const [depression, setDepression] = useState();
  // const [smoking, setSmoking] = useState();
  // const [other_complaint, setOther_complaint] = useState();
  const [history, setHistory] = useState("");
  const [family_history, setFamily_history] = useState("");

  // const [antihypertensive, setAntihypertensive] = useState();
  // const [antidepressant, setAntidepressant] = useState();
  // const [multivitamins, setMultivitamins] = useState();
  // const [ginkobiloba, setGinkobiloba] = useState();
  // const [ass, setAss] = useState();
  // const [aco, setAco] = useState();
  // const [other_medicines, setOther_medicines] = useState();
  const [desc_medicines, setDesc_medicines] = useState("");
  // const [no_surgery, setNo_surgery] = useState();
  // const [aesthetic, setAesthetic] = useState();
  // const [other_surgery, setOther_surgery] = useState();
  const [desc_surgery, setDesc_surgery] = useState("");

  const [physical_examination, setPhysical_examination] = useState("");
  const [hypothesis, setHypothesis] = useState("");
  const [conduct, setConduct] = useState("");

  const [tituloExame, setTituloExame] = useState("");
  const [descricaoExame, setDescricaoExame] = useState("");
  const [medications, setMedications] = useState([]);

  const [dataConsulta, setDataConsulta] = useState("");
  const [name_first, setName_first] = useState("");
  const [name_last, setName_last] = useState("");

  const [crm, setCrm] = useState("");
  const [uf, setUf] = useState("");
  const [primeiroNomeMedico, setPrimeiroNomeMedico] = useState("");
  const [ultimoNomeMedico, setUltimoNomeMedico] = useState("");

  async function DadosConsulta() {
    const idConsulta = localStorage.getItem("idConsulta");
    try {
      const response = await api.get(`viewConsutation/${idConsulta}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setComplaint(response.data.form.complaint || "");
      setConduct(response.data.form.conduct || "");
      setHistory(response.data.form.history || "");
      setFamily_history(response.data.form.family_history || "");
      setDesc_medicines(response.data.form.desc_medicines || "");
      setDesc_surgery(response.data.form.desc_surgery || "");
      setPhysical_examination(response.data.form.physical_examination || "");
      setHypothesis(response.data.form.hypothesis || "");
      setTituloExame(response.data.exam.exam_array[0].nome || "");
      setDescricaoExame(response.data.exam.exam_array[0].obs || "");
      setMedications(response.data.medication.medications_array || "");
      setName_first(response.data.patient.person.name_first || "");
      setName_last(response.data.patient.person.name_last || "");

      setPrimeiroNomeMedico(response.data.doctor.person.name_first || "");
      setUltimoNomeMedico(response.data.doctor.person.name_last || "");
      setCrm(response.data.doctor.crm || "");
      setUf(response.data.doctor.uf || "");
      setDataConsulta(response.data.consultation.date);
      //swal("Cadastrado com Sucesso", "", "success");
      //history.push("/clinica/pacientes");
    } catch (err) {
      console.log("catch");
      //swal(err.response.data.error, "Digite novamente", "error");
    }
  }

  const [medicamentos] = useState([
    { nome: "Medicamento 1" },
    { nome: "Medicamento 2" },
  ]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const receituario = useRef();
  const handleReceiturario = useReactToPrint({
    content: () => receituario.current,
  });

  const dadosConsulta = useRef();
  const handleConsulta = useReactToPrint({
    content: () => dadosConsulta.current,
  });

  const [variavel, setVar] = useState(1);

  function dataBR(date) {
    return date.split("-").reverse().join("/");
  }

  if (abrirDados === true) {
    if (variavel === 1) {
      DadosConsulta();
      setVar(2);
    }
    return (
      <div className="SobreposicaoVisualizarConsulta">
        <section className="ModalVisualizarConsulta">
          <main>
            <button
              className="BotaoFechar"
              onClick={() => {
                setAbrirDados(false);
                setVar(1);
              }}
            >
              <FaWindowClose></FaWindowClose>
            </button>
            <div className="BotaoImprimir">
              <div style={{ display: "none" }}>
                <Pdf
                  ref={dadosConsulta}
                  nomeMedico={primeiroNomeMedico}
                  nome2={ultimoNomeMedico}
                  crm={crm}
                  uf={uf}
                >
                  <h1>Dados da Consulta</h1>
                  <span>Paciente: {`${name_first} ${name_last}`}</span>
                  <div className="observacoes">
                    <h4>Queixa Principal:</h4>
                    <p>{complaint.stripHTML() || "Não descrito"}</p>
                    <h4>Antecedentes Clinicos:</h4>
                    <p>{history.stripHTML() || "Não descrito"}</p>
                    <h4>Antecedentes Familiares:</h4>
                    <p>{family_history.stripHTML() || "Não descrito"}</p>
                    <h4>Uso de medicamentos:</h4>
                    <p>{desc_medicines.stripHTML() || "Não descrito"}</p>
                    <h4>Cirurgias Prévias:</h4>
                    <p>{desc_surgery.stripHTML() || "Não descrito"}</p>
                    <h4>Exame Físico:</h4>
                    <p>{physical_examination.stripHTML() || "Não descrito"}</p>
                    <h4>Hipótese Diagnostica:</h4>
                    <p>{hypothesis.stripHTML() || "Não descrito"}</p>
                    <h4>Condulta:</h4>
                    <p>{conduct.stripHTML() || "Não descrito"}</p>
                    <h4>Exame(s):</h4>
                    <p>
                      <strong>Tipo de Exame:</strong> {tituloExame}
                    </p>
                    <p>{descricaoExame.stripHTML()}</p>

                    <span className="data">Data:{dataBR(dataConsulta)}</span>
                  </div>
                </Pdf>
              </div>

              <button className="BotaoEditor" onClick={handleConsulta}>
                <FiPrinter className="Icone" /> Imprimir
              </button>
            </div>
            <section className={`SecaoConsulta ${abrirConsulta}`} id="Consulta">
              <div
                className="Titulo"
                onClick={() =>
                  abrirConsulta === "ativo"
                    ? setAbrirConsulta("")
                    : setAbrirConsulta("ativo")
                }
              >
                <h2>Consulta</h2>
                <FiChevronDown className="Icone" />
              </div>
              <div className="Conteudo">
                <h3>Queixa Principal*</h3>
                <h4>Descrição da queixa:</h4>
                <textarea
                  name=""
                  id=""
                  cols="80"
                  rows="15"
                  style={{ resize: "none" }}
                  disabled={true}
                  value={complaint.stripHTML() || "Não foi descrito"}
                ></textarea>

                <h3>Anamnese</h3>
                <h4>Antecedentes clínicos:</h4>

                <textarea
                  name=""
                  id=""
                  cols="80"
                  rows="15"
                  style={{ resize: "none" }}
                  disabled={true}
                  value={history.stripHTML() || "Não foi descrito"}
                ></textarea>

                <h4>Antecedentes familiares:</h4>
                <textarea
                  name=""
                  id=""
                  cols="80"
                  rows="15"
                  style={{ resize: "none" }}
                  disabled={true}
                  value={family_history.stripHTML() || "Não foi descrito"}
                ></textarea>

                <h4>Uso de medicamentos:</h4>

                <textarea
                  name=""
                  id=""
                  cols="80"
                  rows="15"
                  style={{ resize: "none" }}
                  disabled={true}
                  value={desc_medicines.stripHTML() || "Não foi descrito"}
                ></textarea>

                <h4>Cirurgias prévias:</h4>

                <textarea
                  name=""
                  id=""
                  cols="80"
                  rows="15"
                  style={{ resize: "none" }}
                  disabled={true}
                  value={desc_surgery.stripHTML() || "Não foi descrito"}
                ></textarea>

                <h4>Exame Físico:</h4>
                <textarea
                  name=""
                  id=""
                  cols="80"
                  rows="15"
                  style={{ resize: "none" }}
                  disabled={true}
                  value={physical_examination.stripHTML() || "Não foi descrito"}
                ></textarea>

                <h4>Hipótese Diagnóstica:</h4>
                <textarea
                  name=""
                  id=""
                  cols="80"
                  rows="15"
                  style={{ resize: "none" }}
                  disabled={true}
                  value={hypothesis.stripHTML() || "Não foi descrito"}
                ></textarea>

                <h4>Conduta:</h4>
                <textarea
                  name=""
                  id=""
                  cols="80"
                  rows="15"
                  style={{ resize: "none" }}
                  disabled={true}
                  value={conduct.stripHTML() || "Não foi descrito"}
                ></textarea>
              </div>
            </section>

            <section className={`SecaoConsulta ${abrirReceita} flex`}>
              <div
                className="Titulo"
                onClick={() =>
                  abrirReceita === "ativo"
                    ? setAbrirReceita("")
                    : setAbrirReceita("ativo")
                }
              >
                <h2>Receita</h2>
                <FiChevronDown className="Icone" />
              </div>
              <div className="Conteudo">
                <h4>Descrição:</h4>
                <div className="ConteudoFlex">
                  <div className="ConteudoDireito">
                    <span>
                      Medicamentos <FiPlus className="Icone" />
                    </span>

                    <select name="medicamentos" id="medicamentos">
                      <option value="---">---</option>
                      {medicamentos.map((medicamento) => (
                        <option value={medicamento.nome}>
                          {medicamento.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div style={{ display: "none" }}>
                  <Pdf
                    ref={receituario}
                    nomeMedico={primeiroNomeMedico}
                    nome2={ultimoNomeMedico}
                    crm={crm}
                    uf={uf}
                  >
                    <h1>Receituário</h1>
                    <span>Paciente: Chico Lopes</span>

                    <div className="observacoes">
                      <h2>Medicamento(s):</h2>
                      {medications.map((medicamento) => (
                        <>
                          <p>
                            {`${medicamento.use}`}
                            <br />
                            {`${medicamento.name} ----------------------------------- ${medicamento.amount}`}
                            <br />
                            {`${medicamento.prescription}`}
                          </p>
                          <br />
                        </>
                      ))}
                      <span className="data">Data:{dataBR(dataConsulta)}</span>
                    </div>
                  </Pdf>
                </div>

                <button className="BotaoEditor" onClick={handleReceiturario}>
                  <FiPrinter className="Icone" /> Imprimir
                </button>
              </div>
            </section>
            <section className={`SecaoConsulta ${abrirAtestado}`} id="Atestado">
              <div
                className="Titulo"
                onClick={() =>
                  abrirAtestado === "ativo"
                    ? setAbrirAtestado("")
                    : setAbrirAtestado("ativo")
                }
              >
                <h2>Atestado</h2>
                <FiChevronDown className="Icone" />
              </div>
              <div className="Conteudo">
                <div className="ConteudoFlex">
                  <textarea
                    name=""
                    id=""
                    cols="80"
                    rows="15"
                    style={{ resize: "none" }}
                    disabled={true}
                  ></textarea>
                  <div className="ConteudoDireito">
                    <span>
                      Modelo <FiPlus className="Icone" />
                    </span>

                    <select name="medicamentos" id="medicamentos">
                      <option value="---">---</option>
                    </select>
                  </div>
                </div>
                <div style={{ display: "none" }}>
                  <Pdf
                    ref={componentRef}
                    nomeMedico={primeiroNomeMedico}
                    nome2={ultimoNomeMedico}
                    crm={crm}
                    uf={uf}
                  >
                    <h1>Atestado Médico</h1>
                    <br />
                    <span>Paciente: Chico Lopes</span>

                    <div className="observacoes">
                      <h2>Observações:</h2>
                      <p>
                        Atesto para devidos fins que Chico Lopes R. G.
                        000.000.000 esteve sob tratamento médico neste
                        consultorio no periodo de 15:00 às 17:00 horas do dia
                        12/10/2020 nescessitando o(a) mesmo(a) de 20 dias de
                        repouso
                      </p>
                    </div>
                    <span className="data">Data:{dataBR(dataConsulta)}</span>
                  </Pdf>
                </div>
                <button className="BotaoEditor" onClick={handlePrint}>
                  <FiPrinter className="Icone" /> Imprimir
                </button>
              </div>
            </section>

            <section className={`SecaoConsulta ${abrirExames}`} id="Exames">
              <div
                className="Titulo"
                onClick={() =>
                  abrirExames === "ativo"
                    ? setAbrirExames("")
                    : setAbrirExames("ativo")
                }
              >
                <h2>Exames</h2>
                <FiChevronDown className="Icone" />
              </div>
              <div className="Conteudo">
                <div className="ConteudoDireito">
                  <label htmlFor="tipoexame">Tipo de Exame:</label>
                  <input
                    type="text"
                    name="tipoexame"
                    id="tipoexame"
                    placeholder="Tipo de Exame"
                    disabled={true}
                    value={tituloExame}
                  />
                </div>
                <textarea
                  name=""
                  id=""
                  cols="80"
                  rows="15"
                  style={{ resize: "none" }}
                  disabled={true}
                  value={descricaoExame.stripHTML()}
                ></textarea>
              </div>
            </section>
          </main>
        </section>
      </div>
    );
  }
  return null;
}

export default VisualizarConsulta;
