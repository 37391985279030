import React from "react";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

import LogoMeet from "../../../Arquivos/logoMeet.svg";
import LogoWhatsApp from "../../../Arquivos/logoWhatsApp.svg";
import LogoEmail from "../../../Arquivos/logoEmail.svg";
import FotoPaciente from "../../../Arquivos/fotoPaciente.jpg";

import "./estilos.css";

export default function DetalhesConsulta({
  nome,
  idade,
  sintomas,
  listaExames,
}) {
  return (
    <div className="Informacoes">
      <div className="IdentificacaoPaciente">
        <div className="FotoPaciente">
          <img src={FotoPaciente} alt="" />
        </div>
        <div className="NomePaciente">
          <h3>{nome}</h3>
          <h4>{idade}</h4>
        </div>
      </div>
      <div className="DetalhesPaciente">
        <h3>Sintomas:</h3>
        <p>{sintomas}</p>
        <h3>Exames solicitados</h3>
        <div className="exame">
          {listaExames.map((exame) => (
            <p key={exame.nomeExame}>{`- ${exame.nomeExame}`}</p>
          ))}
        </div>
        <div className="DetalhesContato">
          <div className="Contato">
            <Link to="#">
              <img src={LogoWhatsApp} alt="" />
            </Link>
            <Link to="#">
              <img src={LogoEmail} alt="" />
            </Link>
            <Link to="#">
              <img src={LogoMeet} alt="" />
            </Link>
          </div>
          <div className="MaisDetalhes">
            <Link to="#">
              {" "}
              Ver mais detalhes <FiArrowRight className="Icone" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
