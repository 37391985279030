/*
Data de criação: 22/10/2020;
Autor: Jeciane Amorim;
Descrição: Listagem de usuários Ativos e Inativos (Visualizar dados e modificar status)  - Perfil Clinica;
*/

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiBell, FiChevronsRight, FiEdit2 } from "react-icons/fi";
import { BsChat } from "react-icons/bs";

import { GoCommentDiscussion } from "react-icons/go";
import Menu from "../../../Componentes/GlobalMenu/indice";

import Chat from "../../../Componentes/Geral/Chat/index";

import Paginacao from "../../../Componentes/Paginacao/indice";

import ModalCadastrar from "../../../Componentes/Medico/Modal-Cadastro-Acompanhamento/indice";
import ModalEdit from "../../../Componentes/Medico/Modal-Edit-Acompanhamento/indice";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";

import "./estilos.css";
import api from "../../../Servicos/api";
import { cpfMascara } from "../../../Servicos/Mascaras/cpfMascara";

function UsuariosClinica() {
  const token = localStorage.getItem("token");
  const person_id = localStorage.getItem("person_id");
  const name_first = localStorage.getItem("name_first");

  const [listaUsuarios, setListaUsuario] = useState([]);
  const [listaUsuariosTabela, setListaUsuarioTabela] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const consultasPorPagina = 10;

  const foto = localStorage.getItem("photo");

  const [abrirModalNovoVinculo, setAbrirModalNovoVinculo] = useState(false);
  const [abrirModalEdit, setAbrirModalEdit] = useState(false);

  const [chat, setChat] = useState(false);
  const [messageId, setMessageId] = useState(null);
  const [receiverInformation, setReceiverInformation] = useState({});
  //abrir modal

  const indexUltimaConsulta = paginaAtual * consultasPorPagina;
  const indexPrimeiraConsulta = indexUltimaConsulta - consultasPorPagina;

  listaUsuariosTabela.slice(indexPrimeiraConsulta, indexUltimaConsulta);

  const paginar = numero => setPaginaAtual(numero);

  useEffect(() => {
    api
      .get(`/listMedicalSupervision/${person_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          is_doctor: 'true',
        },
      })
      .then((response) => {
        setListaUsuario(response.data);
      });
  }, [token, person_id, abrirModalNovoVinculo]);

  //Buscar Pacientes pelo Nome
  const [pesquisaNome, setPesquisaNome] = useState("");
  useEffect(() => {
    const resultados = listaUsuarios.filter((usuario) =>
      usuario.patient.person.name_first
        .toLowerCase()
        .includes(pesquisaNome.toLowerCase())
    );
    setListaUsuarioTabela(resultados);
  }, [pesquisaNome, listaUsuarios]);

  // //Buscar Pacientes pelo cpf
  const [pesquisaCpf, setPesquisaCpf] = useState("");
  useEffect(() => {
    const resultados = listaUsuarios.filter((usuario) =>
      usuario.patient.person.cpf.includes(pesquisaCpf)
    );
    setListaUsuarioTabela(resultados);
  }, [pesquisaCpf, listaUsuarios]);

  // const FormataStringData = (data) => {
  //   var dia = data.split("-")[2];
  //   var mes = data.split("-")[1];
  //   var ano = data.split("-")[0];

  //   return ("0" + dia).slice(-2) + "/" + ("0" + mes).slice(-2) + "/" + ano;
  // }

  const titulos = [
    { nome: "Nome" },
    { nome: "CPF" },
    { nome: "Telefone" },
    { nome: "Data" },
    { nome: "Opções" },
  ];

  return (
    <div className="ContainerAcompanhamento">
      <ModalCadastrar
        abrirDados={abrirModalNovoVinculo}
        setAbrirDados={setAbrirModalNovoVinculo}
      />
      <ModalEdit
        abrirDados={abrirModalEdit}
        setAbrirDados={setAbrirModalEdit}
        id={messageId}
      />
      <Menu />
      <Chat
        chatModal={chat}
        setChatModal={setChat}
        id={messageId}
        receiverInformation={receiverInformation}
      />
      <div className="container-acompanhamentos">
        <div className="header-usuario">
          <h1>Seus Acompanhamentos {name_first}.</h1>
          <div className="Perfil">
            <Link to="">
              <FiBell className="Notificacao" />
            </Link>
            <div className="FotoPerfil">
              <img src={foto} alt="Foto de Perfil" />
            </div>
          </div>
        </div>
        <nav>
          <div className="navegacaoUsuarios">
            <h2>{"Acompanhamentos"}</h2>
              <FiChevronsRight size={20} />
               <h5>{"Busca & Visão geral *"}</h5>
            <Ajuda
              mensagem={
                "Nesta tela é possivel gerenciar seus acompanhamentos médicos, possibilitando registrar, visualizar e remover. "
              }
              width={"30rem"}
              backColor={"var(--cor-branco)"}
              fontColor={"var(--cor-azul)"}
              margin={"0 0 0 1.5rem"}
              border={"0.5rem"}
              size={"2.5rem"}
            />
          </div>
        </nav>

        <section>
          <div className="Pesquisa">
            <div className="pesquisaNome">
              <input
                value={pesquisaNome}
                type="text"
                className="inputPesquisa"
                placeholder="Buscar por nome"
                onChange={(e) => setPesquisaNome(e.target.value)}
                onKeyPressCapture={(e) => setPesquisaNome(e.target.value)}
              />
              <label className="labelPesquisa">Nome do Usuários</label>
            </div>
            <div className="pesquisaCpf">
              <input
                value={pesquisaCpf}
                type="text"
                className="inputPesquisa"
                placeholder="Buscar por CPF"
                onChange={(e) => setPesquisaCpf(cpfMascara(e.target.value))}
                onKeyPressCapture={(e) =>
                  setPesquisaCpf(cpfMascara(e.target.value))
                }
              />
              <label className="labelPesquisa">CPF</label>
            </div>
          </div>

          <div className="navTabela">
            <button
              onClick={() => {
                setAbrirModalNovoVinculo(true);
              }}
              id="Acompanhamento"
            >
              <span>Cadastrar acompanhamento</span>
              <GoCommentDiscussion id="icon-acompanhamento" />
            </button>
            <div className="tabelaUsuarios">
              <table>
                <thead>
                  <tr>
                    {titulos.map((titulo) => (
                      <th key={titulo.nome}>{titulo.nome}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listaUsuariosTabela.length === 0 ? (
                    <tr>
                      <td colSpan={5} id="msgAcomp">Não há acompanhamento cadastrado.</td>
                    </tr>
                  ) : (
                    listaUsuariosTabela.map((info) => (
                      <tr key={info.id}>
                        <td>
                          {info.patient.person.name_first}{" "}
                          {info.patient.person.name_last}
                        </td>
                        <td>{info.patient.person.cpf}</td>
                        <td>{info.patient.person.phone}</td>
                        <td>{info.date}</td>
                        <td id="opcaoMsgAcomp">
                          <button
                            onClick={() => {
                              setChat(true);
                              setMessageId(info.id);
                              setReceiverInformation({
                                receiverId: info.patient.person.user[0].id,
                                receiverName: info.patient.person.name_first,
                                receiverPhoto: info.patient.person.user[0].photo
                              });
                            }}
                            className="alterar-status"
                          >
                            <BsChat color="#1070a1" />
                          </button>
                          <button
                            onClick={() => {
                              setAbrirModalEdit(true);
                              setMessageId(info.id);
                            }}
                            className="alterar-status"
                          >
                            <FiEdit2 color="#1070a1" />
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <Paginacao
                consultasPorPagina={consultasPorPagina}
                totalConsulta={listaUsuariosTabela.length}
                paginar={paginar}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default UsuariosClinica;
