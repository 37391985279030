/*
Data de criação: 22/06/2021;
Autor: Herson Douglas;
Descrição: Painel financeiro da clínica;
*/
import React, { useState, useEffect } from "react";
import api from "../../../Servicos/api";

//Componentes
import Menu from "../../../Componentes/GlobalMenu/indice";
import InformacoesFinanceirasMensais from "../../../Componentes/Clinica/GraficoFinanceiroMensal/index";
// import InformacoesFinanceirasSemanais from "../../../Componentes/Clinica/GraficoFinanceiroSemanal/index";

import { FiChevronsRight } from "react-icons/fi";

import FotoPadrao from "../../../Arquivos/avatarPadrao.svg";
import styles from "./FinanceScreen.module.css";
import PlanHistoryModal from "../../../Componentes/Clinica/ModalHistoricoPagamentos/indice";

export default function PainelFinanceiro() {
  const token = localStorage.getItem("token");
  const fantasy_name = localStorage.getItem("fantasy_name");
  const exibeEstatisticas = true;

  const [graphicData, setGraphicData] = useState([]);
  const [lastPayment, setLastPayment] = useState([]);
  const [totalPaymentData, setTotalPaymentData] = useState({});
  const [abrirModal, setAbrirModal] = useState(false);
  const [planId, setPlanId] = useState(false);

  const dateNow = new Date();

  let date = `${String(dateNow.getMonth() + 1).padStart(2, "0")}/${String(
    dateNow.getDate()
  ).padStart(2, "0")}/${dateNow.getFullYear()}`;

  //Dashboard Data Get

  useEffect(() => {
    api
      .get("/healthInsuranceDashboard", {
        headers: {
          Authorization: `Bearer ${token}`,
          date_now: date,
        },
      })
      .then((response) => {
        const data = response.data ? response.data : null;
        if (data !== null) {
          setGraphicData(data.historyPaymentGraphc);
          setTotalPaymentData(data.payments);
          setLastPayment(data.paymentHistory);
        }
      });
  }, [token, date]);

  const titulos = [
    { nome: "Plano" },
    { nome: "Data" },
    { nome: "Valor" },
    { nome: "Status" },
  ];

  // Adicionar nome do mês

  let monthIndex = [];
  const months = [
    { name: "Jan" },
    { name: "Fev" },
    { name: "Mar" },
    { name: "Abr" },
    { name: "Mai" },
    { name: "Jun" },
    { name: "Jul" },
    { name: "Ago" },
    { name: "Set" },
    { name: "Out" },
    { name: "Nov" },
    { name: "Dez" },
  ];

  for (let indexM of lastPayment) {
    monthIndex.push(indexM.payday.slice(5, 7));
  }

  return (
    <div className="PainelPrincipalClinica">
      <PlanHistoryModal
        abrirModal={abrirModal}
        setAbrirModal={setAbrirModal}
        planId={planId}
      />
      <Menu />

      <div className="ConteudoClinica">
        <header>
          <h1>Bem-vindo, {fantasy_name}.</h1>
          <div id="Perfil">
            <div id="FotoPerfil">
              <img src={FotoPadrao} alt="Foto da Clínica" />
            </div>
          </div>
        </header>

        <main className={styles.mainContainer}>
          <div id="Titulo">
            <span>Financeiro</span>
            <FiChevronsRight id="Icone" />
            <span>Dashboard e histórico de pagamentos *</span>
          </div>

          <div className={styles.cardsUpContainer}>
            <section className={styles.graphicsContainer}>
              <h2 className={styles.title}>Histórico Financeiro</h2>
              {exibeEstatisticas ? (
                <>
                  {/* <InformacoesFinanceirasSemanais /> */}
                  <InformacoesFinanceirasMensais paymentGraphic={graphicData} />
                </>
              ) : (
                <div />
              )}
            </section>
            <section className={styles.totalMonthly}>
              <h2 className={styles.title}>Total este mês</h2>
              <div className={styles.cardContainer}>
                <div className={styles.card}>
                  <h2>
                    <span>R$</span>
                    {parseFloat(totalPaymentData.paidOut).toFixed(2) === 'NaN' ? (
                      '0,00'
                    ) : (
                      parseFloat(totalPaymentData.paidOut).toFixed(2)
                    )}
                  </h2>
                  {totalPaymentData.diference < 0 ? (
                    <p className={styles.negativeBalance}>
                      {parseFloat(totalPaymentData.diference).toFixed(2) === 'NaN' ? (
                        '0,00 % '
                      ) : (
                        `${parseFloat(totalPaymentData.diference).toFixed(2)}% `
                      )}
                       desde o último mêses
                    </p>
                  ) : (
                    <p className={styles.positiveBalance}>
                      {parseFloat(totalPaymentData.diference).toFixed(2) === 'NaN' ? (
                        '0,00% '
                      ) : (
                        `+${parseFloat(totalPaymentData.diference).toFixed(2)}% `
                      )}
                       desde o último mês
                    </p>
                  )}
                </div>
                <div className={styles.card}>
                  <h3 className={styles.pending}>Pendente</h3>
                  <h2>
                    <span>R$</span>
                    {parseFloat(totalPaymentData.pending).toFixed(2) === 'NaN' ? (
                      '0,00'
                    ) : (
                      parseFloat(totalPaymentData.pending).toFixed(2)
                    )}
                  </h2>
                </div>
              </div>
            </section>
          </div>
          <div className={styles.tableWrapper}>
            <table>
              <thead>
                <tr>
                  {titulos.map((titulo) => (
                    <th key={titulo.nome}>{titulo.nome}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {lastPayment.length === 0 ? (
                  <tr>
                    <td>Nenhum histórico a ser mostrado.</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  
                ) : (
                  lastPayment.map((info) => (
                    <tr
                      key={info.id}
                      onClick={() => {
                        setPlanId(info.health_insurance_id);
                        // localStorage.setItem("plan_id", info.health_insurance_id);
                        setAbrirModal(true);
                      }}
                    >
                      <td>{info.plan_name}</td>
                      <td>
                        {`${info.payday.slice(8, 10)} ${months[parseInt(monthIndex) - 1].name
                          } ${info.payday.slice(0, 4)}`}
                      </td>
                      <td>{`R$ ${info.payment.toFixed(2)}`}</td>
                      {info.payment_received ? (
                        <td>
                          <span className={styles.paid}>Pago</span>
                        </td>
                      ) : (
                        <td>
                          <span className={styles.pending}>Pendente</span>
                        </td>
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  );
}
