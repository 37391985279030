// MarcarConsulta.jsx

import React from 'react';
import ChatBot from 'react-simple-chatbot';


export default function MarcarConsulta (){
    return (
        <ChatBot 
        steps={[
            {
                id: 'marcarConsulta',
                message: 'Aqui será pra marcar consultas',
                trigger: '2', // Volta para o ID 2 após a execução
            },
        ]}
        />
        
    );
        
};

