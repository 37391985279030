import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { BiMessageRoundedAdd, BiMessageRoundedMinus } from "react-icons/bi";
import api from "../../../Servicos/api";

import "./estilos.css";

export default function Form(props, setAbrirDados) {
  const token = localStorage.getItem("token");

  //Informações Gerais
  const [date, setDate] = useState("");

  const [questions, setQuestions] = useState([]);

  function addInputPergunta(question) {
    const itensCopy = Array.from(questions);
    itensCopy.push(question);
    setQuestions(itensCopy);
  }

  function updateTask({ target }, index) {
    const itensCopy = Array.from(questions);
    itensCopy.splice(index, 1, target.value);
    setQuestions(itensCopy);
  }

  function deleteTask(index) {
    const itensCopy = Array.from(questions);
    itensCopy.splice(index, 1);
    setQuestions(itensCopy);
  }

  async function EditAcompanhamento() {
    const data = {
      date,
      questions,
    };
    try {
      await api.put(`putSupervisionDoctor/${props.id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      swal("Alterado com Sucesso", "", "success");
      setAbrirDados(false);
    } catch (err) {
      swal(err.response.data.error, "Digite novamente", "error");
    }
  }

  // function FormataStringData(data) {
  //   var dia = data.split("/")[0];
  //   var mes = data.split("/")[1];
  //   var ano = data.split("/")[2];

  //   return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
  //   // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  // }

  useEffect(() => {
    api
      .get(`getMedicalSupervision/${props.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDate(response.data.date);

        setQuestions(response.data.questions);
      });
  }, [token, props.id]);

  return (
    <div id="form-acompanhamento">
      <Input
        className="input-nome"
        placeholder="000.000.000-00"
        value={date}
        type="date"
        setValue={setDate}
        maxlength={14}
        id="data"
        label="Data de hoje"
        name="Data"
      />
      {questions.map(({ id, value }, index) => (
        <div className={"conteudo"}>
          <div className="meu-box">
            <input
              key={id}
              type="text"
              placeholder="Digite uma pergunta."
              value={questions[index]}
              onChange={(event) => {
                updateTask(event, index);
              }}
              name="Perguntas"
              className="input-nome"
              required
            />
            <label htmlFor={index + 1} className="label-nome">
              {" "}
              <span>{`Pergunta ${index + 1}`}</span>
            </label>
          </div>
          <Link
            id="DeletePergunta"
            className="botao-grande"
            to="#"
            onClick={() => deleteTask(index)}
          >
            <BiMessageRoundedMinus size="20" />{" "}
          </Link>
        </div>
      ))}
      <Link
        id="AddPergunta"
        className="botao-grande"
        to="#"
        onClick={() => addInputPergunta("")}
      >
        <BiMessageRoundedAdd size="30" style={{ marginRight: "5px" }} />{" "}
        Pergunta
      </Link>

      <button
        type="button"
        className="botao-grande"
        onClick={() => EditAcompanhamento()}
      >
        Salvar
      </button>
    </div>
  );
}

function Input({
  id,
  label,
  value,
  setValue,
  type,
  placeholder,
  estado,
  maxlength,
}) {
  // function dateConverter(st) {
  //   var months = [
  //     "01",
  //     "02",
  //     "03",
  //     "04",
  //     "05",
  //     "06",
  //     "07",
  //     "08",
  //     "09",
  //     "10",
  //     "11",
  //     "12",
  //   ];

  //   var dt = new Date(st);
  //   var year = dt.getFullYear();
  //   var month = months[dt.getMonth()];
  //   var date = dt.getDate();
  //   return date + "/" + month + "/" + year;
  // }

  function ModificaValor(e) {
    setValue(e.target.value);
  }

  return (
    <div className={"conteudo"} style={{ marginBottom: "20px" }}>
      <div className="meu-box">
        <input
          className="input-nome"
          type={type}
          id={id}
          name={id}
          value={value}
          placeholder={placeholder}
          onChange={ModificaValor}
          disabled={estado}
          maxLength={maxlength}
          required
        />
        <label htmlFor={id} className="label-nome">
          {" "}
          <span>{label}</span>
        </label>
      </div>
    </div>
  );
}
