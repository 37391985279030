import React from "react";
import { useHistory } from "react-router-dom";

import "./estilos.css";
import { FiPlay } from "react-icons/fi";


function NovaConsultaMedico({
  ativo,
  executar,
  consulta,
  medico,
  especializacao,
  grupoEspecializacao,
}) {
  const history = useHistory();

  function dataBR(date) {
    return date.slice(0, 10).split("-").reverse().join("/");
  }

  function openModal(estado) {
    executar(estado);
  }

  return (
    <div>
      <section className={`modal ${ativo}`}>
        <div className="modalTitulo">
          <h2>{"Visualizar Consulta"}</h2>
        </div>
        <div className="modalConteudo">
          <div className="containerInput">
            <div>
              <input
                value={consulta.length !== 0 ? consulta.patient.person.name_first : ""}
                className="selectConsulta"
                disabled
              />

              <label className="modalLabel">Nome do Paciente</label>
            </div>
          </div>
          {/*<div className="containerInput">
            <div>
              <input
                required
                className="modalInput  disable"
                placeholder={"E-mail"}
                value={email}
                disabled
              />
              <label className="modalLabel">Email</label>
            </div>
            <div>
              <input
                placeholder={"Telefone"}
                value={phone}
                className="modalInput disable"
                disabled
              />
              <label className="modalLabel">Telefone</label>
            </div>
            </div>*/}
          <div className="containerInput">
            <div>
              <input
                value={consulta.length === 0 ? "" : dataBR(consulta.start_time)}
                // type="date"
                className="modalInput"
                disabled
              />
              <label className="modalLabel">Data da Consulta</label>
            </div>
            <div>
              <input
                value={consulta.length === 0 ? "" : consulta.start_time.slice(11, 16)}
                className="modalInput"
                disabled
              />
              <label className="modalLabel">Inicio</label>
            </div>
            <div>
              <input
                value={consulta.length === 0 ? "" : consulta.final_time.slice(11, 16)}
                className="modalInput"
                disabled
              />
              <label className="modalLabel">Fim</label>
            </div>
          </div>
          <div className="containerInput">
            <div>
              <input
                value={consulta.length === 0 ? "" : (consulta.type === 1
                  ? "Consulta - 1 vez"
                  : consulta.type === 2
                    ? "Consulta - Rotina"
                    : consulta.type === 3
                      ? "Consulta - Retorno"
                      : "Exames")}
                disabled
                className="selectConsulta"
              />

              <label className="modalLabel">Tipo do procedimento</label>
            </div>
            <div>
              <input
                value={consulta.length === 0 ? "" : (consulta.situation === 3
                  ? "Agendado"
                  : consulta.situation === 4
                    ? "Finalizado"
                    : "Cancelado")}
                required
                className="selectConsulta"
                disabled
              />

              <label className="modalLabel">Situação</label>
            </div>
          </div>
          <div className="containerInput">
            <div>
              <input
                className="selectConsulta"
                value={grupoEspecializacao}
                disabled
              />
              <label className="modalLabel">Grupo de Especialização</label>
            </div>
            <div>
              <input
                value={especializacao}
                className="selectConsulta"
                disabled
              />
              <label className="modalLabel">Especialização</label>
            </div>
          </div>
          <div className="containerInput">
            <div>
              <input
                id="medicoId2"
                value={`Dr(a) ${medico}`}
                className="selectConsulta"
                disabled
              />
              <label className="modalLabel">Nome do Médico</label>
            </div>
          </div>
          <div className="containerInput">
            <div>

              <textarea
                value={consulta.length === 0 ? "" : consulta.observation}
                className="textAreaConsulta"
                placeholder="Observações"
                disabled
              />
              <label className="modalLabel">Observações</label>
            </div>
          </div>
        </div>
        <div className="modalBotao">
          <button
            className="botao-grande cancelar"
            onClick={() => {
              openModal("");
              //limparConsulta();
            }}
          >
            CANCELAR
          </button>
          <button
            className="botao-grande"
            onClick={() => {
              history.push("/medico/consulta")
              localStorage.setItem("idConsulta", consulta.id);
              localStorage.setItem(
                "paciente_id",
                consulta.patient_id
              );
              localStorage.setItem("rota", "agenda");
            }}
          >
            <FiPlay className="icone-play" />
            Iniciar
          </button>
        </div>
      </section>
    </div>
  );
}

export default NovaConsultaMedico;
