import React, { useEffect } from "react";
import "./estilos.css";

const NotFound = () => {
  useEffect(() => {
    document.body.classList.add("not-found-page");

    return () => {
      document.body.classList.remove("not-found-page");
    };
  }, []);

  return (
    <div className="error-container">
      <img src="/404.png" alt="404 Error" className="error-image" />
      <br />
      <a href="https://thehigia.com/" className="back-button">
        Voltar para o Início
      </a>
    </div>
  );
};

export default NotFound;


