import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment";

import "./estilos.css";
import api from "../../../Servicos/api";

function NovaConsultaAtendente({
  ativo,
  executar,
  consulta,
  medico,
  medicoId,
  medicoSpecialization,
  listaPaciente,
  limparConsulta,
  rota,
  data,
}) {
  const token = localStorage.getItem("token");

  //Listagem
  const [pacienteNameSelect, setPacienteNameSelect] = useState("");
  const [pacienteSelect, setPacienteSelect] = useState(0);

  //date
  const [unformatedDate, setunformatedDate] = useState("");
  const datenow = moment(new Date()).format("YYYY-MM-DD");



  //const idAtendente = parseInt(localStorage.getItem("employee_id"));

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [observation, setObservation] = useState("");
  const [start_time, setStart_time] = useState("");
  const [final_time, setFinal_time] = useState("");
  const [type, setType] = useState();
  const [plan_type, setPlanType] = useState();
  const [situation, setSituation] = useState(3);
  const [editar, setEditar] = useState(false);
  const [cadastro, /*setCadastro*/] = useState(true);

  const horas = [
    { time: "06:00" },
    { time: "06:15" },
    { time: "06:30" },
    { time: "06:45" },
    { time: "07:00" },
    { time: "07:15" },
    { time: "07:30" },
    { time: "07:45" },
    { time: "08:00" },
    { time: "08:15" },
    { time: "08:30" },
    { time: "08:45" },
    { time: "09:00" },
    { time: "09:15" },
    { time: "09:30" },
    { time: "09:45" },
    { time: "10:00" },
    { time: "10:15" },
    { time: "10:30" },
    { time: "10:45" },
    { time: "11:00" },
    { time: "11:15" },
    { time: "11:30" },
    { time: "11:45" },
    { time: "12:00" },
    { time: "12:15" },
    { time: "12:30" },
    { time: "12:45" },
    { time: "13:00" },
    { time: "13:15" },
    { time: "13:30" },
    { time: "13:45" },
    { time: "14:00" },
    { time: "14:15" },
    { time: "14:30" },
    { time: "14:45" },
    { time: "15:00" },
    { time: "15:15" },
    { time: "15:30" },
    { time: "15:45" },
    { time: "16:00" },
    { time: "16:15" },
    { time: "16:30" },
    { time: "16:45" },
    { time: "17:00" },
    { time: "17:15" },
    { time: "17:30" },
    { time: "17:45" },
    { time: "18:00" },
  ];
  const history = useHistory();

  useEffect(() => {
    if (JSON.stringify(consulta) !== "{}") {
      async function getPatient() {
        await api
          .get(`getPatient/${consulta.patient_id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setPacienteSelect(response.data.id);
            setPacienteNameSelect(
              response.data.person.cpf + " - " + response.data.person.name_first
            );
            setEmail(response.data.person.user[0].email);
            setPhone(response.data.person.phone);
          });
      }
      getPatient();
      setEditar(true);
      // Verifica se consulta.start_time está presente
      if (consulta.start_time) {
        // Pega a parte da data (antes do 'T') para setar no setunformatedDate
        const date = consulta.start_time.split('T')[0];
        setunformatedDate(date);
        console.log("Data:", date); // Verifica se está correto
      } else {
        console.log("Data de início não disponível");
      }
      setObservation(consulta.observation);
      if (consulta.start_time) {
        // Pega a parte da hora (após o 'T') para setar em start_time
        const startTime = consulta.start_time.split('T')[1];
        setStart_time(startTime);
        console.log("Hora de início:", startTime); // Verifica se está correto
      }
      if (consulta.final_time) {
        // Divide a data e hora usando o 'T' e pega a parte do horário
        let finalTime = consulta.final_time.split('T')[1];
      
        // Usa moment para manipular o tempo e subtrair 4 minutos
        finalTime = moment(finalTime, "HH:mm").subtract(4, 'minutes').format("HH:mm");
      
        // Define o horário final com a subtração
        setFinal_time(finalTime);
        console.log("Hora de fim ajustada:", finalTime); 
      }
      setType(consulta.type);
      setPlanType(consulta.plan_type);
      setSituation(consulta.situation);
    } else {
    }
  }, [consulta, token]);

  useEffect(() => {
    if (data !== "") {
      setunformatedDate(data.substring(0, 10));
      setStart_time(data.substring(11, 16));
      setFinal_time(data.substring(16, 21));
    }
  }, [data]);

  async function CadastrarConsulta(e) {
    let endTimeInMinutes = final_time.split(":");
    let startTimeInMinutes = start_time.split(":");
    let duration = 
      parseInt(endTimeInMinutes[0]) * 60 + 
      parseInt(endTimeInMinutes[1]) - 
      (parseInt(startTimeInMinutes[0]) * 60 + parseInt(startTimeInMinutes[1]));

    let consultationHours = {
      start: `${unformatedDate}T${start_time}`,
      final: `${unformatedDate}T${moment(final_time, "HH:mm")
        .subtract(1, "minute")
        .format("HH:mm")}`
    }

    const data = {
      patient_id: pacienteSelect,
      doctor_id: medicoId,
      duration,
      observation,
      start_time: consultationHours.start,
      final_time: consultationHours.final,
      type,
      situation,
      plan_type: plan_type,
    }

    if (pacienteSelect !== "" && type !== "") {
      try {
        await api.post("createConsultation", data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        swal("Cadastrado com Sucesso", "", "success");
        history.push("/atendente/inicio");
        history.push("/atendente/agenda");
        openModal("");
        LimparCampos();
      } catch (err) {
        //swal(err.response.data.error, "Digite novamente", "error");
      }
    } else {
      swal("Preencha todos os campos", "Para cadastrar", "error");
    }
  }

  async function EditarConsulta(e) {
    let endTimeInMinutes = final_time.split(":");
    let startTimeInMinutes = start_time.split(":");
    let duration = 
      parseInt(endTimeInMinutes[0]) * 60 + 
      parseInt(endTimeInMinutes[1]) - 
      (parseInt(startTimeInMinutes[0]) * 60 + parseInt(startTimeInMinutes[1]));

    let consultationHours = {
      start: `${unformatedDate}T${start_time}`,
      final: `${unformatedDate}T${moment(final_time, "HH:mm")
        .subtract(1, "minute")
        .format("HH:mm")}`
    }
    
    console.log("Start time:", consultationHours.start);
    console.log("Final time:", consultationHours.final);
    console.log({unformatedDate});

    let medicoId = consulta.doctor.id;
    console.log(medicoId); 
    const data = {
      patient_id: pacienteSelect,
      doctor_id: medicoId,
      duration,
      observation,
      start_time: consultationHours.start,
      final_time: consultationHours.final,
      type,
      situation,
      plan_type: plan_type,
    }
  
    if (start_time !== "" && final_time !== "" && cadastro === true) {
      try {
        await api.put(`changeConsultation/${consulta.id}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        swal("Alterado com Sucesso", "", "success");
        history.push("/atendente");
        history.push(`/atendente/${rota}`);
        openModal("");
        LimparCampos();
        limparConsulta();
      } catch (err) {
        console.error(err);  // Exibe o erro no console
        swal(err.response.data.error, "Digite novamente", "error");
            }
    } else {
      swal("Preencha todos os campos corretamente ", "Para editar", "error");
    }
  }

  //tratamento dos DataList
  function setValuePacienteInput() {
    var inputValue = document.getElementById("patienteId2").value;
    var options = document.getElementById("dataList2").childNodes;

    for (var i = 0; i < listaPaciente.length; i++) {
      if (options[i].value === inputValue) {
        setPacienteSelect(parseInt(options[i].getAttribute("data-value")));
        setPacienteNameSelect(options[i].value);
        setEmail(listaPaciente[i].email);
        setPhone(listaPaciente[i].person.phone);
        break;
      }
    }
  }

  function openModal(estado) {
    executar(estado);
  }

  function LimparCampos() {
    setEditar(false);
    //paciente
    document.getElementById("patienteId2").value = "";
    setPacienteNameSelect("");
    setPacienteSelect("");
    setEmail("");
    setPhone("");

    //agenda
    setunformatedDate("");
    setStart_time("");
    setFinal_time("");

    //consulta
    setType("");
    setPlanType("");
    setSituation(3);

    //observação
    setObservation("");
  }

  return (
    <div>
      <section className={`modal ${ativo}`}>
        <div className="modalTitulo">
          <h2>
            {Object.keys(consulta).length === 0 &&
              consulta.constructor === Object
              ? "Nova Consulta"
              : "Editar Consulta"}
          </h2>
        </div>

        <div className="modalConteudo">
          <div className="containerInput">
            <div>
              <input
                id="patienteId2"
                value={pacienteNameSelect}
                list="dataList2"
                required
                className="selectConsulta"
                placeholder="--Selecione um paciente --"
                autoComplete="off"
                disabled={editar}
                onClick={(e) => {
                  e.target.value = "";
                  setPacienteSelect("");
                  setEmail("");
                  setPhone("");
                }}
                onChange={(e) => setValuePacienteInput()}
              />

              <datalist id="dataList2">
                {listaPaciente.map((pacientesListas) => (
                  <option
                    key={pacientesListas.person.patient.id}
                    data-value={pacientesListas.person.patient.id}
                    value={
                      pacientesListas.person.cpf +
                      " - " +
                      pacientesListas.person.name_first
                    }
                  >
                    {pacientesListas.person.cpf +
                      " - " +
                      pacientesListas.person.name_first}
                  </option>
                ))}
              </datalist>

              <label className="modalLabel">Nome do Paciente</label>
            </div>
          </div>
          <div className="containerInput">
            <div>
              <input
                required
                className="modalInput  disable"
                placeholder={"E-mail"}
                value={email}
                disabled
              />
              <label className="modalLabel">Email</label>
            </div>
            <div>
              <input
                placeholder={"Telefone"}
                value={phone}
                className="modalInput disable"
                disabled
              />
              <label className="modalLabel">Telefone</label>
            </div>
          </div>
          <div className="containerInput">
            <div>
            <input
              type="date"
              value={unformatedDate} // No formato "YYYY-MM-DD"
              required
              className={cadastro ? "selectConsulta" : "selectConsulta erro"}
              min={datenow} // Evita selecionar datas anteriores à atual
              disabled={!editar} // Impede alterações quando não estiver no modo de edição
              onChange={(e) => setunformatedDate(e.target.value)} // Atualiza o estado com a data escolhida
            />
              <label className={cadastro ? "modalLabel" : "modalLabel erro"}>
                {cadastro ? "Data da Consulta" : "Hórario indisponível"}
              </label>
            </div>
            <div>
          <select
            value={start_time}
            required
            className={cadastro ? "selectConsulta" : " selectConsulta erro"}
            onChange={(e) => {
              const selectedStartTime = e.target.value;
              // Verifica se o início não é igual ao fim e se o início não é maior que o fim
              if (selectedStartTime === final_time) {
                alert("O horário de início não pode ser igual ao horário de fim.");
              } else if (selectedStartTime > final_time && final_time) {
                alert("O horário de início não pode ser maior que o horário de fim.");
              } else {
                setStart_time(selectedStartTime);
              }
            }}
          >
            <option value="" disabled>
              -- Selecione o início --
            </option>
            {horas.map((hora) => (
              <option key={hora.time} value={hora.time}>
                {hora.time}
              </option>
            ))}
          </select>
          <label className={cadastro ? "modalLabel" : " modalLabel erro"}>Início</label>
        </div>

        <div>
          <select
            value={final_time}
            className={cadastro ? "selectConsulta" : " selectConsulta erro"}
            required
            onChange={(e) => {
              const selectedFinalTime = e.target.value;
              // Verifica se o fim não é igual ao início e se o fim não é menor que o início
              if (selectedFinalTime === start_time) {
                alert("O horário de fim não pode ser igual ao horário de início.");
              } else if (selectedFinalTime < start_time) {
                alert("O horário de fim não pode ser menor que o horário de início.");
              } else {
                setFinal_time(selectedFinalTime);
              }
            }}
          >
            <option value="" disabled>
              -- Selecione o fim --
            </option>
            {horas.map((hora) => (
              <option key={hora.time} value={hora.time}>
                {hora.time}
              </option>
            ))}
          </select>
          <label className={cadastro ? "modalLabel" : " modalLabel erro"}>Fim</label>
        </div>

          </div>
          <div className="containerInput">
            <div>
              <select
                value={type}
                required
                className="selectConsulta"
                onChange={(e) => setType(parseInt(e.target.value))}
              >
                <option value="">-- Tipo de procedimento --</option>
                <option value="1">Consulta - 1 vez</option>
                <option value="2">Consulta - Rotina</option>
                <option value="3">Consulta - Retorno</option>
              </select>
              <label className="modalLabel">Tipo do procedimento</label>
            </div>
            <div>
              <select
                value={plan_type}
                required
                className="selectConsulta"
                onChange={(e) => setPlanType(parseInt(e.target.value))}
              >
                <option value="">-- Tipo de plano --</option>
                <option value="0">Nenhum plano</option>
                <option value="1">SUS</option>
                <option value="2">Ambulatório</option>
                <option value="3">Hospitalar</option>
                <option value="4">Odontológico</option>
                <option value="5">Referencial</option>
              </select>
              <label className="modalLabel">Tipo de Plano</label>
            </div>
            <div>
              <select
                value={situation}
                required
                className="selectConsulta"
                onChange={(e) => {
                  setSituation(parseInt(e.target.value));
                }}
              >
                <option value="" disabled>
                  -- Situação --
                </option>
                <option value="3">Agendado</option>
                <option value="2" disabled={!editar}>
                  Cancelado
                </option>
                <option value="1">Em espera</option>
                <option value="4" disabled>
                  Finalizado
                </option>
              </select>
              <label className="modalLabel">Situação</label>
            </div>
          </div>

          <div className="containerInput">
            <div>
              <input
                value={medicoSpecialization}
                className="selectConsulta"
                disabled
              />
              <label className="modalLabel">Especialização</label>
            </div>
          </div>
          <div className="containerInput">
            <div>
              <input value={medico} className="selectConsulta" disabled />
              <label className="modalLabel">Nome do Médico</label>
            </div>
          </div>
          <div className="containerInput">
            <div>
              <textarea
                value={observation}
                className="textAreaConsulta"
                placeholder="Observações"
                onChange={(e) => setObservation(e.target.value)}
              />
              <label className="modalLabel">Observações</label>
            </div>
          </div>
        </div>
        <div className="modalBotao">
          <button
            className="botao-grande cancelar"
            onClick={() => {
              openModal("");
              limparConsulta();
              LimparCampos();
            }}
          >
            CANCELAR
          </button>
          <button
            className="botao-grande"
            onClick={() => {
              JSON.stringify(consulta) !== "{}"
                ? EditarConsulta()
                : CadastrarConsulta();
            }}
          >
                          {Object.keys(consulta).length === 0 &&
              consulta.constructor === Object
              ? "SALVAR"
              : "EDITAR"}
          </button>
        </div>
      </section>
    </div>
  );
}

export default NovaConsultaAtendente;