import React from "react";

import Rotas from "./Rotas/indice";

import "./global.css";

import { ChallengesProvider } from "./Servicos/Context/ChallengesContext";

function App() {
  return (
    <div>
      <ChallengesProvider>
        <Rotas />
      </ChallengesProvider>
    </div>
  );
}

export default App;
