import React, { useState } from "react";
import api from "../../../Servicos/api";

import "./estilos.css";

function DadosCadastraisMedico({ abrirDadosMedicos, setAbrirDadosMedicos }) {
  const [dadosMedico, setDadosMedico] = useState({});
  const [dadosMedicoPerson, setDadosMedicoPerson] = useState({});
  const token = localStorage.getItem("token");
  const doctor_id = localStorage.getItem("doctor_id");
  async function DadosConsulta() {
    try {
      const response = await api.get(
        `/patient/viewConsultation/doctor/${doctor_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDadosMedico(response.data);
      setDadosMedicoPerson(response.data.person);
    } catch (err) {
      console.log(err);
    }
  }
  const [variavel, setVar] = useState(1);
  if (abrirDadosMedicos === true) {
    if (variavel === 1) {
      DadosConsulta();
      setVar(2);
    }

    return (
      <div className="SobreposicaoDadosCadastraisMedico">
        <section className="ModalDadosCadastraisMedico">
          <header>
            <h2>Dados Cadastrais</h2>
          </header>
          <main>
            <div className="Nomes">
              <span>Nome:</span>
              <span>Especialização:</span>
              <span>RQE:</span>
              <span>CRM:</span>
              <span>Idade:</span>
              <span>Gênero:</span>
              <span>Celular:</span>
            </div>

            <div className="Dados">
              <span>
                {dadosMedicoPerson.name_first} {dadosMedicoPerson.name_last}
              </span>
              <span>{dadosMedico.area_specialization}</span>
              <span>
                {dadosMedico.rqe} {dadosMedico.uf}
              </span>
              <span>{dadosMedico.crm}</span>
              <span>{dadosMedicoPerson.age}</span>
              <span>
                {dadosMedicoPerson.gender === 1
                  ? "Masculino"
                  : dadosMedicoPerson.gender === 2
                  ? "Feminino"
                  : "Outro"}
              </span>
              <span>{dadosMedicoPerson.phone}</span>
            </div>
          </main>
          <footer>
            <button
              onClick={() => {
                setAbrirDadosMedicos(false);
                setVar(1);
              }}
            >
              Fechar
            </button>
          </footer>
        </section>
      </div>
    );
  }
  return null;
}

export default DadosCadastraisMedico;
