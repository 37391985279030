/*
Data de criação: 18/08/2020;
Autor: Jeciane Amorim;
Descrição: Página de visualização do perfil, responsável por disponibilizar
ao usuário os dados do perfil e o gerenciamento dos dados do perfil e exclusão da sua conta.
*/

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { FiEdit3, FiXCircle, FiCamera } from "react-icons/fi";
import "./estilos.css";
import api from "../../../Servicos/api";
import axios from "axios";
import swal from "sweetalert";

import Menu from "../../../Componentes/GlobalMenu/indice";
import ModalFoto from "../../../Componentes/Geral/ModalFoto/indice";
import defaultPhoto from "../../../Arquivos/avatarPadrao.svg";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";

export default function PerfilAtendente() {
  const history = useHistory();
  const [edit, setEdit] = useState(false);
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("employee_id");
  //const idUserPut = localStorage.getItem("idUserPut");

  const gender = localStorage.getItem("gender");
  //const email = localStorage.getItem("email");
  const [photo, setPhoto] = useState(localStorage.getItem("photo"));
  //const occupation = localStorage.getItem("occupation");
  const workload = localStorage.getItem("workload");
  const [name_first, setName_first] = useState(localStorage.getItem("name_first"));
  const [name_last, setName_last] = useState(localStorage.getItem("name_last"));
  const [cpf, setCpf] = useState(localStorage.getItem("cpf"));

   //-------------ERRO---------------//
  //const rg = "12345678911";    //Não consegue encontrar no genero do usuário

  const [data_birth, setData_birth] = useState(localStorage.getItem("data_birth").substring(0, 10));
  const [race, setRace] = useState(localStorage.getItem("race"));
  const [phone, setPhone] = useState(localStorage.getItem("phone"));
  const age = localStorage.getItem("age");
  const [state, setState] = useState(localStorage.getItem("state"));
  const [city, setCity] = useState(localStorage.getItem("city"));
  const [number, setNumber] = useState(localStorage.getItem("number"));
  const [street, setStreet] = useState(localStorage.getItem("street"));
  const [housign_type, setHousign_type] = useState(localStorage.getItem("housign_type"));
  const [cep, setCep] = useState(localStorage.getItem("cep"));
  const [modalFoto, setModalFoto] = useState(false);

  const [listaEstados, setListaEstados] = useState([]);
  const [listaCidades, setListaCidades] = useState([]);

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => {
        const estadoInitials = response.data.map((estado) => estado.sigla);

        setListaEstados(estadoInitials);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (!state || state === "0") {
      return setListaCidades([]);
    }

    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`
      )
      .then((response) => {
        const cidadeNome = response.data.map((cidade) => cidade.nome);

        setListaCidades(cidadeNome);
      })
      .catch((error) => console.log(error));
  }, [state]);


  async function AlterarPerfil(e) {
    e.preventDefault();

    try {
      const data = {
        race,
        data_birth,
        name_first,
        name_last,
        cpf,
        phone,
        gender,
        workload,
        address: {
          state,
          city,
          cep,
          number,
          street,
          housign_type,
        },
      };
      //console.table(data);
      await api.put(`employee/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      swal("Dados alterados com Sucesso", "", "success");
      setEdit(false);

      localStorage.setItem("race", race);
      localStorage.setItem("data_birth", data_birth);
      localStorage.setItem("name_first", name_first);
      localStorage.setItem("name_last", name_last);
      localStorage.setItem("cpf", cpf);
      localStorage.setItem("phone", phone);
      localStorage.setItem("gender", gender);
      localStorage.setItem("workload", workload);
      localStorage.setItem("state", state);
      localStorage.setItem("city", city);
      localStorage.setItem("cep", cep);
      localStorage.setItem("number", number);
      localStorage.setItem("street", street);
      localStorage.setItem("housign_type", housign_type);

      history.push("/atendente/perfil");
    } catch (err) {
      swal(err.response.data.error, "Erro ao editar dados", "error");
    }
  }

  return (
    <div className="perfil-container-atendente">
      <ModalFoto
        modalFoto={modalFoto}
        setModalFoto={setModalFoto}
        photo={photo}
        setPhoto={setPhoto}
      />
      <Menu />
      <section className="conteudo-perfil">
        <div className="container-formulario">
          <div className="container-perfil">
          <img src={photo ? photo : defaultPhoto} alt="Foto de Perfil" />

            <div className="informacao">
              <button
                className="botao-nova-foto"
                onClick={() => {
                  setModalFoto(true);
                }}
              >
                <FiCamera style={{ color: "#ffffff", width: 20, height: 20 }} />
              </button>
              <div id="div-ajuda">
                <h1 className="informacao-nome">{name_first}</h1>

                <Ajuda
                  mensagem={
                    "Nesta tela é possível visualizar os seus dados pessoais."
                  }
                  width={"20rem"}
                  backColor={"var(--cor-branco)"}
                  fontColor={"var(--cor-azul)"}
                  margin={"2.5rem 0 0 -3.0rem"}
                  border={"0.5rem"}
                  size={"2.5rem"}
                />
              </div>

              <h4 className="informacao-idade">{age} anos</h4>
              <div className="informacao-acao">
                <Link
                  to="#"
                  className={`informacao-acao-editar ${edit ? "editar" : ""}`}
                  onClick={() => setEdit(true)}
                >
                  <FiEdit3 className="icon" />
                  Alterar Perfil
                </Link>
              </div>
            </div>
          </div>
          <form onSubmit={AlterarPerfil}>
            <h2 className="titulo-formulario">Meus dados</h2>
            <div className="container-input">
              <div>
                <label htmlFor="nome">Nome</label>
                <input
                  id="nome"
                  value={name_first || ""}
                  disabled={edit ? false : true}
                  onChange={(e) => setName_first(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="sobrenome">Sobrenome</label>
                <input
                  id="sobrenome"
                  value={name_last || ""}
                  disabled={edit ? false : true}
                  onChange={(e) => setName_last(e.target.value)}
                />
              </div>
            </div>

            <div className="container-input">
              <div>
                <label htmlFor="cpf">CPF</label>
                <input
                  disabled={edit ? false : true}
                  id="cpf"
                  value={
                    edit === true
                      ? cpf
                      : `${cpf[0] + cpf[1] + cpf[2]}.***.***-**` || ""
                  }
                  onChange={(e) => setCpf(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="data_birth">Data de nascimento</label>
                <input
                  disabled={edit ? false : true}
                  id="data_birth"
                  format="yyyy-MM-dd"
                  value={data_birth || ""}
                  onChange={(e) => setData_birth(e.target.value)}
                />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="race">Cor/Raça</label>
                <select
                  placeholder="Etnia"
                  value={race || 0}
                  onChange={(e) => setRace(parseInt(e.target.value))}
                  disabled={edit ? false : true}
                  required
                >
                  <option value="">-- Etnia --</option>
                  <option value="1">Amarela</option>
                  <option value="2">Branca</option>
                  <option value="3">Negra</option>
                  <option value="4">Pardo</option>
                </select>
              </div>
              <div>
                <label htmlFor="telefone">Telefone</label>
                <input
                  disabled={edit ? false : true}
                  id="telefone"
                  value={phone || ""}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>

            <h2 className="titulo-formulario">Endereço</h2>
            <div id="input-estado" className="container-input">
              <div>
                <label htmlFor="state">Estado</label>
                <select
                  id="state"
                  value={state || ""}
                  onChange={(e) => setState(e.target.value)}
                  disabled={edit ? false : true}
                >
                  <option value={state || ""} style={{ textColor: "#6e757d" }}>
                    {state}
                  </option>
                  {listaEstados.map((uf) => (
                    <option key={uf} value={uf}>
                      {uf}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="city">Cidade</label>
                <select
                  id="city"
                  value={city || ""}
                  onChange={(e) => setCity(e.target.value)}
                  disabled={edit ? false : true}
                >
                  <option value={city} style={{ textColor: "#6e757d" }}>
                    {city}
                  </option>
                  {listaCidades.map((listaCidade) => (
                    <option key={listaCidade} value={listaCidade}>
                      {listaCidade}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div id="input-numero" className="container-input">
              <div>
                <label htmlFor="street">Rua</label>
                <input
                  id="street"
                  value={street || ""}
                  disabled={edit ? false : true}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="number">Número</label>
                <input
                  id="number"
                  value={number || 0}
                  disabled={edit ? false : true}
                  onChange={(e) => setNumber(parseInt(e.target.value))}
                />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="housign_type">Tipo da Residência</label>
                <select
                  value={housign_type || ""}
                  onChange={(e) => setHousign_type(e.target.value)}
                  disabled={edit ? false : true}
                >
                  <option value="">-- Complemento --</option>
                  <option value="Casa">Casa</option>
                </select>
              </div>

              <div>
                <label htmlFor="cep">CEP</label>
                <input
                  id="cep"
                  value={cep || 0}
                  disabled={edit ? false : true}
                  onChange={(e) => setCep(parseInt(e.target.value))}
                />
              </div>
            </div>
            <div className={`container-botao ${edit ? "editar" : ""}`}>
              <button
                className="botao-grande"
                onClick={() => setEdit(false)}
                type="reset"
                formNoValidate
              >
                Cancelar
              </button>
              <button className="botao-grande" type="submit">
                Salvar
              </button>
            </div>
          </form>
          <button
            className={`informacao-acao-deletar  ${edit ? "editar" : ""}`}
            //onClick={() => DeletePaciente()}
          >
            <FiXCircle className="icon" />
            Excluir conta
          </button>
        </div>
      </section>
    </div>
  );
}
